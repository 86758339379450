import React from 'react';
import { HBox, Element } from 'react-stylesheet';
import { Span, Image } from '../../01_atoms';
import {colors} from "../../00_base/variables";

const defaultToolStyle = {
    screenBackground: '#63819f',
    textColor: colors.white,
    borderColor: colors.white,
    letterFontSize: 12
};


const ToolTranslator = ({ items, toolStyle = defaultToolStyle }) => {
    const originalLetters = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט', 'י', 'כ', 'ל', 'מ', 'נ', 'ס', 'ע', 'פ', 'צ', 'ק', 'ר', 'ש', 'ת'];
    const background = toolStyle.screenBackground;
    const borderColor = toolStyle.borderColor;
    const textColor = toolStyle.textColor;
    const letterFontSize = toolStyle.letterFontSize;
    const letterStyle = { fontSize: letterFontSize + "px", position: 'absolute', top: '1rem', right: '1rem', fontWeight: 'bold', color: textColor };
    const renderLetter = (item, index) => {
        return (
            <HBox justifyContent={'center'} alignItems={'center'} padding={'2rem'} width={'50%'}
                border={`1px solid ${borderColor}`} background={item.color} key={item.id}>
                <Span style={letterStyle} value={item.label || originalLetters[index]}/>
                <Element width={'60px'} height={'60px'}>
                    <Image src={item.image} width='60px' />
                </Element>
            </HBox>
        )
    };

    return (
        <Element background={background}>
            <HBox flexWrap='wrap'>
                {items.map(renderLetter)}
            </HBox>
        </Element>
    )
}

export default ToolTranslator;
