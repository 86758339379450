import React from "react";
import { adventureSpace } from "../../store/mocks/space-mock";
import "./screen-riddle.scss";
import { WarningMessage } from "../../components/warning-message";
import { RiddlesWrapper } from "../riddles/RiddlesWrapper/riddles-wrapper";
import colorsVariables from "../../assets/css/variables/space-colors.scss";

export const HistoryContext = React.createContext(null);

const ScreenRiddle = ({ match, history }) => {
  const adventure = adventureSpace;
  const riddleId = match.params.id;
  const riddle = adventure.riddles.find(
    (riddle) => riddle.id === parseInt(riddleId)
  );
  const Tool = riddle.tool;

  const currentLevel = localStorage.getItem("space-level");

  if (currentLevel < riddleId) {
    // history.push("/spaceship");
  }

  const onSubmit = () => {
    levelUp();
    return true;
  };

  const getWrapperColor = () => {
    switch (riddleId) {
      case "1":
        return {
          color: colorsVariables.spaceGrey,
          shadowColor: colorsVariables.spaceGreyShadow,
        };
      default:
        return {
          color: colorsVariables.spaceDarkBlue,
          shadowColor: colorsVariables.spaceDarkBlueShadow,
        };
    }
  };

  const levelUp = () => {
    localStorage.setItem("space-level", parseInt(currentLevel) + 1 + "");
  };

  return (
    <HistoryContext.Provider value={history}>
      <WarningMessage />
      <div className={"riddles-container"}>
        <RiddlesWrapper title={riddle.title} />
        <Tool
          riddleData={riddle}
          toolId={riddleId}
          onSubmit={onSubmit}
          toolData={riddle.toolData}
          isResolved={currentLevel > riddle.id}
          history={history}
        />
      </div>
    </HistoryContext.Provider>
  );
};

export default ScreenRiddle;
