import React, { useState } from 'react';
import Button from '../../01_atoms/button/a-button';
import styled from 'styled-components';
import PropTypes from "prop-types";
import { colors, gutters } from '../../00_base/variables';

const Tabs = ({ items, activeTab, tabLabelStyle, tabLabelActiveStyle, horizontal }) => {
    const [activeItem, setActiveItem] = useState(activeTab ? activeTab : 0);

    return (
        <TabsContainer horizontal={horizontal}>
            <TabsLabels horizontal={horizontal}>
                {items.map((item, i) => {
                    const isActive = activeItem === i;
                    return (
                        <TabLabel key={i} isActive={isActive} horizontal={horizontal}>
                            <Button style={{...tabLabelStyle, ...isActive && tabLabelActiveStyle}}
                                onClick={() => !isActive && setActiveItem(i)} 
                                value={item.label} />
                        </TabLabel>
                    )
                })}
            </TabsLabels>

            <TabsContent>
                {items.find((item, i) => activeItem === i).content}
            </TabsContent>
        </TabsContainer>
    )
}

const TabsContainer = styled.div`
    display: ${props => props.horizontal ? 'flex' : 'block'};
    flex-direction: row;
    // justify-content: space-between;
`;
const TabsLabels = styled.div`
    display: flex;
    flex-direction: ${props => props.horizontal ? 'column' : 'row'}
    justify-content: ${props => props.horizontal ? 'flex-start' : 'center'}
`;
const TabLabel = styled.div`
    display: flex;
    flex-direction: ${props => props.horizontal ? 'column' : 'row'}
    justify-content: ${props => props.horizontal ? 'flex-start' : 'center'}
    opacity: ${props => props.isActive ? 1 : '.8'};
    width: ${props => !props.horizontal && '50%'};
    margin-top: ${props => props.horizontal && gutters.xs};
    margin-bottom: ${props => props.horizontal && gutters.xs};
    white-space: nowrap;
`;
const TabsContent = styled.div`
padding: ${gutters.md}
`;


Tabs.defaultProps = {
    tabLabelStyle: {
        color: colors.warning,
        backgroundColor: colors.secondary
    },
    tabLabelActiveStyle: {
        backgroundColor: colors.warning,
        color: colors.secondary
    },
    horizontal: false
};
Tabs.propTypes = {
    /**
     * each item must include: label (String) and content (String or Component)
     */
    items: PropTypes.array.isRequired,
    /**
     * Define the default active tab (0, 1...)
     */
    activeTab: PropTypes.number,
    /**
     * style object for the tabs label buttons
     */
    tabLabelStyle: PropTypes.object,
    /**
     * style object for the active tab label buttons
     */
    tabLabelActiveStyle: PropTypes.object,
    /**
     * define an horizontal tabs
     */
    horizontal: PropTypes.bool
}

export default Tabs;  