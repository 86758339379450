import React from "react";

export const Audio = ({id, source, autoPlay, loop}) => {
    return (
        <div className={"audio-wrapper"}>
            <audio autoPlay={autoPlay} loop={loop}  id={id} >
                <source src={source} type="audio/ogg" />
                <source src={source} type="audio/mpeg" />
            </audio>
        </div>
    )
};