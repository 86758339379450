import React, { useEffect, useState } from "react";
import { WarningMessage } from "../../../components/warning-message";
import { adventureSpace } from "../../../store/mocks/space-mock";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { playAudio } from "../../../assets/helpers";
import "./spaceship.scss";

import { Audio } from "../../01_atoms/audio/audio";
import Audio1 from "../../../assets/audios/1.wav";
import Audio2 from "../../../assets/audios/2.wav";
import Audio8 from "../../../assets/audios/8.wav";

import GIF_1 from "../../../assets/images/pizza/lobby/scene01smaller.gif";
import GIF_2 from "../../../assets/images/pizza/lobby/scene02smaller.gif";
import GIF_3 from "../../../assets/images/pizza/lobby/scene03smaller.gif";
import GIF_4 from "../../../assets/images/pizza/lobby/scene04smaller.gif";
import GIF_5 from "../../../assets/images/pizza/lobby/scene05smaller.gif";
import GIF_6 from "../../../assets/images/pizza/lobby/scene06smaller.gif";
import GIF_7 from "../../../assets/images/pizza/lobby/scene07smaller.gif";
import GIF_ALL from "../../../assets/images/monster-gif_fix.gif";

import IMAGE_1 from "../../../assets/images/pizza/scene 1.png";
import IMAGE_2 from "../../../assets/images/pizza/scene 2.png";
import IMAGE_3 from "../../../assets/images/pizza/lobby/design_lobby_alian.jpg";
import IMAGE_4 from "../../../assets/images/pizza/scene 4.png";
import IMAGE_5 from "../../../assets/images/pizza/scene 8.png";
import IMAGE_6 from "../../../assets/images/pizza/lobby/011.scene-six-on.png";
import IMAGE_7 from "../../../assets/images/pizza/lobby/012.scene-seven-on.png";
import IMAGE_8 from "../../../assets/images/pizza/lobby/lobby_all_scenes_on.jpg";
import SPACESHIP_8 from "../../../assets/space-gifs/level-8.gif";
import SPACESHIP_11 from "../../../assets/space-gifs/level-10.jpg";

const ScreenMenu = ({ history }) => {
  const adventure = adventureSpace;
  const level = localStorage.getItem("space-level");
  const adventureId = localStorage.getItem("adventureId");
  const riddle = adventure.riddles.find(
    (riddle) => riddle.id === parseInt(level)
  );
  const [closeSpaceship, setCloseSpaceship] = useState(false);

  const SPACESHIP_GIFS = [
    GIF_1,
    GIF_2,
    GIF_3,
    GIF_4,
    GIF_5,
    GIF_6,
    GIF_7,
    GIF_7,
  ];
  const SPACESHIP_IMAGES = [
    IMAGE_1,
    IMAGE_2,
    IMAGE_3,
    IMAGE_4,
    IMAGE_5,
    IMAGE_6,
    IMAGE_7,
    IMAGE_7,
    IMAGE_8,
  ];

  const audioValidId = "audio-spaceship-valid";
  const audioInValidId = "audio-spaceship-invalid";
  const audioWarningId = "audio-warning-id";

  useEffect(() => {
    if (level === "3") {
      playAudio(audioWarningId);
    }
  }, [level]);

  const goToNextLevel = (index) =>
    adventureId ? history.push(`/riddle/${index}`) : history.push(`/spaceship`);

  const TransparentButton = ({ index, children }) => {
    const clickHandler = (e) => {
      e.stopPropagation();
      playAudio(audioValidId);
      setTimeout(() => goToNextLevel(index), 1000);
    };

    return (
      <button
        className={`transparent-button ${
          level > 4 && index === 4 ? "hide" : ""
        }`}
        onClick={clickHandler}
        // style={{display: level > 3 && index + 1 === 4 ? "none" : "block" }}
        style={{
          ...adventure.riddles[index - 1].menuTriggerStyle,
          opacity: parseInt(level) === index ? 1 : 0,
        }}>
        {children}
      </button>
    );
  };

  const clearUrl = (url) => {
    while (url.lastIndexOf("/") !== -1) {
      url = url.slice(0, url.lastIndexOf("/"));
    }
    return url;
  };

  const checkForRedirectToLastRiddle = () => {
    if (
      level === "11" &&
      window.location.pathname === "/spaceship" &&
      !closeSpaceship
    ) {
      setCloseSpaceship(true);
      let url = clearUrl(window.location.href);
      url += "/riddle/11";
      setTimeout(() => {
        window.location.href = url;
      }, 2000);
    }
  };

  return (
    <div
      className={classNames("spaceship", { closeAnimation: closeSpaceship })}
      onClick={() => playAudio(audioInValidId)}>
      <Audio source={Audio1} id={audioValidId} />
      <Audio source={Audio2} id={audioInValidId} />
      <Audio source={Audio8} id={audioWarningId} />

      {checkForRedirectToLastRiddle()}

      <WarningMessage />

      <div className={"spaceship-container"}>
        {level == 3 ? (
          <img src={GIF_ALL} />
        ) : (
          <img src={SPACESHIP_IMAGES[level - 1]} />
        )}

        {Array.apply(null, Array(parseInt(level))).map((item, index) => {
          return (
            <TransparentButton index={index + 1} key={index + 1}>
              {level == 3 ? null : <img src={SPACESHIP_GIFS[level - 1]} />}
            </TransparentButton>
          );
        })}
      </div>
    </div>
  );
};

export default ScreenMenu;
