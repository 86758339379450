import React from "react";
import {Link} from 'react-router-dom';
import PropTypes from "prop-types";
import ICON from "../../assets/images/pizza_hut_assets/hint.png";
import "./screen-clue.scss";

export const ClueTrigger = ({id, top, right, bottom, left}) => {
    const level = localStorage.getItem('space-level');
    return (
        <button className='clue-trigger' style={{top, right, bottom, left}}>
            <Link to={`/clue/${id}`}>
                <img src={ICON} height={"100%"} width={"100%"}/>
            </Link>
        </button>
    )
};

ClueTrigger.defaultProps = {};

ClueTrigger.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) ,
    top: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
};