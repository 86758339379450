import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Audio } from "../01_atoms/audio/audio";
import Audio3 from "../../assets/audios/3.wav";
import { playAudio } from "../../assets/helpers";
import { HistoryContext } from "../ScreenRiddle/screen-riddle";
import "./success-display.scss";
import { DICTIONARY } from "../../dictionary/dictionary";

const SuccessDisplay = ({ historyRoute, title, text_link, goto }) => {
  const audioId = "audio-back-home";
  const language = localStorage.getItem("language") || "hebrew";
  const history = useContext(HistoryContext);

  const navigate = () => {
    let level = localStorage.getItem("space-level");

    playAudio(audioId);
    setTimeout(() => {
      if (level <7 ) {
        historyRoute ? historyRoute.push(goto) : history.push(goto);
      }else if(level==8){
        historyRoute ? historyRoute.push('/riddle/9') : history.push('/riddle/9');
      } else {
        historyRoute
          ? historyRoute.push(`/riddle/${level}`)
          : history.push(`/riddle/${level}`);
      }
    }, 1600);
  };

  return (
    <div className='success-display'>
      <Audio source={Audio3} id={audioId} />
      <div className={"success-text"}>
        {DICTIONARY.instructions.inputted[language]}
      </div>
      <div className={"redirect"} onClick={navigate}>
        {DICTIONARY.instructions.continue_to_bridge[language]}
      </div>
    </div>
  );
};

SuccessDisplay.defaultProps = {
  title: "פיצחתם את הקוד",
  text_link: "המשך לגשר הפיקוד",
  goto: "/spaceship",
};

SuccessDisplay.propTypes = {
  title: PropTypes.string,
  text_link: PropTypes.string,
};

export default SuccessDisplay;
