import React, {useState} from "react";
import PropTypes from 'prop-types';
import SingleTifzoretCube from './single-tifzoret-cube/single-tifzoret-cube.jsx';
import "./space-tifzoret.scss";

const SpaceTifzoret = ({height, width}) => {

    const renderSquares = () =>{
        let heightArr = Array.from(Array(height).keys());
        let widthArr = Array.from(Array(width).keys());

        return(
            heightArr.map(heightIndex => {
                return (<div className={"cubes-row"} style={{height:`calc(100% / ${height})`}}>
                            {widthArr.map(widthIndex => <SingleTifzoretCube width={`calc(100% / ${width})`}/>)}
                        </div>)
            }))
    }

    return (
        <div className={"space-tifzoret"}>
            <div className={"cubes-container"}>
                {renderSquares()}
            </div>
        </div>
    )
};

SpaceTifzoret.defaultProps = {
    height: 5,
    width: 8,
};

SpaceTifzoret.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
};

export default SpaceTifzoret;