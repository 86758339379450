import React, {useEffect, useState} from "react";
import './alternative-power-system.scss';
import * as systemsImages from '../../../assets/images/alternative-power-riddle/index'
import PIPES from "../../../assets/images/alternative-power-riddle/pipes.png";
import SpaceNavbar from "../../ScreenSpaceNavbar/screen-space-navbar"
import {playAudio} from "../../../assets/helpers"
import {Audio} from "../../01_atoms/audio/audio";
import Audio5 from "../../../assets/audios/5.wav";
import BACKGROUND from "../../../assets/images/pizza/scene01_bg.png"
const systemsImagesArray = [
    systemsImages.A,
    systemsImages.B,
    systemsImages.C,
    systemsImages.D,
    systemsImages.E,
    systemsImages.F,
    systemsImages.G,
    systemsImages.H,
    systemsImages.AA,
    systemsImages.BB,
    systemsImages.CC,
    systemsImages.DD,
    systemsImages.EE,
    systemsImages.FF,
    systemsImages.GG,
    systemsImages.HH,
]

const DIFF = 8;

export const ScreenAlternativePower = ({riddleData, onSubmit, isResolved}) => {
    const [isUserSucsess, setIsUserSucsess] = useState(isResolved);
    const [planetsData, setPlanetsData] = useState([]);
    const [isWrongAnswer, setIsWrongAnswer] = useState(false);
    const answersIndexs = [0, 2, 4, 6];
    const buttonAudioId = "alternative-system-audio-button";
    useEffect(() => {
        setInitialPlanets()
    }, []);

    const checkValidation = () => {
        let validationValue = true;
        answersIndexs.map(answerIndex => {
            if (!planetsData[answerIndex].mode) {
                validationValue = false;
            }
        });
        setIsUserSucsess(validationValue);
        if (!validationValue) {
            setIsWrongAnswer(true);
            setTimeout(() => {
                setInitialPlanets()
            }, 1500)
        } else {
            onSubmit();
            window.scrollTo(0,0);

        }
    };

    const setInitialPlanets = () => {
        setPlanetsData(isResolved ? [
            {index: 0, mode: true},
            {index: 1, mode: false},
            {index: 2, mode: true},
            {index: 3, mode: false},
            {index: 4, mode: true},
            {index: 5, mode: false},
            {index: 6, mode: true},
            {index: 7, mode: false},
        ] : [
            {index: 0, mode: false},
            {index: 1, mode: false},
            {index: 2, mode: false},
            {index: 3, mode: false},
            {index: 4, mode: false},
            {index: 5, mode: false},
            {index: 6, mode: false},
            {index: 7, mode: false},
        ])
    };
    const howManyPlanetsClicked = () => {
        let counter = 0;
        planetsData.map(planet => {
            if (planet.mode) {
                counter++
            }
        });
        return counter
    };

    const onImageClick = (index) => {
        if (!isResolved) {
            playAudio(buttonAudioId);
            let newState = [...planetsData];
            newState[index].mode = !newState[index].mode;
            setPlanetsData(newState);
            if (howManyPlanetsClicked() === 4) {
                checkValidation()
            }
        }
    };

    const renderSystems = () => {
        isWrongAnswer && setTimeout(() => {
            setIsWrongAnswer(false)
        }, 1500);
        return planetsData.map(planet => {
            const {index, mode} = planet;
            const imgClassName = mode && isWrongAnswer ? "system-img-wrong" : mode ? "system-img-clicked" : "system-img"
            const src = (isUserSucsess || isResolved) && answersIndexs.includes(index) ? systemsImagesArray[index + DIFF] : systemsImagesArray[index];
            return <img src={src} onClick={e => onImageClick(index)} className={imgClassName}/>
        })
    };

    return (
        <div className={`alternative-system-container ${isUserSucsess ? "success" : ""}`}
        style={{backgroundImage:`url(${BACKGROUND})`}}>
            <Audio source={Audio5} id={buttonAudioId}/>

            <img src={PIPES} className={"pipes-image"}/>

            <div className={"systems-row"}>
                {renderSystems()}
            </div>

            <SpaceNavbar location={'left'}
                         text={isUserSucsess && riddleData.successText}
                         isSuccess={isUserSucsess}
                         isError={isWrongAnswer}
                         showClues={true}
                         color={"grey"}
                         id={riddleData.id}
                         showMenuReturn={true}/>
        </div>
    )
};