import React, {useState} from "react";
import './screen-treasure.scss';
import {StarsBackground} from "../../03_organisms/stars-background/stars-background";
import ToolTreasure from "../../tools/treasure";
import { DICTIONARY } from "../../../dictionary/dictionary";

export const ScreenTreasure = ({riddleData}) => {
    const language = localStorage.getItem("language") || "hebrew";
    const [showFinalScreen, setShowFinalScreen] = useState(false);

    return (
        <div className="screen-treasure-container">
            {/* <div className="screen-treasure-wrapper"> */}
                {/* <StarsBackground position="absolute" zIndex={1}/> */}
                {!showFinalScreen ?
                    <div className="screen-treasure-content">
                        <h2>{DICTIONARY.instructions.treasure_text[language]}</h2>
                        <button onClick={() => setShowFinalScreen(true)}>Engage</button>
                    </div>
                    :
                    <div className="final-screen">
                        <ToolTreasure/>
                    </div>
                }
            </div>
        // </div>
    )
};