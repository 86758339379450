export const viewport = {
    xs: '320px',
    xs_l: '480px',
    sm: '640px',
    md: '768px',
    lg: '992px',
    xl: '1080px',
    wide: '1200px',
    widest: '1920px'
};

export const screenWidth = {
    //Regular phone
    mobileMin: '0',
    mobileMax: '480px',
    //Big phone
    mobileLgMin: '481px',
    mobileLgMax: '640px',
    //Tablet Portrait
    tabletPortraitMin: '641px',
    tabletPortraitMax: '768px',
    //Tablet Landscape
    tabletLandscapeMin: '769px',
    tabletLandscapeMax: '1028px',
    //Desktop
    desktopMin: '1029px',
    desktopMax: '1200px',
    //Desktop Large
    desktopLargeMin: '1201px',
    desktopLargeMax: '1400px',
    //Desktop Max
    desktopMaxMin: '1401px',

};