import React from "react";
import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import { BrowserRouter as Router } from "react-router-dom";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Switch, Route } from "react-router-dom";
import advenutreReducer from "./store/reducers/adventure-reducers";
import ScreenHomepage from "./components/ScreenHomepage/screen-homepage";
import generalAudio from "./assets/audios/Spaceship  Ambience.mp3";
import { Audio } from "./components/01_atoms/audio/audio";
import ScreenRiddle from "./components/ScreenRiddle/screen-riddle";
import "./app.scss";
import { StarsBackground } from "../src/components/03_organisms/stars-background/stars-background";

import ScreenMenu from "./components/05_screens/menu/screen-menu";
import { ScreenClue } from "./components/ScreenClue/screen-clue";
import Terms from "./components/ScreenHomepage/terms";

const rootReducer = combineReducers({
  adventure: advenutreReducer,
});
const store = createStore(rootReducer, applyMiddleware(ReduxThunk));
const generalAudioId = "general-audio-id";

function App({ location }) {
  const language = localStorage.getItem("language");

  return (
    <Provider store={store}>
      <div className={`app ${language ? language.toLowerCase() : ""}`}>
        <Audio
          source={generalAudio}
          id={generalAudioId}
          autoPlay={true}
          loop={true}
        />
        <StarsBackground />

        <Router>
          <Switch location={location}>
            <Route exact path={"/"} component={ScreenHomepage} />

            <Route path={"/terms"} component={Terms} />

            <Route
              path={"/riddle/:id"}
              component={(props) => <ScreenRiddle {...props} />}
            />
            <Route
              path={"/spaceship"}
              component={(props) => <ScreenMenu {...props} />}
            />
            <Route
              path={"/clue/:id"}
              component={(props) => <ScreenClue {...props} />}
            />
          </Switch>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
