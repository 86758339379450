import React, { useState } from "react";
import "./screen-engine-start.scss";
import SpaceRotary from "../../tools/space-rotary/space-rotary";
import SpaceNavbar from "../../ScreenSpaceNavbar/screen-space-navbar";
import _ from "lodash";
import { Audio } from "../../01_atoms/audio/audio";
import { playAudio } from "../../../assets/helpers";
import Audio13 from "../../../assets/audios/13.wav";

export const ScreenEngineStart = ({ riddleData, isResolved, onSubmit }) => {
  const [userCode, setUserCode] = useState(
    isResolved
      ? JSON.stringify(riddleData.password)
      : Array.from(Array(riddleData.password.length).keys()).map(() => 0)
  );
  const [isUserSuccessed, setIsUserSuccessed] = useState(isResolved);

  const audioId = "audio-engine-start";

  const handleSubmit = () => {
    if (!isResolved) {
      if (_.isEqual(userCode, riddleData.password)) {
        setIsUserSuccessed(true);
        onSubmit();
      }

      return _.isEqual(userCode, riddleData.password);
    }
    //handle riddle solve
  };

  const onRotate = (rotaryIndex, newValue) => {
    playAudio(audioId);
    const code = isResolved ? JSON.parse(userCode) : userCode;
    code[rotaryIndex] = newValue.toString();
    setUserCode(JSON.parse(JSON.stringify(code)));
    if (!isResolved) {
      setTimeout(handleSubmit, 100);
    }
  };

  const renderRotary = (index) => {
    const src = require(`../../../assets/images/engine-start-riddle/${index}.png`);

    return (
      <div className={"single-engine-rotary-wrapper"}>
        <div className={"single-engine-rotary"}>
          <SpaceRotary
            id={`${index}`}
            onChange={(deg) => onRotate(index, deg)}
            backgroundColor={"transparent"}
            showNumbers={true}
            degressAddOnClick={30}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={"screen-engine-container"}>
      <Audio id={audioId} source={Audio13} />
      <div className={"engine-rotaries-wrapper"}>
        {renderRotary(3)}
        {renderRotary(2)}
        {renderRotary(1)}
        {renderRotary(0)}
      </div>
      <SpaceNavbar
        text={isUserSuccessed ? riddleData.successText : ""}
        showMenuReturn={true}
        showClues={true}
        isSuccess={isUserSuccessed}
        id={riddleData.id}
      />
    </div>
  );
};
