import styled from 'styled-components';
import { colors } from '../../00_base/configurations';
import {font_size, viewport} from '../../00_base/variables';


const SkewElement = styled.div`
    position: relative;
    background-color: ${props => props.isLoading ? colors.gray_loading : props.background};
    width: ${props => props.width ? props.width : 'auto'};
    overflow: visible;
    text-align: center;
    z-index: ${props => props.zIndex ? props.zIndex : '0'};
    ${props => props.style && props.style}
    & > * {
        position: relative;
        z-index: 1;
    } 
    ${props => props.before && `
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: ${props.before.top ? props.before.top : 0};
            left: 0;
            background-color: ${props.isLoading ? colors.gray_loading : props.background};
            transform: skew${props.before.direction}(${props.before.deg}deg);
            transform-origin: ${props.before.origin};
            z-index: 0;
        }
    `}

    ${props => props.after && `
        &:after { 
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: ${props.after.top ? props.after.top : 0};
            left: 0;
            background-color: ${props.isLoading ? colors.gray_loading : props.background}; 
            transform: skew${props.after.direction}(${props.after.deg}deg);
            transform-origin: ${props.after.origin};
            z-index: 0;
        }
    `}
    
    ${props => props.backgroundHover &&
        `&:hover {
            background-color: ${props.isLoading ? colors.gray_loading : props.backgroundHover};
            &:before {
                background-color: ${props.isLoading ? colors.gray_loading : props.backgroundHover};
            }
            &:after {
                background-color: ${props.isLoading ? colors.gray_loading : props.backgroundHover};    
            }
        }`
    }
    
    @media screen and (max-width: ${viewport.sm}) {
        ${props => props.mobileReg && `
            &:after, &:before {
                transform: skew(0deg);
                position: static;
            }
        `}
    }
`;

export default SkewElement;