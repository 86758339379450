import React, {useEffect, useState} from 'react';
import { Modal } from '../../../03_organisms';
import ImageCropper from '../../../02_molecules/image-cropper';

const ImageCropperModal = ({ src, index, uploadSingleFile, setCropperActive }) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {},  [isLoading]);
    return (
        <Modal isActive={'active'}
               hideTrigger={true}
               animationDirection={'tb'}
               closeModal={() => setCropperActive(false)}>
            <ImageCropper
                src={src}
                classes={{ cropContainer: 'crop-container' }}
                handleSubmit={dataUrl => {
                    const pic = dataUrl.replace(/^data:image\/(png|jpg);base64,/, "")
                    setIsLoading(true);
                    function dataURLtoFile(dataurl, filename) {

                        let arr = dataurl.split(','),
                            mime = arr[0].match(/:(.*?);/)[1],
                            bstr = atob(arr[1]),
                            n = bstr.length,
                            u8arr = new Uint8Array(n);

                        while (n--) {
                            u8arr[n] = bstr.charCodeAt(n);
                        }

                        return new File([u8arr], filename, { type: mime });
                    }

                    const file = dataURLtoFile(pic, 'croped.jpg');

                    uploadSingleFile(file, index);
                }} />
        </Modal>
    )
};

export default ImageCropperModal