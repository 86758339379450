import React from "react";
import { VBox, HBox, Element } from "react-stylesheet";
import { MdLanguage, MdBackspace } from "react-icons/md";
import { gutters, font_size, colors } from "../../00_base/variables";
import { Container } from "react-grid-system";
import { Circle, Span } from "../../01_atoms";
import { DICTIONARY } from "../../../dictionary/dictionary";
import { Audio } from "../../01_atoms/audio/audio";
import { playAudio } from "../../../assets/helpers";
import Audio12 from "../../../assets/audios/12.wav";

class ToolPassword extends React.Component {
  constructor(props) {
    super(props);

    const { password, keyboardType } = this.props;

    const firstLetter = password[0];

    const firstLetterEnglish = firstLetter.match(/[a-z]/i);
    const firstLetterHebrew = firstLetter.match(/[\u0590-\u05FF]/);

    console.log("firstLetterEnglish", firstLetterEnglish);
    console.log("firstLetterHebrew", firstLetterHebrew);

    let lettersType;
    if (keyboardType) {
      lettersType = keyboardType;
    } else if (!!firstLetterEnglish) {
      lettersType = 2;
    } else if (!!firstLetterHebrew) {
      lettersType = 1;
    } else {
      lettersType = 0;
    }

    this.state = {
      isValid: -1,
      userInput: "",
      password: password,
      secret: 0,
      lettersType: lettersType,
      audioId: "audio-password",
    };

    this.numberClick = this.numberClick.bind(this);
    this.clearNumberInput = this.clearNumberInput.bind(this);
    this.submitNumberInput = this.submitNumberInput.bind(this);
    this.switchType = this.switchType.bind(this);
    this.backspace = this.backspace.bind(this);
  }

  secretHandler = () => {
    this.setState({
      secret: this.state.secret + 1,
      userInput:
        this.state.secret > 10 ? this.state.password : this.state.userInput,
    });

    setTimeout(() => {
      this.setState({
        secret: 0,
      });
    }, 8000);
  };

  numberClick(num) {
    let { userInput, audioId } = this.state;
    const number = (userInput += num);
    playAudio(audioId);
    this.setState({
      userInput: number,
      isValid: 1,
      secret: 0,
    });
  }

  backspace() {
    const { userInput } = this.state;
    const newNumber = userInput.substring(0, userInput.length - 1);
    this.setState({
      userInput: newNumber,
      isValid: 1,
    });
  }

  clearNumberInput() {
    this.setState({
      userInput: "",
      isValid: 1,
    });
  }

  submitNumberInput() {
    const { userInput, password } = this.state;
    if (userInput === password) {
      this.setState({
        isValid: 1,
      });

      this.props.handleSuccess();
    } else {
      this.setState({
        isValid: 0,
        userInput: "",
      });
    }
  }

  switchType() {
    const { lettersType } = this.state;
    this.setState({
      lettersType:
        lettersType === 0
          ? 1
          : lettersType === 1
          ? 2
          : lettersType === 2
          ? 0
          : 0,
    });
  }

  render() {
    const { isValid, userInput, password, audioId, lettersType } = this.state;
    const language = localStorage.getItem("adventure-language");
    return (
      <Element
        style={{
          flex: 1,
          alignSelf: "center",
        }}>
        <Audio source={Audio12} id={audioId} />
        <Container
          style={{
            display: "flex",
            alignItems: "space-between",
            position: "relative",

            // zIndex: '100',
            // opacity: !password && '0'
          }}>
          <VBox justifyContent='center' height={"100%"} style={phoneWrapper}>
            <Element
              width={"100%"}
              marginBottom={gutters.md}
              marginTop={gutters.md}>
              <HBox
                alignItems={"center"}
                justifyContent={"center"}
                style={inputField}>
                {userInput}
              </HBox>
            </Element>

            <Letters
              typing={this.numberClick}
              colors={{ buttonBackground: colors.white, color: colors.black }}
              lang={"en"}
            />

            <Element
              style={{ ...wrongPassword, opacity: isValid === 0 ? "1" : "0" }}
              onClick={this.secretHandler}>
              {DICTIONARY.instructions.wrong_password[language]}
            </Element>

            <HBox
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}>
              {/*<button onClick={this.clearNumberInput}*/}
              {/*style={{*/}
              {/*...button,*/}
              {/*...btnClear,*/}
              {/*backgroundColor: colors.fifth,*/}
              {/*color: colors.buttons*/}
              {/*}}>מחק*/}
              {/*</button>*/}

              <Element margin={`0 ${gutters.lg}`}>
                {userInput === password || userInput === "" ? (
                  <button
                    onClick={this.submitNumberInput}
                    style={{
                      outline: "0",
                      ...button,
                      ...btnSubmit,
                      color: colors.buttonText,
                      padding: 0,
                      backgroundColor: "transparent",
                    }}>
                    <Circle
                      size={65}
                      background={userInput === "" ? "#d67c7c" : "#9de09b"}
                    />
                  </button>
                ) : (
                  <button
                    onClick={this.submitNumberInput}
                    style={{
                      ...button,
                      ...btnSubmit,
                      backgroundColor: "transparent",
                      padding: 0,
                      outline: 0,
                    }}>
                    <Circle
                      size={65}
                      background={userInput === "" ? "#d67c7c" : "#9de09b"}
                    />
                  </button>
                )}
              </Element>
            </HBox>
          </VBox>
        </Container>
      </Element>
    );
  }
}

class Letters extends React.Component {
  render() {
    const { typing, lang } = this.props;
    const letters = [
      ["P", "O", "I", "U", "Y", "T", "R", "E", "W", "Q"],
      ["L", "K", "J", "H", "G", "F", "D", "S", "A"],
      ["M", "N", "B", "V", "C", "X", "Z"],
    ];
    return (
      <HBox
        flexDirection='row-reverse'
        flexWrap='wrap'
        justifyContent='space-between'
        style={buttons}>
        {letters.map((row, r) => {
          return (
            <HBox
              flexWrap={"nowrap"}
              justifyContent={"center"}
              flexDirection={"row"}
              width={"100%"}
              key={r}>
              {row.map((letter, l) => {
                return (
                  <div
                    key={letter}
                    style={{
                      ...letterStyle,
                      color: "#fff",
                      backgroundColor: "#000",
                      border: `1px solid #54FF00`,
                      fontSize: font_size.lg,
                      paddingLeft: 0,
                      cursor: "pointer",
                      paddingRight: 0,
                    }}
                    onClick={() => typing(letter)}>
                    {letter}
                  </div>
                );
              })}
            </HBox>
          );
        })}
      </HBox>
    );
  }

  static defaultProps = {
    lang: "en",
  };
}

const inputField = {
    width: "100%",
    height: "3rem",
    fontSize: font_size.xl,
    textAlign: "center",
    borderTop: "0",
    borderLeft: "0",
    borderRight: "0",
    backgroundColor: "transparent",
    color: colors.white,
    fontWeight: "bold",
  },
  phoneWrapper = {
    width: "100%",
    margin: "0 auto 10px",
    height: "100%",
    borderRadius: "2rem",
    // padding: '1.5rem',
  },
  buttons = {},
  number = {
    width: "30%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: ".5rem",
    padding: `${gutters.xs} 0`,
    fontWeight: "bold",
    color: colors.black,
    fontSize: font_size.md,
  },
  letterStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // borderRadius: '.5rem',
    // margin: `${gutters.sm} 0 ${gutters.sm}`,
    padding: gutters.sm,
    // color: '#fff',
    backgroundColor: "#000",
    color: "red",
    font_size: "3rem",
    width: "5vw",
    height: "5vw",
    margin: "5px",
  },
  wrongPassword = {
    fontSize: "2.5rem",
    color: "#d67c7c",
    textAlign: "center",
    paddingBottom: "1rem",
    fontWeight: "bold",
  },
  button = {
    border: "0",
    fontSize: "2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  btnSubmit = {
    border: "0",
    color: "#000",
    fontWeight: "bold",
    overflow: "hidden",
    textAlign: "center",
  };
export default ToolPassword;
