import React from "react";
import "./riddles-wrapper.scss";

export const RiddlesWrapper = ({title, color, shadowColor}) => {
    return (
        < >
            <div className={"top-blue-poligon"} style={{backgroundColor:color}}/>
            <div className={"bottom-blue-poligon"} style={{backgroundColor:color}}/>
            <div className={"left-blue-poligon"} style={{backgroundColor:color}}/>
            <div className={"right-blue-poligon"} style={{backgroundColor:color}}/>

            <div className={"top-blue-poligon shadow"} style={{backgroundColor:shadowColor}}/>
            <div className={"bottom-blue-poligon shadow"} style={{backgroundColor:shadowColor}}/>
            <div className={"left-blue-poligon shadow"} style={{backgroundColor:shadowColor}}/>
            <div className={"right-blue-poligon shadow"} style={{backgroundColor:shadowColor}}/>
 
            <div className={"right-top-corner"} style={{backgroundColor:color}}/>
            <div className={"right-bottom-corner"} style={{backgroundColor:color}}/>
            <div className={"left-top-corner"} style={{backgroundColor:color}}/>
            <div className={"left-bottom-corner"} style={{backgroundColor:color}}/>

            <div className={"right-top-corner shadow"} style={{backgroundColor:shadowColor}}/>
            <div className={"right-bottom-corner shadow"} style={{backgroundColor:shadowColor}}/>
            <div className={"left-top-corner shadow"} style={{backgroundColor:shadowColor}}/>
            <div className={"left-bottom-corner shadow"} style={{backgroundColor:shadowColor}}/>

            <div className={"border-shadow"} style={{borderColor:shadowColor}}/>

            <div className={"riddle-title"}>{title}</div>
        </>
    )
};