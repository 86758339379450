import React from 'react'; 
import { Image } from '../../01_atoms';
import { Container } from 'react-grid-system';
import { Element } from 'react-stylesheet';
import PropTypes from "prop-types";

const ToolImage = ({ src, width }) => {
    return (
        <div>
            <Container style={{padding: 0}}>
                <Element>
                    <Image src={src} width={width} />
                </Element>
            </Container>
        </div>
    )
};

ToolImage.defaultProps = {
    width: '100%'
};

ToolImage.propTypes = {
    src: PropTypes.string.isRequired,
    width: PropTypes.string,
};


export default ToolImage;