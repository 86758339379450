import React, {useState} from "react";
import PropTypes from 'prop-types';
import "./single-tifzoret-cube.scss";

const SingleTifzoretCube = ({height,width, faceUpColor, faceDownColor}) => {
    const [isFaceUp, setIsFaceUp] = useState(true);  

    return (
        <div style={{ height, width, backgroundColor:isFaceUp ? faceUpColor: faceDownColor}} 
            className={"single-cube"} onClick={()=>setIsFaceUp(!isFaceUp)}/>
    )
};

SingleTifzoretCube.defaultProps = {
    height:"100%",
    width:"100%",
    faceUpColor:"purple",
    faceDownColor:"#464444",
};

SingleTifzoretCube.propTypes = {
    height:PropTypes.string,
    width:PropTypes.string,
    faceUpColor:PropTypes.string,
    faceDownColor:PropTypes.string,
};

export default SingleTifzoretCube;