import { SET_ADVENTURE, SET_ADVENTURE_DATA, SET_RIDDLES} from "../actions/adventure/adventure-actions";

 
const initialState = {
    adventure: null,
    adventureData: null,
    riddles: null
};

const advenutreReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ADVENTURE:
            return {
                ...state,
                adventure: action.adventure
            };
        case SET_ADVENTURE_DATA:
             return {
                ...state,
                adventureData: action.adventureData
            };
        case SET_RIDDLES:
            return {
                ...state,
                riddles: action.riddles
            };
        default:
            return state;
    }
};

export default advenutreReducer;