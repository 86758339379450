import React, { useState } from "react";
import "./screen-autopilot-access.scss";
import SpaceCoder from "../../tools/space-coder/space-coder";
import { ClueTrigger } from "../../ScreenClue/clue-trigger";
import MenuReturn from "../../ScreenMenuReturn/screen-menu-return";
import _ from "lodash";
import { Link } from "react-router-dom";
import { DICTIONARY } from "../../../dictionary/dictionary";
import CAPTAIN from "../../../assets/images/pizza/captain.png";

export const ScreenAutopilotAccess = ({ riddleData, isResolved, onSubmit }) => {
  const phoneNumberAnswer = "1700506070"; // localStorage.getItem("phoneNumber") ||
  const language = localStorage.getItem("language") || "hebrew";
  const imagePath = localStorage.getItem("image");
  const [showParagraph, setShowParagraph] = useState(isResolved);

  const handleSubmit = (userCode) => {
    const isValid = _.isEqual(userCode, _.toString(phoneNumberAnswer));
    if (isValid) {
      setShowParagraph(true);
      onSubmit();
    }

    return isValid;
    //handle riddle solve
  };
  console.log(phoneNumberAnswer);
  return (
    <div className={"autopilot-access-container"}>
      <ClueTrigger id={riddleData.id} top={"0"} right={"0"} />
      <MenuReturn id={riddleData.id} top={"0"} left={"0"} />
      <div className={"autopilot-access-wrapper"}>
        <div className={"autopilot-img-container"}>
          <div className={"autopilot-name"}>קפטן החללית</div>
          <img src={CAPTAIN} className={"autopilot-img"} />
        </div>
        {showParagraph ? (
          <div className={"paragraph"}>
            <div className={"paragraph-text"}>
              {DICTIONARY.instructions.spaceship_text[language]}
            </div>
            <button className={"button"}>
              {riddleData.id == 7 || riddleData.id==8  ? (
                <Link to={`/riddle/9}`}>
                {DICTIONARY.instructions.continue_[language]}
              </Link>
              ):
              <Link to={`/riddle/${riddleData.id + 1}`}>
                {DICTIONARY.instructions.continue_[language]}
              </Link>
}
              
            </button>
          </div>
        ) : (
          <div className={"coder"}>
            <div className={"coder-text"}>
              {DICTIONARY.instructions.access_automatic_pilot[language]}
            </div>
            <SpaceCoder
              onSubmit={handleSubmit}
              digitsLength={phoneNumberAnswer.length}
            />
          </div>
        )}
      </div>
    </div>
  );
};
