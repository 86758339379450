import React, { useContext } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import backIcon from "../../assets/images/backToMenu.png";
import classnames from "classnames";
import { Audio } from "../01_atoms/audio/audio";
import Audio3 from "../../assets/audios/3.wav";
import { playAudio } from "../../assets/helpers";
import { HistoryContext } from "../ScreenRiddle/screen-riddle";
import { DICTIONARY } from "../../dictionary/dictionary";
import BUTTON_REG_NEW from "../../assets/images/pizza/button_reg_new.png";
import BUTTON_PRESS_NEW from "../../assets/images/pizza/button_pressed_new.png";

const MenuReturn = ({
  historyRoute,
  top,
  right,
  bottom,
  left,
  isSuccess,
  riddleId,
}) => {
  const language = localStorage.getItem("language") || "hebrew";
  const audioId = "audio-back-home";
  const history = useContext(HistoryContext);

  const navigate = (route) => {
    playAudio(audioId);
    setTimeout(() => {
      historyRoute ? historyRoute.push(route) : history.push(route);
    }, 1600);
  };

  return (
    <div
      className={classnames("back-home", { success: isSuccess })}
      style={{ top, right, bottom, left }}>
      <Audio source={Audio3} id={audioId} />
      {/* <button onClick={() => navigate(riddleId ? `/riddle/${riddleId}` : "/spaceship")}
                    className={classnames("back-home-img")}>
                {riddleId ?
                    <div>{DICTIONARY.instructions.back_mission[language]}</div>
                    :
                <div style={{"white-space":" break-spaces", color:"#fff", padding:"5px 7px"}}>{DICTIONARY.instructions.back_to_command_bridge[language]}</div>
                } */}
      <div
        className={"continue"}
        onClick={() =>
          navigate(riddleId ? `/riddle/${riddleId}` : "/spaceship")
        }>
        <img src={BUTTON_REG_NEW} className={"button-reg"} width={125} />
        <img src={BUTTON_PRESS_NEW} className={"button-press"} width={125} />
      </div>

      {/*<img src={backIcon} className={classnames("back-home-img", {success:isSuccess})}/>*/}
      {/* </button> */}
    </div>
  );
};

MenuReturn.defaultProps = {};

MenuReturn.propTypes = {
  top: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  isSuccess: PropTypes.bool,
};

export default MenuReturn;
