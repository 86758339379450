import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {VBox} from 'react-stylesheet';
import {Row, Container} from 'react-grid-system';
import PropTypes from "prop-types";
import {gutters, colors, font_size} from '../../00_base/variables';
import Train from '../../03_organisms/train/train';
import {BackgroundImage, Button, Image} from '../../01_atoms';
import {Link} from "react-router-dom";
import SkewElement from "../../02_molecules/skew-element";
import {BackToMenu} from "../../02_molecules/back-to-menu/back-to-menu";


const ToolGallery = (
    {
        items,
        userData,
        screenBackground = '#63819f',
        spaceBetweenImages = 12,
        framesPadding = 20,
        borderColor,
        borderWidth
    }) => {
    const [activeGallery, setActiveGallery] = useState(null);

    const userImages = userData;

    if (items.length === 1) {
        return (
            <Wrapper backgroundColor={screenBackground} border={`${borderColor} ${borderWidth}px solid`}
                     width={`calc(100% - ${borderWidth}px)`}>
                <Container>
                    {items[0].images.map((image, index) => {
                        const frame = items[0].frames[index];
                        return (
                            <ImageWrapper key={index} spaceBetweenImages={spaceBetweenImages}>
                                <Cover>
                                    <UserImageWrapper framesPadding={framesPadding}>
                                        <BackgroundImage url={image}
                                                         style={{minHeight: "200px", width: '100%', height: '100%'}}/>
                                    </UserImageWrapper>
                                    <img alt="cover" src={frame || image} style={{display: 'block', width: '100%'}}/>
                                </Cover>
                            </ImageWrapper>
                        )
                    })}
                </Container>
            </Wrapper>
        );
    }

    const activeItem = items && items.find(item => item.id === activeGallery);
    const activeMode = activeItem && activeItem.id >= 0;

    const Item = ({index, number, image}) => {
        const [numberActive, setNumberActive] = useState(null);
        useEffect(() => {
            if (numberActive >= 0) {
                setTimeout(() => {
                    setNumberActive(null);
                }, 2500)
            }
        }, [numberActive]);
        return (
            <TrainItemWrapper key={index} onClick={() => setNumberActive(number)}>

                <BackgroundImage
                    url={image}
                    cover={{component: <NumberCover isActive={numberActive}> {number} </NumberCover>}}
                    style={{width: '215px', height: '215px'}}/>
            </TrainItemWrapper>
        )
    };

    return (
        <Wrapper backgroundColor={screenBackground} border={`${borderColor} ${borderWidth}px solid`}
                 width={`calc(100% - ${borderWidth}px)`}>
            <div style={{...container, justifyContent: activeMode ? 'space-around' : 'center'}}>
                {activeMode &&
                <VBox justifyContent={'center'} width='100vw'>
                    <Image src={activeItem.icon} style={{alignSelf: 'center', width: '100px'}}/>
                    <Train spaceBetweenItems={'10px'}
                           items={activeItem.images.map((image, index) => {
                               const editableItems = activeItem.editable && activeItem.editable.split(',');
                               let url;
                               if (editableItems && editableItems.some(item => parseInt(item - 1) === index)) {
                                   url = userImages ? userImages.shift() : image;
                               } else {
                                   url = image;
                               }
                               return (
                                   <Item image={url} number={activeItem.hints[index]} index={index}/>
                               )
                           })}/>
                </VBox>
                }
                <Row style={{...row, flexDirection: activeMode ? 'row' : 'column', marginBottom:gutters.md}}>
                    {items.filter(item => item.id !== activeGallery).map((item, i) => {
                        return (
                            <GalleryTrigger key={i}
                                            backgroundImage={item.icon}
                                            backgroundColor={item.color}
                                            activeMode={activeMode}
                                            onClick={() => setActiveGallery(item.id)}>
                                {item.icon ? <Image src={item.icon}/> : `גלריה ${item.id + 1}`}
                            </GalleryTrigger>
                        )
                    })}
                </Row>

                {/*<BackToMenu/>*/}

            </div>

        </Wrapper>
    )
};


const Wrapper = styled.div`
    background-color: ${props => props.backgroundColor};
    flex: 1;
    border: ${props => props.border};
    width: ${props => props.width};
`;
const container = {
    width: '100vw',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    overflow: 'hidden',
    maxWidth: '100%',
    height: '100%'
};

const row = {
    justifyContent: 'center',
    alignItems: 'center',
};
const UserImageWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    padding: ${props => props.framesPadding + "px"};
    top: 0;
    left: 0;
`;
const NumberCover = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    font-size: ${font_size.max};
    display: ${props => props.isActive ? 'flex' : 'none'};
    z-index: 1;
    background-color: rgba(0,0,0,0.9);
    color: ${colors.white};
    justify-content: center;
    align-items: center;
`;
const ImageWrapper = styled.div`
    display: flex;    
    margin-top: ${props => props.spaceBetweenImages / 2 + "px"};
    margin-bottom: ${props => props.spaceBetweenImages / 2 + "px"};
`;
const TrainItemWrapper = styled.div`
    position: relative;
`;
const Cover = styled.div`
    width: 100%;
    position: relative;
    background-color: ${colors.white};
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const GalleryTrigger = styled.div`
    width: 100%;
    height: ${props => props.activeMode ? '10rem' : '20rem'};
    width: ${props => props.activeMode ? '10rem' : '20rem'};
    background-color: ${colors.gray_2};
    color: ${colors.black};
    font-weight: bold;
    border: 0;
    padding: 20px 0;
    margin: ${gutters.xxs};
    display: flex;
    justify-content: center;
    cursor: pointer; 
    align-items: center;
    font-size: ${props => props.activeMode ? '20px' : '40px'};
`;

ToolGallery.defaultProps = {
    width: '100%',
    images: []
};

ToolGallery.propTypes = {
    images: PropTypes.array
};

export default ToolGallery;

