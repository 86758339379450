import Tool from "../../_models/tool-model";
import ToolTifzoret from "../../../components/tools/tifzoret";
import ToolGallery from "../../../components/tools/gallery";
import ToolContactList from "../../../components/tools/contact-list";
import ToolTrivia from "../../../components/tools/trivia";
import ToolVideo from "../../../components/tools/viedo";
import ToolTranslator from "../../../components/tools/translator";
import ToolTreasure from "../../../components/tools/treasure";
import ToolImage from "../../../components/tools/image";
import Adventure from "../../_models/adventure-model";
import AdventureData from "../../_models/adventureData-model";
import Riddle from "../../_models/riddle-model";
import ToolDefiner from "../../../components/tools/definer";
import ToolTruthOrLie from "../../../components/tools/lie-truth/tool-truth-lie";

const api = 'https://api.youdoadventures.com';
const adventure_passcode_url = `${api}/adventures-by-passcode`;
const adventure_url = `${api}/adventures`;
const adventureData_url = `${api}/adventureData`;
const adventureType_url = `${api}/adventureType`;
const riddles_url = `${api}/riddles`;
const tools_url = `${api}/riddlesTemplate`;

const tools = [
    { id: 47, component: ToolContactList },
    { id: 48, component: ToolDefiner },
    { id: 56, component: ToolGallery },
    { id: 7, component: ToolImage },
    { id: 57, component: ToolVideo },
    { id: 49, component: ToolTrivia },
    { id: 35, component: ToolTruthOrLie },
    { id: 296, component: ToolTrivia },
    { id: 9, component: ToolTifzoret },
    { id: 46, component: ToolTifzoret },
    { id: 15, component: ToolTranslator },
    { id: 60, component: ToolTreasure },
    // { id: 100, component: ToolTreasure },
];

export const SET_ADVENTURE = 'SET_ADVENTURE';
export const SET_ADVENTURE_DATA = 'SET_ADVENTURE_DATA';
export const SET_RIDDLES = 'SET_RIDDLES';
 

export const getAdventureByPasscode = passcode => {
    return async (dispatch, getState) => {
        try {
            const response = await fetch(`${adventure_passcode_url}/?passcode=${passcode}`);
            if (!response.ok) {
                throw new Error('Something went wrong');
            }
            const data = await response.json();

            const adventure = new Adventure(
                data.id,
                data.title,
                data.adventure_name,
                data.thumbnail,
                data.passcode,
                data.identifier,
                data.adventuredata_id,
                data.created_at,
                data.user_email,
                data.is_published,
                data.json_data,
                data.language,
            );

            dispatch({
                type: SET_ADVENTURE,
                adventure: adventure
            });
             return adventure;

        } catch (error) {
            throw error
        }
    }
};
export const getAdventureById = adventureId => {
     return async (dispatch, getState) => {
        try {
            const response = await fetch(`${adventure_url}/${adventureId}`);
             if (!response.ok) {
                throw new Error('Something went wrong');
            }
            const data = await response.json();

            const adventure = new Adventure(
                data.id,
                data.title,
                data.adventure_name,
                data.thumbnail,
                data.passcode,
                data.identifier,
                data.adventuredata_id,
                data.created_at,
                data.user_email,
                data.is_published,
                data.json_data,
                data.language,
            )
            dispatch({
                type: SET_ADVENTURE,
                adventure: adventure
            });
            return adventure;

        } catch (error) {
            throw error
        }
    }
};

export const setAdventureData = adventureDataId => {
    return async (dispatch, getState) => {
        try {
            const response = await fetch(`${adventureData_url}/${adventureDataId}`);
            const data = await response.json();
            const adventureData = new AdventureData(
                data.id,
                data.adventurename,
                data.description,
                data.themeid,
                data.istimeron,
                data.riddles,
                data.general_details,
                data.thumbnail,
                data.thumbnail_mobile,
                data.thumbnail_tablet,
                data.thumbnail_desktop,
                data.welcome_screen_url_mobile,
                data.welcome_screen_url_tablet,
                data.welcome_screen_url_desktop,
                data.adventure_logo,
                data.adventure_type,
                data.is_main,
                data.title_label,
                data.icon_label,
                data.icon,
                data.created_at
            )
            dispatch({
                type: SET_ADVENTURE_DATA,
                adventureData: adventureData
            });

            return adventureData;
        } catch (error) {
            throw error
        }
    }
};

export const getAdventureDataType = adventureTypeId => {
    return async (dispatch, getState) => {
        const response = await fetch(`${adventureType_url}/${adventureTypeId}`);
        const adventureType = await response.json();
        return adventureType;
    }
};

export const setRiddles = riddles => {
    const getData = (url) => {
        return new Promise((resolve, reject) => {
            fetch(url).then(
                response => response.json()
            ).then(
                data => resolve(data)
            )
        })
    }
    return async (dispatch, getState) => {
        let allRiddles = [];
        for (let i = 0; i < riddles.length; i++) {
            const riddleData = await getData(`${riddles_url}/${riddles[i]}`);
            allRiddles.push(riddleData);
        }

        dispatch({
            type: SET_RIDDLES,
            riddles: allRiddles
        });

        return allRiddles;
    }
};

export const getRiddleById = riddleId => {
    return async (dispatch, getState) => {
        try {
            const response = await fetch(`${riddles_url}/${riddleId}`);

            if (!response.ok) {
                throw new Error('Something went wrong');
            }
            const data = await response.json();
            const riddle = new Riddle(
                data.id,
                data.title,
                data.riddle_name,
                data.subtitle,
                data.description,
                data.description_short,
                data.templatedata,
                data.thumbnail,
                data.videourl,
                data.user_email,
                data.is_published,
                data.keywords,
                data.accessories,
                data.payment_type,
                data.print_files,
                data.riddlestemplates,
                data.tags,
                data.created_at
            )

            return riddle;

        } catch (error) {
            throw error
        }
    }
};

export const getSingleTool = toolId => {

    return async (dispatch, getState) => {
        try {
            const response = await fetch(`${tools_url}/${toolId}`);
            if (!response.ok) {
                throw new Error('Something went wrong');
            }
            const responseData = await response.json();
            let toolData;
            if (!!responseData) {
                const toolComponent = tools.find(tool => responseData.categories.some(cat => cat === tool.id))
                console.log(toolComponent);
                toolData =
                    new Tool(
                        responseData.id,
                        responseData.title,
                        responseData.description,
                        responseData.thumbnail,
                        '',
                        responseData.password,
                        {
                            ...responseData.categorydata
                        },
                        responseData.answer,
                        !!toolComponent && !!toolComponent.component ? toolComponent.component : toolComponent
                    )
                return toolData
            }
        } catch (error) {
            throw error
        }
    }
}