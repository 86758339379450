import React, { useEffect, useState } from "react";
import "./screen-homepage.scss";
import Loader from "../01_atoms/loaders/simple-loader/loader";
import Button from "../01_atoms/button";
import { api } from "../../config";
import TITLE from "../../assets/images/pizza/start_frame_title.png";
import BACKGROUND from "../../assets/images/pizza/open_frame_bg_clean.png";
import BUTTON_REG from "../../assets/images/pizza/button_reg.png";
import BUTTON_PRESS from "../../assets/images/pizza/button_pressed.png";
import START_VIDEO from "../../assets/images/pizza/intro_video_final.mp4";
import { adventureSpace } from "../../store/mocks/space-mock";
import { DICTIONARY } from "../../dictionary/dictionary";
import { Checkbox } from "@material-ui/core";
import FieldCheckbox from "../02_molecules/form-fields/checkbox";

const adventure_passcode_url = `${api}/adventures-by-passcode`;

const PASSCODE = "2r1rvs"; // adventure id: 5824

const ScreenHomepage = (props) => {
  const localLanguage = localStorage.getItem("language");
  const [isLoading, setIsLoading] = useState(false);
  const [termsApproved, setTermsApproved] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [isAdventureSuccess, setIsAdventureSuccess] = useState(false);
  const [language, setLanguage] = useState(
    localLanguage && localLanguage !== "null" && localLanguage !== "undefined"
      ? localLanguage.toLowerCase()
      : "hebrew"
  );
  const [isFalsePassword, setIsFalsePassword] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const [enterCode, setEnterCode] = useState(
    DICTIONARY.instructions.enter_code[language]
  );
  const [continue_, setContinue_] = useState(
    DICTIONARY.instructions.continue_[language]
  );
  const [startTheAdventure, setStartTheAdventure] = useState(
    DICTIONARY.instructions.start_the_adventure[language]
  );
  const [guide, setGuide] = useState(DICTIONARY.instructions.guide[language]);
  const [downloadFile, setDownloadFile] = useState(
    DICTIONARY.instructions.download_file[language]
  );

  useEffect(() => {
    localStorage.setItem("language", language);
    setEnterCode(DICTIONARY.instructions.enter_code[language]);
    setContinue_(DICTIONARY.instructions.continue_[language]);
    setStartTheAdventure(DICTIONARY.instructions.start_the_adventure[language]);
    setGuide(DICTIONARY.instructions.guide[language]);
    setDownloadFile(DICTIONARY.instructions.download_file[language]);
  }, [language]);

  const riddles = adventureSpace.riddles;

  const resetCluesLocalStorage = () => {
    for (let i = 0; i < riddles.length; i++) {
      localStorage.setItem(`riddle-clue-${i + 1}`, "0");
    }
  };

  const onSubmit = async () => {
    if (!termsApproved) {
      alert("יש לאשר את תקנון המשחק");
      return;
    }
    localStorage.setItem("space-level", "0");

    setIsLoading(true);
    setUserPassword("");
    setIsFalsePassword(false);

    try {
      const response = await fetch(
        `${adventure_passcode_url}/?passcode=${PASSCODE}`
      );
      if (!response.ok) {
      } else {
        const data = await response.json();
        if (Object.keys(data).length === 0) {
          setIsFalsePassword(true);
        } else {
          const adventure = {
            id: data.id,
            name: data.json_data.name,
            number: data.json_data.number,
            image: data.json_data.image,
          };

          // if (data.adventure_name === "space-adventure") {
          localStorage.setItem("adventureId", adventure.id);
          localStorage.setItem("phoneNumber", adventure.number);
          localStorage.setItem("image", adventure.image);
          localStorage.setItem("space-level", "1");
          setLanguage(data.language || "hebrew");
          resetCluesLocalStorage();
          setIsAdventureSuccess(true);
          videoStarted();
          // }
        }
        setIsLoading(false);
      }
    } catch (error) {
      throw error;
    }
  };

  const videoStarted = () => {
    let video = document.getElementById("space-starter-video");
    video.onended = () => setVideoEnded(true);
  };

  const startAdventure = () => {
    window.location.href = "/spaceship";
  };

  const onPasswordChange = (password) => {
    setUserPassword(password);
    // setIsFalsePassword(false);
  };

  const getUserPasscode = (
    <div>
      <div className={"input-container"}>
        <h2 className={"guide"}>{enterCode}</h2>
        {/*<InsertPasscode checkPasscode={(value) => {*/}
        {/*setUserPassword(value)*/}
        {/*}}/>*/}
        <div>
          <input
            name={"password"}
            autoComplete={"off"}
            className={"passcode-input"}
            value={userPassword.toLowerCase()}
            onChange={(e) => onPasswordChange(e.target.value)}
          />
        </div>
      </div>
    </div>
  );

  const adventureVideo = (
    <>
      {/*<iframe width="560" */}
      {/*height="315" */}
      {/*style={{maxWidth: '100%'}} */}
      {/*src={START_VIDEO} */}
      {/*frameBorder="0"*/}
      {/*allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
      {/*allowFullScreen/>*/}
      <video
        id='space-starter-video'
        autoPlay={true}
        controls
        style={{ width: "560px", maxWidth: "100%", height: "315px" }}>
        <source src={START_VIDEO} type='audio/mp4' />
      </video>
    </>
  );
  const boardHebrew =
    "https://images.youdoadventures.com/1600684036093-space_board_pdf.pdf";
  const boardEnglish =
    "https://images.youdoadventures.com/1605597004941-emergency_flight_manual_pdf.pdf";
  const gameBoard =
    language && language.toLowerCase() === "english"
      ? boardEnglish
      : boardHebrew;
  const termsValue = `אני מאשר את <a href="/">תקנון</a> המשחק`;

  return (
    <div
      className={"home-page-container"}
      style={{ background: `url(${BACKGROUND}) center center /cover` }}>
      <img className={"logo"} src={TITLE} />

      {isAdventureSuccess && adventureVideo}

      <div className={"continue-wrapper"}>
        {isLoading ? (
          <Loader size={43} color={"#fff"} />
        ) : isAdventureSuccess ? (
          videoEnded && (
            <div className={"continue"} onClick={startAdventure}>
              <img src={BUTTON_REG} className={"button-reg"} />
              <img src={BUTTON_PRESS} className={"button-press"} />
              <span style={{ fontSize: "20px" }}>{startTheAdventure}</span>
            </div>
          )
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <div className={"continue"} onClick={onSubmit}>
              <img src={BUTTON_REG} className={"button-reg"} width={150} />
              <img src={BUTTON_PRESS} className={"button-press"} width={150} />
              <span>התחלה</span>
            </div>
            <div
              className={"d-flex align-items-center"}
              style={{ marginTop: "100px" }}>
              <input
                type={"checkbox"}
                style={{ width: "20px", height: "20px" }}
                onChange={(event) => setTermsApproved(event.target.checked)}
              />
              <span style={{ fontSize: "18px", color: "#fff" }}>
                קראתי ואני מאשר את{" "}
                <a
                  style={{ color: "#fff", textDecoration: "underline" }}
                  href='/terms'>
                  תקנון
                </a>{" "}
                המשחק
              </span>
              {/*|<FieldCheckbox name={"terms"}*/}
              {/*               style={{color:"#fff"}}*/}
              {/*               value={termsApproved ? termsValue : ""}*/}
              {/*               changeHandler={(name, value) => setTermsApproved(value)}*/}
              {/*               options={[termsValue]}/>*/}
            </div>
          </div>
        )}
      </div>

      {/*<div className={"download-printfile"}>*/}
      {/*    <p>{guide}</p>*/}
      {/*    <a href={gameBoard} target={"_blank"}>*/}
      {/*        <Button pointer>{downloadFile}</Button>*/}
      {/*    </a>*/}
      {/*</div>*/}
      {/*<h3 className={"choose-language"}>{DICTIONARY.instructions.choose_language[language]}</h3>*/}
      {/*<div className={"language-container"}>*/}
      {/*    <button onClick={e => setLanguage('hebrew')} className={"he"}>עברית</button>*/}
      {/*    <button onClick={e => setLanguage('english')} className={"en"}>English</button>*/}
      {/*</div>*/}
    </div>
  );
};

ScreenHomepage.default = {};
ScreenHomepage.propTypes = {};

export default ScreenHomepage;
