import React, { useState, useEffect } from "react";
import { adventureSpace } from "../../store/mocks/space-mock";
import { RiddlesWrapper } from "../riddles/RiddlesWrapper/riddles-wrapper";
import { WarningMessage } from "../warning-message";
import { StarsBackground } from "../03_organisms/stars-background/stars-background";
import classnames from "classnames";
import MenuReturn from "../ScreenMenuReturn/screen-menu-return";
import "./screen-clue.scss";

import { DICTIONARY } from "../../dictionary/dictionary";

export const ScreenClue = ({ match, history }) => {
  const language = localStorage.getItem("language");

  const id = match.params.id;
  const riddle = adventureSpace.riddles.find(
    (riddle) => riddle.id === parseInt(id)
  );
  const clues = riddle.clues;
  const [clueActive, setClueActive] = useState(-1);
  const [warning, setWarning] = useState(-1);
  const clueLevel = localStorage.getItem(`riddle-clue-${id}`);
  const currentLevel = localStorage.getItem("space-level");

  if (currentLevel < id) {
    history.push("/spaceship");
  }

  useEffect(() => {
    if (warning >= 0) {
      setTimeout(() => {
        setWarning(-1);
      }, 1000);
    }
  }, [warning]);

  const openClue = (index) => {
    const level = parseInt(clueLevel);

    if (index <= clueLevel) {
      const newLevel = level + 1;
      setClueActive(index);

      if (index + 1 >= newLevel) {
        localStorage.setItem(`riddle-clue-${id}`, JSON.parse(newLevel));
      }
    } else {
      setWarning(level);
    }
  };

  const triggerClueItem = (clue, index) => {
    const isUsed = clueLevel > index;
    const isWarning = warning === index;
    const classes = classnames(
      "clue-trigger-item",
      //{ disabled: index > clueLevel },
      // { used: isUsed },
      { warning: isWarning }
    );
    return (
      <div key={index} className={classes} onClick={() => openClue(index)}>
        {clue.title}
      </div>
    );
  };

  const ActiveClue = ({ clue }) => {
    const endOfDesc = clue.description.substr(
      clue.description.length - 4,
      clue.description.length - 1
    );
    return (
      <div className={"clues-active"}>
        <h5 className={"clue-subtitle"}>{clue.subtitle}</h5>
        {endOfDesc === ".jpg" ? (
          <img
            style={{ height: "100%", width: "100%" }}
            src={clue.description}
          />
        ) : (
          <p className={"clue-description"}>{clue.description}</p>
        )}
        <button className={"clue-close"} onClick={() => setClueActive(-1)}>
          {DICTIONARY.instructions.go_back[language]}
        </button>
      </div>
    );
  };

  return (
    <div className='autopilot-access-container '>
      <div className='screen-clue'>
        <WarningMessage />
        <StarsBackground />
        <MenuReturn riddleId={riddle.id} historyRoute={history} />

        <RiddlesWrapper title={riddle.title} />

        <div className={"screen-clue-wrapper"}>
          <div className={"screen-clue-logo"} />
          <div className={"screen-clue-content"}>
            {clueActive < 0 ? (
              <div className={"clues-menu"}>{clues.map(triggerClueItem)}</div>
            ) : (
              <ActiveClue clue={clues[clueActive]} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
