import React, { useState } from "react";
import PropTypes from "prop-types";
import "./space-rotary.scss";
import BORER_1 from "../../../assets/images/pizza/borer01.png";
import BORER_2 from "../../../assets/images/pizza/borer02.png";
import BORER_3 from "../../../assets/images/pizza/borer03.png";
import BORER_4 from "../../../assets/images/pizza/borer04.png";
import MAHOG from "../../../assets/images/pizza/mahogborer01.png";

const SpaceRotary = ({
  id,
  degressAddOnClick,
  color,
  onChange,
  backgroundColor,
  showNumbers,
  isResolved,
  initialRotateValue = 0,
}) => {
  const [rotateDegrees, setRotateDegrees] = useState(initialRotateValue);
  const rotaryId = "rotary-" + id;

  const removeFullRoundes = (degrees) => {
    let numToReturn = degrees;
    while (numToReturn >= 360) {
      numToReturn -= 360;
    }
    return numToReturn;
  };

  const onRotaryClick = () => {
    if (!isResolved) {
      const newDegrees = rotateDegrees + degressAddOnClick;
      setRotateDegrees(newDegrees);
      document.getElementById(
        rotaryId
      ).style.transform = `rotate(${newDegrees}deg)`;
      onChange(removeFullRoundes(newDegrees));
    }
  };

  const renderRotary = () => {
    const circleStyle = {
      borderColor: color,
      boxShadow: `0 0 10px ${color}, inset 0 0 10px ${color}`,
      transform: `rotate(${rotateDegrees}deg)`,
    };
    return (
      <div className={"rotary"} style={circleStyle} id={rotaryId}>
        <div
          className={"rotary-dot"}
          style={{ backgroundColor: color, boxShadow: `0 0 20px ${color}` }}
        />
      </div>
    );
  };
  const imgStyle = {
    transform: `rotate(315deg)`,
    transformOrigin: "center center",
    width: "60px",
  };
  const renderNumbersRotary = () => {
    return (
      <div className={"numbers-img"}>
        <div className={"rotary withNumbers"} id={rotaryId}>
          <div className={"rotary-dot"}>
            <img src={MAHOG} style={imgStyle} />
          </div>
        </div>
      </div>
    );
  };

  const getBorerImage = (id) => {
    console.log(id);
    if (id === "0") {
      return BORER_1;
    }
    if (id === "1") {
      return BORER_2;
    }
    if (id === "2") {
      return BORER_3;
    }
    if (id === "3") {
      return BORER_4;
    }
  };

  return (
    <div
      className={"space-rotary-wrapper"}
      style={{
        backgroundColor,
        backgroundImage: `url(${getBorerImage(id)})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
      onClick={onRotaryClick}>
      {showNumbers ? renderNumbersRotary() : renderRotary()}
    </div>
  );
};

SpaceRotary.defaultProps = {
  degressAddOnClick: 45,
  color: "green",
  backgroundColor: "rgba(0,0,0,0.7)",
  showNumbers: false,
};

SpaceRotary.propTypes = {
  id: PropTypes.string,
  degressAddOnClick: PropTypes.number,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  onChange: PropTypes.func,
  showNumbers: PropTypes.bool,
};

export default SpaceRotary;
