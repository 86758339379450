import React from "react";
import { Link } from "react-router-dom";
import "./password-success.scss";
import PropTypes from "prop-types";
import { DICTIONARY } from "../../dictionary/dictionary";

export const PasswordSuccess = ({
  title,
  color = "#000",
  text_link,
  goto = "/spaceship",
}) => {
  const language = localStorage.getItem("language") || "hebrew";
  return (
    <div className={"success-message"}>
      <h3 style={{ color: color }}>
        {DICTIONARY.instructions.inputted[language]}
      </h3>
      <div className='success-image'>
        <button
          style={{
            backgroundColor: "black",
            border: "1px solid #bd0021",
            borderRadius: "50px",
            width: "320px",
            marginLeft: "-23px",
          }}>
          <Link style={{ color: "white" }} to={goto}>
            {DICTIONARY.instructions.continue_to_bridge[language]}
          </Link>
        </button>
      </div>
    </div>
  );
};

PasswordSuccess.defaultProps = {
  title: "פיצחתם את הקוד",
  text_link: "המשך לגשר הפיקוד",
};

PasswordSuccess.propTypes = {
  title: PropTypes.string,
  text_link: PropTypes.string,
};
