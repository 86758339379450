import React from 'react';
import {Link} from 'react-router-dom';
import {HBox, Element} from 'react-stylesheet';
import styled from 'styled-components';
import {MdPerson, MdKeyboardArrowDown} from "react-icons/md";
import {font_size, colors, gutters} from '../../00_base/variables';
import {Image, Circle, Span} from '../../01_atoms';
import {phoneNumbers, letters} from "./phone-numbers";


const ToolContactList = (
    {
        items,
        riddleId,
        screenBackground,
        textColor,
        borderColor = colors.black,
        borderWidth = 1
    }) => {
    return (
        <Element style={{flex: 1, backgroundColor: screenBackground}}>
            <ListStyle style={listStyle}>
                <Element width={'100%'}>
                    {items.filter(item => item.name).map((item, i) => {
                        return (
                            <HBox key={i}
                                  className={i}
                                  alignItems='center'
                                  padding={`0 0 0 ${gutters.sm}`}>
                                <Circle size={47}
                                        background={item.color ? item.color : '#1e5a80'}
                                        color='#000'>
                                    {item.icon ? <Image src={item.icon}/> : <MdPerson color={'#fff'} size={23}/>}
                                </Circle>
                                <HBox alignItems='center' style={{flex: 'auto'}}>
                                    <Link to={{
                                        pathname: `/phone-call`,
                                        riddleId: riddleId,
                                        name: item.name,
                                        audio: item.audio ? item.audio : 'https://admin.youdoadventures.com/wp-content/uploads/2019/04/no_answer.mp3',
                                        video: item.video,
                                        placeholder: item.placeholder,
                                        number: phoneNumbers[i]
                                    }}
                                          style={{
                                              ...listItem,
                                              color: textColor,
                                              borderBottom: `${borderWidth}px solid ${borderColor}`
                                          }}>
                                        <HBox justifyContent={'space-between'} alignItems={'center'}>
                                            <Element>
                                                    <Span color={textColor}
                                                          style={{
                                                              fontSize: font_size.md,
                                                              margin: '0',
                                                          }}>{item.name}</Span>
                                                <Span fontSize={font_size.xs}
                                                      style={{margin: '0', color: textColor}}>
                                                        {phoneNumbers[i]}
                                                    </Span>
                                            </Element>
                                            <Element>
                                                <MdKeyboardArrowDown color={textColor} size={30}/>
                                            </Element>
                                        </HBox>
                                    </Link>
                                </HBox>
                            </HBox>
                        );
                    })}
                </Element>

                <Element width={'1.5rem'} padding={'1rem'}>
                    {letters.map((letter, l) => {
                        return (
                            <div key={l} style={{color: textColor}}>{letter}</div>
                        )
                    })}
                </Element>
            </ListStyle>

        </Element>
    )
};

const ListStyle = styled.div`
&::-webkit-scrollbar {
    display: none;
}

&::-webkit-scrollbar-track {
    display: none;
}

&::-webkit-scrollbar-thumb {
    display: none;
}
`;

const listItem = {
    display: 'block',
    width: '100%',
    fontSize: font_size.md,
    textDecoration: 'none',
    marginRight: gutters.sm,
    paddingTop: gutters.xs,
    paddingBottom: gutters.xs,
};
const listStyle = {
    padding: '0 1rem',
    zIndex: '3',
    overflow: 'scroll',
    position: 'relative',
    top: '0',
    borderLeftTopRadius: '2rem',
    borderRightTopRadius: '2rem',
    display: 'flex',

};
export default ToolContactList;