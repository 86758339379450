import React, { useState, useEffect, useReducer, useCallback } from "react";
import PropTypes from "prop-types";
import Button from "../../01_atoms/button/a-button";
import FieldText from "../../02_molecules/form-fields/text/m-field-text";
import FieldSelect from "../../02_molecules/form-fields/select/m-field-select";
import FieldRadio from "../../02_molecules/form-fields/radio/m-field-radio";
import FieldCheckbox from "../../02_molecules/form-fields/checkbox/m-field-checkbox";
import FieldTextArea from "../../02_molecules/form-fields/textarea/m-field-textarea";
import FilesUploader from "../../02_molecules/form-fields/files-uploader/m-files-uploader";
import { Checkbox } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { none } from "react-stylesheet/lib/css";
const UPDATE_INPUT = "UPDATE_INPUT";

const formReducer = (state, action) => {
  if (action.type === UPDATE_INPUT) {
    const updatedValues = {
      ...state.inputValues,
      [action.input]: action.value,
    };
    const updateValiditeis = {
      ...state.inputValidities,
      [action.input]: action.isValid,
    };
    let updatedFormIsValid = true;
    for (const key in updateValiditeis) {
      updatedFormIsValid = updatedFormIsValid && updateValiditeis[key];
    }

    return {
      inputValues: updatedValues,
      inputValidities: updateValiditeis,
      formIsValid: updatedFormIsValid,
    };
  }
  return state;
};

const initialValues = (fields) => {
  let fieldsData;
  for (const key in fields) {
    fieldsData = {
      ...fieldsData,
      [fields[key].name]: fields[key].value,
    };
  }
  return fieldsData;
};

const initialValidities = (fields) => {
  let fieldsData;
  for (const key in fields) {
    fieldsData = {
      ...fieldsData,
      [fields[key].name]: !!fields[key].name,
    };
  }
  return fieldsData;
};

const getFieldComponent = (type) => {
  switch (type) {
    case "text":
      return FieldText;
    case "select":
      return FieldSelect;
    case "radio":
      return FieldRadio;
    case "checkbox":
      return FieldCheckbox;
    case "textarea":
      return FieldTextArea;
    case "files":
      return FilesUploader;
    default:
      return FieldText;
  }
};

const Form = ({
  width,
  fields,
  handleFormSubmit,
  submitLabel,
  submitBlock,
  hideTrigger,
  submitStyle,
}) => {
  const [isTrue, setIsTrue] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: initialValues(fields),
    inputValidities: initialValidities(fields),
    formIsValid: !!fields,
  });

  const dispatch = "";

  const inputChangedHandler = useCallback(
    (name, value, isValid) => {
      dispatchFormState({
        type: UPDATE_INPUT,
        value: value,
        isValid: isValid,
        input: name,
      });
    },
    [dispatchFormState]
  );

  const formSubmitHandler = useCallback(async () => {
    //
    // if (!formState.formIsValid) {
    //     alert('Form is not valid. Please check your fields');
    //     return;
    // }

    setError(null);
    setIsLoading(true);

    handleFormSubmit(
      formState.inputValues,
      handleSubmitSuccess,
      handleSubmitError
    );
  }, [formState, handleFormSubmit]);

  const handleSubmitSuccess = () => {
    setIsLoading(false);
  };

  const handleSubmitError = () => {
    setIsLoading(false);
    setError(true);
  };

  const renderField = (field, index) => {
    const Field = getFieldComponent(field.type);
    return (
      <Field
        {...field}
        fieldStyle={field.fieldStyle}
        key={index}
        changeHandler={inputChangedHandler}
        initialValue={formState.inputValues[field.name]}
        initiallyValid={formState.inputValidities[field.name]}
      />
    );
  };

  useEffect(() => {}, [isLoading, dispatch]);
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formSubmitHandler();
      }}
      style={{ width: "100%" }}>
      {fields.map(renderField)}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Checkbox
          onChange={(e) => {
            console.log(e.target.checked);
            setIsTrue(e.target.checked);
            
          }}
          style ={{
            color: "#fffffe",
          }}
        />
        <h2 style={{ fontSize: "1.6rem", color: "#fffffe" }}>
          אני מאשר.ת קבלת דיוור ועדכון על מבצעים מפיצה האט.
        </h2>
      </div>

      {!hideTrigger && (
        <div>
          {isTrue ? (
            <Button
              value={submitLabel}
              block={submitBlock}
              style={submitStyle}
            />
          ) : (
            <button
              onClick={() => setIsDisabled(true)}
              disabled={isDisabled}
              style={{
                width: "23rem",
                borderRadius: "30px",
                display: "block",
                margin: "0 auto",
                padding: "10px 0",
                fontWeight: "bold",
                fontFamily: "Fedra Sans Hebrew Book",
                fontSize: "2rem",
                backgroundColor: "#0dff00",
                color: "#000",
                border: 0,
                outline: "none",
              }}>
              שליחה
            </button>
          )}

          {isDisabled && !isTrue ? (
            <h2 style={{marginRight:"108px"}}>על מנת לקבל את ההטבה למייל יש לאשר דיוור.</h2>
          ) : null}
        </div>
      )}
    </form>
  );
};

Form.defaultProps = {
  submitLabel: "submit",
  submitBlock: true,
};

Form.propTypes = {
  submitBlock: PropTypes.bool,
  submitStyle: PropTypes.object,
  handleFormSubmit: PropTypes.func,
  fields: PropTypes.array.isRequired,
};

export default Form;
