import React from 'react';

import { Element, HBox } from 'react-stylesheet';




import { Container } from 'react-grid-system';
import { gutters, colors } from '../../00_base/variables';
import { Image, Text } from '../../01_atoms';
import {BackToMenu} from "../../02_molecules/back-to-menu/back-to-menu";




const ToolDefiner = ({ items }) => {
    return (
        <Element background={'#fff'}>
            <Container style={{ marginTop: gutters.sm }}>
                {items.map((item, i) => {
                    const { title, description, image, color } = item;
                    return (
                        <Element key={i} marginBottom={'2rem'} className={'px-4'} width={'100%'} minHeight={180}>
                            <div style={{ ...definerContent, color: colors.black }}>
                                {!!title && <Text tag={'h3'} style={definerTitleStyle}>{title}</Text>}
                                {!!image &&
                                    <HBox style={definerImage}
                                        border={color ? `.6rem solid ${color}` : '0'}>
                                        <Image width={100} src={image} />
                                    </HBox>
                                }
                                {!!description &&
                                    <Text tag={'p'}
                                        style={definerDescription}>
                                        {description}
                                    </Text>
                                }
                            </div>
                        </Element>
                    )
                })}
                <BackToMenu/>
            </Container>
        </Element>
    )
}

export default ToolDefiner;

const
    definerImage = {
        float: 'left',
        marginRight: gutters.sm,
        display: 'block'
    },
    definerTitleStyle = {
        fontSize: '2rem'
    },
    definerContent = {
        padding: `0 ${gutters.sm}`,
        marginBottom: gutters.sm
    },
    definerDescription = {
        fontSize: '1.4rem',
    };