import React, {useState, useEffect, useReducer, useContext} from 'react';
import {HBox, VBox, Element} from 'react-stylesheet';
import {Link} from "react-router-dom";
import {Button, Circle, Heading, Paragraph} from '../../01_atoms';
import {colors, gutters, font_size} from '../../00_base/variables';
import {Container, Row, Col} from 'react-grid-system';
import {Wrapper, Question, IndicatorWrapper, OptionSquare} from './tool-trivia-styles';
import './tool-trivia-style.css';
import Image from "../../01_atoms/image/a-image";
import {MdCheckCircle} from 'react-icons/md';
import {DICTIONARY} from "../../../dictionary/dictionary";

const TriviaContext = React.createContext();
const answersReducer = (state, action) => {
    const index = action.index;
    state[index].u = action.value;
    return state;
};

const language = localStorage.getItem('adventure-language');

const ToolTrivia = ({
                        items,
                        finalTitle,
                        finalSubtitle = DICTIONARY.the_answer_is_the_ode_To_The_next_question[language],
                        finalDescription = '',
                        wrongFinalTitle = 'תקנו את התשובות השגויות (מסומנות באדום)',
                        finalImage,
                        screenBackground = colors.white,
                        textColor = colors.black,
                        btnPrevBg = colors.primary,
                        btnPrevTextColor = colors.white,
                        btnNextBg = colors.primary,
                        btnNextTextColor = colors.white
                    }) => {

    const toolStyle = {
        screenBackground,
        textColor,
        btnPrevBg,
        btnPrevTextColor,
        btnNextBg,
        btnNextTextColor
    };
    const adventureName = localStorage.getItem('adventureName');
    const [activeQuestion, setActiveQuestion] = useState(0);
    const [markIndicators, setMarkIndicators] = useState(false);

    useEffect(() => {
        if (activeQuestion >= items.length) {
            setMarkIndicators(true)
        }
    }, [activeQuestion, items.length]);

    const defaultAnswers = items.map((item, index) => {
        return {a: item.answer, u: -1, key: item.id}
    });
    const [answers, dispatchAnswers] = useReducer(answersReducer, defaultAnswers);

    const finalScreenActive = activeQuestion >= items.length;
    return (
        <TriviaContext.Provider
            value={{
                answers,
                dispatchAnswers,
                items,
                activeQuestion,
                setActiveQuestion,
                toolStyle,
                adventureName,
                finalScreenActive,
                finalTitle,
                finalSubtitle,
                finalDescription,
                finalImage,
                wrongFinalTitle
            }}>
            <Wrapper className="trivia-wrapper" backgroundColor={toolStyle.screenBackground}>
                <Container>
                    {!finalScreenActive &&
                    <TriviaIndicators markIndicators={markIndicators}/>
                    }
                    {finalScreenActive ?
                        <TriviaFinalScreen/> :
                        items.map((item, index) => {
                            const isActive = index === activeQuestion;
                            return <TriviaQuestion {...item}
                                                   key={item.id}
                                                   toolStyle={toolStyle}
                                                   isActive={isActive}
                                                   prevQuestion={() => setActiveQuestion(activeQuestion - 1)}
                                                   nextQuestion={() => setActiveQuestion(activeQuestion + 1)}/>
                        })
                    }

                </Container>
            </Wrapper>

        </TriviaContext.Provider>
    )
};

const TriviaIndicators = ({markIndicators, isFinalScreen}) => {
    const {answers, items, activeQuestion, setActiveQuestion, finalScreenActive} = useContext(TriviaContext);

    return <HBox justifyContent={'space-around'} paddingTop={gutters.sm} flexWrap={finalScreenActive ? 'wrap' : 'nowrap'}>
        {answers.map((answer, index) => {
            const isActive = activeQuestion === index;
            const value = answer.u >= 0 ? answer.u + 1 : '';
            const background = items[index].color;
            const checkIcon = markIndicators && answer.u === answer.a;
            const borderColor = isActive ? "black" : (finalScreenActive || markIndicators) ? answer.u === answer.a ? background : "red" : colors[isActive ? 'black' : 'white'];
            const hue = colors.lightOrDark(background);
            const color = hue === 'light' ? colors.black : colors.white;
            const circleValue = checkIcon ? <MdCheckCircle size={isFinalScreen ? 50 : 25} color={background}/> : value;
            const borderWidth = isActive ? 2 : checkIcon ? 0 : finalScreenActive ? 3 : 2;
            return <IndicatorWrapper isFinalScreen={finalScreenActive} isActive={isActive} key={index}>
                <Circle value={circleValue}
                        size={finalScreenActive ? 70 : 25}
                        background={checkIcon ? "transparent" : background}
                        color={color}
                        fontSize={font_size.sm}
                        fontWeight={'bold'}
                        border={`${borderWidth}px solid ${borderColor}`}
                        style={{cursor: 'pointer'}}
                        onClick={() => setActiveQuestion(index)}/>
            </IndicatorWrapper>
        })}
    </HBox>
};

const TriviaQuestion = ({
                            id,
                            answer,
                            options,
                            question,
                            questionLabel,
                            color,
                            isActive,
                            nextQuestion,
                            prevQuestion,
                            toolStyle
                        }) => {
    const {adventureName} = useContext(TriviaContext);
    const language = localStorage.getItem('adventure-language');

    const {btnPrevBg, btnPrevTextColor, btnNextBg, btnNextTextColor} = toolStyle;
    return (
        <Question isActive={isActive}>
            <Heading tag={'h1'}
                     fontSize={font_size.lg}
                     color={toolStyle.textColor}
                     textAlign={'center'}
                     style={{marginBottom: 0}}
                     value={questionLabel || `${DICTIONARY.question[language]} ${id + 1}`}/>

            <Paragraph textAlign={'center'}
                       fontWeight={'bold'}
                       color={toolStyle.textColor}
                       fontSize={font_size.md}
                       style={{marginTop: 0}}
                       value={question.replace("{name}", adventureName)}/>

            <TriviaOptions questionId={id}
                           color={color}
                           options={options}/>

            <HBox justifyContent={'center'} marginTop={gutters.xs}>
                {
                    id !== 0 &&
                    <Button block
                            variant={'secondary'}
                            onClick={id > 0 && prevQuestion}
                            value={DICTIONARY.go_back[language]}
                            pointer={'pointer'}
                            style={{
                                backgroundColor: btnPrevBg,
                                color: btnPrevTextColor,
                                margin: '0 0 0 5px'
                            }}/>
                }
                <Button block
                        variant={'primary'}
                        value={DICTIONARY.continue[language]}
                        onClick={nextQuestion}
                        pointer={'pointer'}
                        style={{
                            ...id === 0 ? {margin: '0'} : {margin: '0 5px 0 0'},
                            backgroundColor: btnNextBg,
                            color: btnNextTextColor,
                        }}/>
            </HBox>
        </Question>
    )
};

const TriviaOptions = ({questionId, color, options}) => {
    const {dispatchAnswers, answers} = useContext(TriviaContext);
    const [active, setActive] = useState(null);
    const clickHandler = index => {
        setActive(index);
        dispatchAnswers({index: questionId, value: index});
    };

    return (
        <Row>
            {options.map((option, index) => {
                const isActive = answers[questionId].u === index || active === index;
                const hue = colors.lightOrDark(color);
                const textColor = hue === 'light' ? colors.black : colors.white;
                return (
                    <Col xs={6} style={{paddingTop: gutters.xs, paddingBottom: gutters.xs}} key={index}>
                        <OptionSquare onClick={() => clickHandler(index)}
                                      isActive={isActive}
                                      color={textColor}
                                      background={color}>
                            {option}
                        </OptionSquare>
                    </Col>
                )
            })}
        </Row>
    )
};

const TriviaFinalScreen = () => {
    const {answers, items, finalTitle, finalSubtitle, finalDescription, finalImage, wrongFinalTitle, setActiveQuestion} = useContext(TriviaContext);
    const correctAnswers = answers.filter(answer => answer.u === answer.a);
    const isFinish = correctAnswers.length === answers.length;
    const language = localStorage.getItem('adventure-language');

    if (isFinish) {
        return (
            <Element width={'100%'} textAlign={'center'} marginTop={gutters.lg}>
                <VBox justifyContent={'center'} alignItems={'center'}>
                    {finalTitle &&
                    <Heading tag={'h2'}
                             textAlign={'right'}
                             color={colors.black}
                             value={finalTitle}/>
                    }
                    {finalSubtitle &&
                    <Heading tag={'h6'}
                             textAlign={'right'}
                             fontSize={'2.2rem'}
                             style={{margin: 0}}
                             fontWeight={'normal'}
                             color={colors.black}
                             value={finalSubtitle}/>
                    }
                    {finalDescription &&
                    <Heading tag={'h6'}
                             textAlign={'right'}
                             style={{margin: 0}}
                             fontSize={'1.6rem'}
                             fontWeight={'normal'}
                             color={colors.black}
                             value={finalDescription}/>
                    }
                </VBox>

                <TriviaIndicators markIndicators={true}/>
                <Button block
                        value={DICTIONARY.continue[language]}>
                    <Link to={"/menu"} style={{color:"#fff"}}>{DICTIONARY.continue[language]}</Link>
                </Button>
            </Element>
        )
    }
    return (
        <VBox
            // justifyContent={'center'}
            alignItems={'center'}
            width={'100%'}
            textAlign={'center'}>
            <Heading tag={'h3'}
                     color={colors.black}
                     style={{marginBottom: gutters.sm}}
                     value={`${DICTIONARY.you_answered[language]} ${correctAnswers.length} ${DICTIONARY.out_of[language]} ${items.length} ${DICTIONARY.questions_correctly[language]}`}/>
            <Heading tag={'h4'}
                     style={{marginTop: 0}}
                     color={colors.black}
                     value={wrongFinalTitle}/>
            <TriviaIndicators markIndicators={true} isFinalScreen={true}/>
            <Button block
                    size={'md'}
                    onClick={() => setActiveQuestion(0)}>
                {DICTIONARY.continue[language]}
            </Button>
        </VBox>
    )
};
export default ToolTrivia;