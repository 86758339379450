import styled from 'styled-components';
import {colors, gutters} from "../../00_base/variables";

export const Wrapper = styled.div`
    background-color: ${props => props.backgroundColor};
    flex: 1;
`;
export const Question = styled.div`
    display: ${props => props.isActive ? 'block' : 'none'};
    max-width: 50rem;
    margin: 0 auto;
`;
export const IndicatorWrapper = styled.div`
    padding: 0;
    width: ${props => props.isFinalScreen ? "33.333%" : "auto"}
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
`;


export const OptionSquare = styled.div`
    padding: ${gutters.xs};
    min-height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 2.5rem;
    border: 2px solid ${props => props.background ? props.background : colors.black};
    background: ${props => props.isActive ? props.background : colors.white};
    color: ${props => props.isActive ? props.color : colors.black};
    height: 100%;
    width: 100%;
    box-sizing: border-box !important;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    transition: box-shadow 0.3s;

    :hover {
        cursor: pointer;
        box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    }
`;



