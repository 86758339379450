import React from "react";
import { useHistory } from "react-router-dom";
import "../ScreenMenuReturn/screen-menu-return.scss";
import BACKGROUND from "../../assets/images/pizza/open frame clean desktop_thumb.jpeg";
import BUTTON_REG from "../../assets/images/pizza/button_reg.png";
import BUTTON_PRESS from "../../assets/images/pizza/button_pressed.png";
import MenuReturn from "../ScreenMenuReturn/screen-menu-return";
import BUTTON_REG_NEW from "../../assets/images/pizza/button_reg_new.png";
import BUTTON_PRESS_NEW from "../../assets/images/pizza/button_pressed_new.png";

import { Link } from "react-router-dom";

const pStyle = {
  lineHeight: "1.38",
  textAlign: "right",
  marginTop: 0,
  marginBottom: 0,
  padding: "0pt 0pt 8pt 0pt",
};
const h1Style = {
  lineHeight: "1.32",
  marginRight: "4pt",
  textAlign: "right",
  marginTop: 0,
  marginBottom: "0pt",
  padding: "7.5pt 0pt 22.5pt 0pt",
  
};
const h1SpanStyle = {
  fontSize: "22pt",
  fontFamily: "Arial",
  color: "#000000",
  // backgroundColor:"transparent",
  fontWeight: 400,
  fontStyle: "normal",
  fontVariant: "normal",
  textDecoration: "none",
  verticalAlign: "baseline",
  // whiteSpace:"pre",
  whiteSpace: "pre-wrap",
};

const pStyleSpan = {
  fontSize: "12pt",
  fontFamily: "Arial",
  color: "#000000",
  // backgroundColor:"transparent",
  fontWeight: 700,
  fontStyle: "normal",
  fontVariant: "normal",
  textDecoration: "none",
  verticalAlign: "baseline",
  whiteSpace: "preWrap",
};

const Terms = () => {
  return (
    <div
      className='terms'
      style={{ background: `url(${BACKGROUND} ) center center /cover` }}>
      <div style={{ padding: "10px 20px" }}>
        <h1 dir='rtl' style={{ h1Style }}>
          <span style={h1SpanStyle}>תקנון משחק בריחה פיצה האט ו YOUDO</span>
        </h1>
        <Link to='/'>
          <div className={"continue"} style={{position:'absolute' , left:0,bottom:0}} >
            <img src={BUTTON_REG_NEW} className={"button-reg"} width={125} />
            <img
              src={BUTTON_PRESS_NEW}
              className={"button-press"}
              width={125}
            />
          </div>
        </Link>

        {/* <MenuReturn  top={"0"} left={"0"} /> */}
        {/* <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>תקנון פעילות :</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>1 .הגדרות</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          למונחים הבאים בתקנון תהא ההגדרה המפורטת לצידם להלן:
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          &quot;עורכת הפעילות&quot; &ndash; חברת &quot;טבסקו החזקות
          בע&quot;מ&quot; (להלן : &quot;פיצה האט&quot;)
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>&quot;משתתף&quot;</span>
        <span style={pStyleSpan}>
          &nbsp;&ndash; הפעילות פתוחה לכל אדם אשר הינו אזרח ישראלי או תושב ישראל
          השוהה
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          בישראל בעת עריכת הפעילות ואשר אינו תאגיד או כל גוף אחר לרבות בית עסק
          וכד&apos;, ועומד ביתר התנאים
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>המפורטים בתקנון זה;</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          בעצם השתתפותו מצהיר המשתתף כי הינו מעל גיל 18 .מובהר כי אם המשתתף
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          הינו קטין (מתחת לגיל 18 ),בעצם השתתפותו מצהיר הקטין כי אושר לו להשתתף
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          בפעילות, על ידי נציגו של אותו משתתף. &quot;נציג&quot; לצורך תקנון זה
          הינו נציג של
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          אותו הקטין כהגדרתו בחוק הכשרות המשפטית והאפוטרופסות, התשכ&quot;ב-1962
          ;
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        &nbsp;
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>&quot;הפעילות&quot;</span>
        <span style={pStyleSpan}>
          &nbsp;&ndash; משחק בריחה בנושא חלל ממותג פיצה האט, הכולל חידות אשר על
          מנת לפתור אותן נדרש לעשות שימוש בקופסת הפיצה ובאתר המשחק המופיע על גבי
          הקרטון.
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>&quot;אתר הפעילות&quot;</span>
        <span style={pStyleSpan}>
          &ndash; &nbsp;https://pizza.youdoadventures.com/
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        &nbsp;
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>&quot;הפרס&quot;&nbsp;</span>
        <span style={pStyleSpan}>
          &ndash; כל משתתף שמצליח לפתור את כל החידות יקבל קוד קופון לרכישת לחם
          שום או אצבעות שוקולד בכפוף לתנאי אתר פיצה האט וכן שובר הנחה לקניית
          קופסת בריחה בהתאמה אישית באתר youdoadventures.com
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        &nbsp;
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>2.תקופת הפעילות</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>א . תקופת הפעילות הינה למשך חודשיים</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ב . מובהר בזאת כי עורכת הפעילות, תהא רשאית להאריך ו/או לקצר את תקופת
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          הפעילות על פי שיקול דעתה הבלעדי, ובלבד שהודיע על סיום מוקדם בכל דרך
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          סבירה. עורכת הפעילות שומרת לעצמה את הזכות להפסיק את הפעילות לפני המועד
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          הנקוב, כל אימת שתתרחש תקלה או אירוע שאינם בשליטתה.
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        &nbsp;
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>3 .זכאות להשתתף בפעילות ואופן ההשתתפות</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          א. כל מי שברשותו מארז פיצה עם משחק החלל ויכול ליסרוק את הQR קוד&nbsp;
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ב. ההשתתפות בפעילות אסורה על עובדי עורכת הפעילות ו / או מנהלים ו/או
          שותפיהם
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>ו/או עובדיהם ו/או בני משפחותיהם.</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ג . עם הכניסה למשחק, המשתתף מצהיר, מאשר ומתחייב כי:
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          (i)ידוע למשתתף כי ההשתתפות בפעילות על כל שלביה תהא על אחריותו הבלעדית
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>בלבד.</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          (ii)ידוע למשתתף כי עורכת הפעילות תהא רשאית לשנות את כללי הפעילות, כפי
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>שנקבעו בתקנון זה על פי שיקול דעתה&nbsp;</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          (iii)ידוע למשתתף כי השתתפות ו ו/או אי השתתפות ובפעילות כפופה לזמינות
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ותקינות רשת האינטרנט ורשת המחשבים ומערכות אשר באמצעותן מתנהלת
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          הפעילות, המשתתף לא יהא רשאי להעלות כלפי עורכת הפעילות ו/או מי מטעמה
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          כל טענה ו/או דרישה בגין נזק שנגרם לו עקב טעות, תקלה, איחור או כשל
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>בה שתתפותו בפעילות וכיו&quot;ב.</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          (iv)מבלי לגרוע מהאמור לעיל, משתתף המעוניין להשתתף בפעילות מצהיר ומסכים
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          בזאת כי יתכן שתיפולנה טעויות בניהול הפעילות, וכי בכל מקרה של טעות
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          כאמור, לא תהווה השתתפות ו ו/או אי השתתפות ו בפעילות עילה לכל תביעה
          ו/או
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          טענה ו/או דרישה בגין נזק שנגרם לו עקב הטעות, איחור או כשל בהשתתפות
          ובפעילות וכיוצא בזה.&nbsp;
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          (v)במידה ש עורכת הפעילות ו/או מי מטעמה תזהה פעילות שאינה חוקית ו/או
          ניסיון
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          לפרוץ את המערכת ו/או ניסיון להזין נתונים שאינם נכונים ו/א ו להשתמש
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          בנתונים שאינם נכונים וכיוצ&quot;ב, עורכת הפעילות ו/או מי מטעמה רשאית
          לחסום
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          באופן מיידי את המשתתף והמשתתף לא יוכל להמשיך להשתתף בפעילות בכל
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>תקופת הפעילות.</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          (vi)למען הסר ספק, יובהר כי עורכת הפעילות ו/או מי מטעמה, שומרת לעצמה את
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          הזכות לפסול על הסף את השתתפותו של משתתף לפי אמות המידה של עורכת
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          הפעילות ו/או מי מטעמה. בעצם השתתפותו בפעילות, מצהיר המשתתף כי הוא
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          מוותר על כל טענה ו/או תביעה ו/או דרישה כלפי עורכת הפעילות ו/או מי
          מטעמה
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          בקשר עם האמור בסעיף זה ושיקול הדעת הבלעדי בעניין זה מוקנה לעורכת
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>הפעילות ו/א ו למי מטעמה בלבד.</span>
      </p>
      <p dir='rtl' style={pStyle}>
        &nbsp;
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>5 .מדיניות פרטיות</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          א . הפרטים שייאספו , ככל שייאספו, לגבי המשתתפים בפעילות , ישמשו אך ורק
          לצורך
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          פעילות זו. בסמוך לסיום הפעילות הנתונים אשר יאספו בנוגע למשתתפים ימחקו.
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ב . עורכת הפעילות ו/או מי מטעמה לא יעבירו לצדדים שלישיים את פרטיהם
          האישיים
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          של המשתתפים ואת המידע שנאסף על פעילות ם באתר הפעילות, אלא במקרים
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>שלהלן:</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          1 )ככל שהדבר דרוש לצורך אספקה תקינה של שירותים לפעילות;
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          2 )אם יתקבל בידי עורכת הפעילות ו/או מפעילת האתר צו שיפוטי המורה לה
          למסור
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          את פרטי המשתתפים או את המידע אודותיהם לצד שלישי;
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          3 )בכל מקרה שעורכת הפעילות ו/או מפעיל האתר תסבור, כי מסירת המידע נחוצה
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          כדי למנוע נזק חמור לגופו או לרכוש ו של משתתף.
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ג . עורכת הפעילות מיישמת מערכות ונהלים לאבטחת מידע. בעוד שמערכות
          ונהלים
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          אלה מצמצמים את הסיכונים לחדירה בלתי -מורשית לנתונים שיאספו במסגרת
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          הפעילות, אין בהם בטחון מוחלט. לכן, עורכת הפעילות לא מתחייבת שהשתתפות
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          בפעילות תהיה חסינה באופן מוחלט מפני גישה בלתי -מורשית למידע שייאסף
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>במסגרתה .&nbsp;</span>
      </p>
      <p dir='rtl' style={pStyle}>
        &nbsp;
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>6 .אופן הזכייה -&nbsp;</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          א. כל אדם שמסיים את המשחק ומגיע למסך הסיום, מקליד את פרטי כתובת המייל
          שלו. עורכת הפעילות תיידע את הזוכה בפעילות בדבר הזכייה באמצעות משלוח
          מייל (להלן: &quot; הודעת הזכייה &quot;)הכולל קופון חד ערכי לשימוש באתר
          פיצה וכן קופון חד ערכי לשימוש באתר youdo
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ב . בעצם השתתפותו בפעילות, מצהיר המשתתף כי הוא מוותר על כל טענה ו/או
          תביעה
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ו/או דרישה כלפי עורכת הפעילות ו/או מי מטעמה בקשר עם האמור בתקנון זה
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ושיקול הדעת הבלעדי בעניין זה מוקנה לעורכת הפעילות ו/או למי מטעמה בלבד.
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        &nbsp;
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>8 .הפרסים</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          א . הזכייה בפרס הינה בכפוף לעמידה בכל תנאי התקנון.
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ב. עורכת הפעילות תהא רשאית לפסול, עפ&quot;י שיקול דעתה, מועמד לזכייה
          אשר לא
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          אותר בתנאים האמורים לעיל, והמועמד לזכייה לא יהיה זכאי לקבל פיצוי
          כלשהו.
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ג . הזכייה בפרס במסגרת הפעילות הינה אישית ואינה ניתנת להעברה. הפרס לא
          ניתן
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          לשינוי, החלפה או המרה אלא בהסכמת עורכת הפעילות מראש ובכתב ועל פי שיקול
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>דעתה הבלעדי.</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ד . עורכת הפעילות שומרת לעצמה את הזכות לשנות את הפרס מכל סיבה שהיא
          ובכל
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          עת ו/או להתנות את הענקת הפרס בתנאים ובלבד שתימסר הודעה על כך
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>למשתתפים.</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ה. מבלי לגרוע מהוראות תקנון זה והוראות כל דין , עורכת הפעילות תהא
          רשאית שלא
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          למסור את הפרס לידי מי שהגיע אליו במעשה עבירה או במעשה שאינו כדין, או
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          שפעל בניגוד לאמור בתקנון זה, מכל בחינה שהיא.
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>9 .זכויות יוצרים</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>קניין רוחני - המשחק על פלטפורמת YOUDO</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          א. מובהר כי השם YOUDO והלוגו המצורף אליו ו/או כי סימנים מסחריים שונים
          המופיעים באתר הינם קניינה הבלעדי של החברה ו/או לפי העניין של החברות
          מבבעלותן הסימנים המסחריים המצוינים לעיל ו/או מי מטעמן וכי המשתמש מסכים
          כי כל זכויות הקניין באתר ובמידע שבו לרבות זכויות הקניין הרוחני, זכויות
          יוצרים, סודות מסחריים, זכויות בתוכנה, בפטנטים, בהפצה ובהמצאות הינם
          ויהיו תמיד בבעלותה הבלעדית והמלאה של YOUDO ו/או של החברות מבבעלותן
          הסימנים המסחריים המצוינים לעיל ו/או של היצרנים האחרים ואין למשתמש כל
          זכות מכל סוג שהוא באתר ובמידע שבו.
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ב. המשתמש מתחייב כי הוא לא יעתיק, יפיץ, יציג בפומבי, יתרגם ו/או ימסור
          לצד שלישי כלשהו כל חלק של האתר ו/או כל מוצר המוצג בו ו/או כל פלט של
          האתר ו/או כל מידע שבאתר מבלי לקבל את הסכמתה המפורשת לכך של YOUDO בכתב
          ומראש.
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ג. המשתמש מתחייב שלא לבצע שינויים כלשהם באתר ו/או במידע, לרבות שינויי
          עריכה ונוסח והוא מתחייב שלא לפרסם את המידע (בין אם שונה ובין אם לא)
          ו/או חלק ממנו באינטרנט ו/או במדיה כלשהי אחרת, קיימת ו/או עתידית.
          המשתמש מתחייב עוד כי הוא לא יבצע עסקאות ו/או התקשרויות עם צדדים
          שלישיים, בתמורה או שלא בתמורה, במידע או חלק ממנו.
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ד. המשתמש מצהיר ומתחייב כי הוא לא יעתיק את האתר ו/או כל חלק ממנו ולא
          ינקוט בשום פעולה ולא ירשה לבצע כל פעולה שעלולה (במישרין ו/או בעקיפין)
          לחבל בתפעולו התקין והשוטף של האתר ו/או עלולה לגרום לשינוי המידע המוצג
          בו ו/או עלולה לגרום לנזקים ל YOUDO ו/או למשתמשים אחרים ו/או צדדים
          שלישיים כלשהם
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>10 .פרסום הפעילות ותוצאותיה</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          א . עורכת הפעילות שומרת לעצמה את הזכות לפרסם את הפעילות, הפרסים
          וכיוצ&quot;ב,
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          בכל שלב שהוא (בין במהלך הפעילות ובין לאחריה), לפי שיקול דעתה הבלעדי,
          באתר
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ו/או בכל אמצעי תקשורת שהוא ובכל דרך שתמצא לנכון.
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ב . בעצם השתתפותם בפעילות המשתתפים מאשרים, כי לא תהיה להם כל טענה ו/או
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          דרישה ו/או תביעה כנגד עורכת הפעילות ו/או מי מטעמה בקשר עם השימוש,
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          בהתאם לתקנון זה, בפרטים שהזינו במסגרת הפעילות.
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ג . בעצם השתתפותם בפעילות, מצהירים המשתתפים באופן בלתי חוזר, כי הם
          בחרו
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          להשתתף בפעילות לשם הנאה ובידור, כשהם מודעים לכך שהתמורה היחידה לה
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          יזכו, בכפוף לנאמר בתקנון זה, הינו הפרס כהגדרתו לעיל.
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>12 .שונות</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          א . &nbsp;בהשתתפותו בפעילות מסכים, מאשר ומצהיר המשתתף בפעילות כי קרא
          את התקנון
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ו כי הוא מקבל עליו את הוראותיו וגם אם לא קרא את התקנון מסכים המשתתף
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          בפעילות כי תקנון זה יחול עליו ויחייבו לכל דבר ועניין.
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ב . מובהר כי ההשתתפות בפעילות, הינה על אחריותם הבלעדית של המשתתפים
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          בפעילות. עורכת הפעילות ו/או מי מטעמה לא יהיו אחראים ולא יהיו חייבים
          בשיפוי
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          בגין כל אבדן, נזק ישיר ו/או עקיף, הפסד, לרבות הוצאות ישירות ו/או
          עקיפות,
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          שיגרמו למי מהמשתתפים ו/או לצדדים שלישיים כלשהם כתוצאה מהשתתפות
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          בפעילות וכי בכל מקרה לא תהווה השתתפותם ו/או אי השתתפותם בפעילות עילה
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>לכל תביעה ו/או טענה ו/או דרישה .</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ג . המשתתפים בפעילות, בעצם השתתפותם, יחשבו כמסכימים לכל הוראות תקנון
          זה .
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          בהשתתפותם בפעילות פוטרים המשתתפים בפעילות את עורכת הפעילות ו/או מי
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          מטעמה מכל טענה ו/או דרישה ו/או תביעה בקשר ל פעילות ו/או לכל שלב משלביה
          פעילות ו/או לתקנון ו/או לתקלות כלשהן בקשר לפעילות והם מוותרים על כל
          טענה
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>הקשורה באלה .</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ד. עורכת הפעילות ו/או מי מטעמה אינה אחראית ולא תהיה אחראית באופן כלשהו
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          לפעילותן התקינה של רשת האינטרנט, החשמל, ו/או כל אמצעי תשתית או תקשורת
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          אחר, על כל רכיביהן ולא תשא באחריות להפסקות, הפרעות, ניתוקים, נזקים,
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          הוצאות, מניעת רווח וכיוצ&quot;ב, אשר יגרמו למשתתף בקשר עם השתתפות
          בפעילות,
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>במישרין ו/או בעקיפין.</span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ה . עורכת הפעילות ו/או מי מטעמה שומרת לעצמה את הזכות לבטל את זכייתו
          ו/או את
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          השתתפותו של משתתף בתחרות במידה ויימצא כי אותו משתתף נהג במרמה ו/או
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          בעבירה ו/או בחוסר תום לב ו/או שלא בדרך שנקבעה בתקנון זה ו/או שלא על פי
          דין.
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          במקרה כאמור בסעיף זה תהא עורכת התחרות רשאית שלא למסור את הפרס ו/או
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          לדרוש את הפרס חזרה , בעין או בשווי, מידי מי שזכה בו בנסיבות כאמור
          בסעיף זה
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          והפרס יעבור למשתתף המדורג הבא אחריו לפי בחירתה הבלעדית של עורכת
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          התחרות ו/או מי מטעמה. לעניין זה, &quot;חוסר תום לב&quot; ייחשב ככל
          מעשה או מחדל
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          שאיננו עומד בקנה אחד עם אופן השתתפו ת סביר ומקובל ו/או עם מהות המבצע
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          ו/או מהווה עקיפה כלשהי של הכללים המפורטים בתקנון המבצע.
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        &nbsp;
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          י . תקנון זה יעמוד לעיון כל דורש , ללא תשלום , באתר :
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          יא . www.pizzahut.co.il ובמשרדי עורכת הפעילות בכתובת: רחוב ויסבורג 19
          ,תל אביב .
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>
          יב . בכל מקום בתקנון זה שבו האמור נכתב בלשון זכר, נעשה הדבר מטעמי
          נוחות בלבד
        </span>
      </p>
      <p dir='rtl' style={pStyle}>
        <span style={pStyleSpan}>ומתייחס לזכר ונקבה במידה שווה.</span>
      </p>
    </div dir='rtl' style={pStyle}> */}

        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}> הגדרות</span>
        </p>

        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            למונחים הבאים בתקנון תהא ההגדרה המפורטת לצידם להלן:
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            "עורכת הפעילות" חברת "טבסקו החזקות בע"מ" (להלן: "פיצה האט").
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            "משתתף" הפעילות פתוחה לכל אדם אשר הינו אזרח ישראלי או תושב ישראל
            השוהה בישראל בעת עריכת הפעילות וחלוקת הפרסים ואשר אינו תאגיד או כל
            גוף אחר לרבות בית עסק וכד' ועומד ביתר התנאים המפורטים בתקנון זה;
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            בעצם השתתפותו מצהיר המשתתף כי הינו מעל גיל 18. מובהר כי אם המשתתף
            הינו קטין (מתחת לגיל 18), בעצם השתתפותו מצהיר הקטין כי אושר לו
            להשתתף בפעילות, על ידי נציגו של אותו משתתף. "נציג" לצורך תקנון זה
            הינו נציג של אותו הקטין כהגדרתו בחוק הכשרות המשפטית והאפוטרופסות,
            התשכ"ב-1962;
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            "הפעילות" השתתפות במשחק בריחה בנושא חלל באתר הפעילות ברשת האינטרנט,
            הכולל חידות אשר על מנת לפתור אותן יש לסרוק את ה QR קוד המופיע על גבי
            קרטון הפיצה של פיצה האט. המשתתפים אשר יפתרו את כל החידות במשחק יזכו
            בפרס.{" "}
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            "אתר הפעילות" https://pizza.youdoadventures.com/{" "}
          </span>
        </p>

        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            "הפרס" קוד קופון של פיצה האט לקבלת לחם שום אישי או מקלות שוקולד אישי
            בהזמנה מעל 69.90 ₪ או בקניית פיצה משפחתית (L)
          </span>
        </p>

        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>פרשנות</span>
        </p>

        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            בכל מקרה של סתירה ו/או אי התאמה כלשהי בין הוראות תקנון זה לפרסומים
            אחרים כלשהם בדבר הפעילות, תגברנה הוראות תקנון זה לכל דבר ועניי
          </span>
        </p>

        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            החלוקה לסעיפים וכותרות הסעיפים הינה לשם נוחות בלבד ולא תשמש לצרכי
            פרשנות .
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>תקופת הפעילות</span>
        </p>

        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            תקופת הפעילות הינה למשך חודשיים, מיום שלישי 10.08.2021 החל מהשעה
            08:00 ועד ליום 31.09.2021 בשעה 23:59 (להלן: "תקופת הפעילות").
          </span>
        </p>

        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            מובהר בזאת כי עורכת הפעילות, תהא רשאית להאריך ו/או לקצר את תקופת
            הפעילות על פי שיקול דעתה הבלעדי, ובלבד שתודיע על סיומה המוקדם בכל
            דרך סבירה. עורכת הפעילות שומרת לעצמה את הזכות להפסיק את הפעילות לפני
            המועד הנקוב, כל אימת שתתרחש תקלה או אירוע שאינם בשליטתה.
          </span>
        </p>

        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>זכאות להשתתף בפעילות ואופן ההשתתפות</span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            כל מי שברשותו מארז פיצה עם משחק החלל וביכולתו לסרוק את הQR שעל גבי
            האריזה.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            עצם ההשתתפות בפעילות מהווה הסכמה לתוכנו של התקנון באופן מלא ובלתי
            מסויג.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            ההשתתפות בפעילות אסורה על עובדי עורכת הפעילות ו/או מנהליהם ו/או
            שותפיהם ו/או עובדיהם ו/או בני משפחותיהם.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            עם רישום המשתתף לפעילות, המשתתף מצהיר, מאשר ומתחייב כי:
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            ידוע למשתתף כי ההשתתפות בפעילות על כל שלביה תהא על אחריותו הבלעדית
            בלבד.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            ידוע למשתתף כי עורכת הפעילות תהא רשאית לשנות את כללי הפעילות, כפי
            שנקבעו בתקנון זה על פי שיקול דעתה הבלעדי ועורכת הפעילות תפרסם הודעה
            על כך באתר הפעילות ו/או בכל אמצעי אחר שתבחר בו עורכת הפעילות.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            ידוע למשתתף כי השתתפותו ו/או אי השתתפותו בפעילות כפופה לזמינות
            ותקינות רשת האינטרנט ורשת המחשבים והמערכות אשר באמצעותן מתנהלת
            הפעילות, ובפרט אתר הפעילות. המשתתף לא יהא רשאי להעלות כלפי עורכת
            הפעילות ו/או מי מטעמה כל טענה ו/או דרישה בגין נזק שנגרם לו עקב טעות,
            תקלה, איחור או כשל בהשתתפותו בפעילות וכיו"ב.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            מבלי לגרוע מהאמור לעיל, משתתף המעוניין להשתתף בפעילות מצהיר ומסכים
            בזאת כי יתכן שתיפולנה טעויות בניהול הפעילות, וכי בכל מקרה של טעות
            כאמור, לא תהווה השתתפותו ו/או אי השתתפותו בפעילות עילה לכל תביעה
            ו/או טענה ו/או דרישה בגין נזק שנגרם לו עקב הטעות, איחור או כשל
            בהשתתפותו בפעילות וכיוצא בזה, לרבות אי קליטת התוכן אשר הועלה ע"י
            המשתתף במערכות עורכת הפעילות.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            במידה שעורכת הפעילות ו/או מי מטעמה תזהה פעילות שאינה חוקית ו/או
            ניסיון לפרוץ את המערכת ו/או ניסיון להזין נתונים שאינם נכונים ו/או
            להשתמש בנתונים שאינם נכונים וכיוצ"ב, עורכת הפעילות ו/או מי מטעמה
            רשאית לחסום באופן מידי את המשתתף והמשתתף לא יוכל להמשיך להשתתף
            בפעילות בכל תקופת הפעילות.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            למען הסר ספק, יובהר כי עורכת הפעילות ו/או מי מטעמה, שומרת לעצמה את
            הזכות לפסול על הסף את השתתפותו של משתתף לפי אמות המידה של עורכת
            הפעילות ו/או מי מטעמה. בעצם השתתפותו בפעילות, מצהיר המשתתף כי הוא
            מוותר על כל טענה ו/או תביעה ו/או דרישה כלפי עורכת הפעילות ו/או מי
            מטעמה בקשר עם האמור בסעיף זה ושיקול הדעת הבלעדי בעניין זה מוקנה
            לעורכת הפעילות ו/או למי מטעמה בלבד.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            אין הגבלה על מספר המשחקים שמשתתף יכול לשחק במסגרת הפעילות.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            עורכת הפעילות שומרת לעצמה את הזכות לשנות כל הוראה מהוראות תקנון זה
            בכל עת ובלא צורך בהודעה על כך, מעבר לפרסום הנוסח העדכני של התקנון
            באתר עורכת הפעילות. האחריות להכרת התקנון כפי שיהיה מעת לעת חלה על
            המשתתף.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            בכל מקרה בו יפר משתתף בפעילות את הוראות התקנון ו/או התחייבות
            מהתחייבויותיו כלפי עורכת הפעילות ו, תהיה עורכת הפעילות רשאית לבטל את
            השתתפותו בפעילות ו/או זכייתו. משתתף בפעילות מוותר מראש על כל דרישה
            ו/או טענה ו/או תביעה כנגד עורכת הפעילות בקשר עם האמור לעיל.
          </span>
        </p>

        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>מדיניות פרטיות</span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            עורכת הפעילות ו/או מי מטעמה לא יעבירו לצדדים שלישיים את פרטיהם
            האישיים של המשתתפים ואת המידע שנאסף על פעילותם באתר הפעילות, אלא
            במקרים שלהלן:
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            ככל שהדבר דרוש לצורך אספקה תקינה של שירותים לפעילות;
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            אם יתקבל בידי עורכת הפעילות ו/או מפעילת האתר צו שיפוטי המורה לה
            למסור את פרטי המשתתפים או את המידע אודותיהם לצד שלישי;
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            בכל מקרה שעורכת הפעילות ו/או מפעיל האתר תסבור, כי מסירת המידע נחוצה
            כדי למנוע נזק חמור לגופו או לרכושו של משתתף.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            עורכת הפעילות מיישמת מערכות ונהלים לאבטחת מידע. בעוד שמערכות ונהלים
            אלה מצמצמים את הסיכונים לחדירה בלתי-מורשית לנתונים שיאספו במסגרת
            הפעילות, אין בהם בטחון מוחלט. לכן, עורכת הפעילות לא מתחייבת שהשתתפות
            בפעילות תהיה חסינה באופן מוחלט מפני גישה בלתי-מורשית למידע שייאסף
            במסגרתה.
          </span>
        </p>

        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>אופן הזכייה</span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            כל אדם שמסיים את המשחק ומגיע למסך הסיום, יידרש להקליד את כתובת
            הדוא"ל שלו. עורכת הפעילות תיידע את הזוכה בפעילות בדבר הזכייה באמצעות
            משלוח מייל הכולל קופון חד ערכי ללחם שום או מקלות שוקולד אישי בקניית
            פיצה משפחתית (L) או בהזמנה מעל 69.90 ₪ . את הקופון ניתן לממש באתר,
            באפליקציה ובסניפים של עורכת הפעילות וכן קופון חד ערכי לשימוש באתר
            youdo (להלן: "הודעת הזכייה").
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            עורכת הפעילות שומרת לעצמה את הזכות לספק לזוכה מוצר או שירות שווה ערך
            או דומה לפרס ותוכנו על פי שיקול דעתה ומכל סיבה שהיא.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            עורכת הפעילות תהיה רשאית לשנות את הליך בחירת הזוכה על פי שיקול דעתה
            הבלעדי. המשתתפים מוותרים בזאת על כל טענה ו/או תביעה ו/או דרישה
            מעורכת הפעילות בגין כל שינוי שתבצע, מכל סיבה שהיא.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            בעצם השתתפותו בפעילות, מצהיר המשתתף כי הוא מוותר על כל טענה ו/או
            תביעה ו/או דרישה כלפי עורכת הפעילות ו/או מי מטעמה בקשר עם האמור
            בתקנון זה ושיקול הדעת הבלעדי בעניין זה מוקנה לעורכת הפעילות ו/או למי
            מטעמה בלבד.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            בכל מקרה בו זוכה מסר פרטים מוטעים או שאינם מדויקים הוא לא יוכל לקבל
            את הפרס ולא יהיו לו כל טענות כנגד עורכת הפעילות בעניין.
          </span>
        </p>

        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>הפרסים</span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            הזכייה בפרס הינה בכפוף לעמידה בכל תנאי התקנון.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            הזוכה בפרס שוויתר על הפרס, או שלא עמד בתנאי תקנון זה יהיה מנוע
            מלהעלות כל טענה ו/או תביעה ו/או דרישה כלפי עורכת הפעילות ו/או מי
            מטעמה בגין אי חלוקת הפרס.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            עורכת הפעילות תהא רשאית לפסול, עפ"י שיקול דעתה, מועמד לזכייה אשר לא
            אותר בתנאים האמורים לעיל, והמועמד לזכייה לא יהיה זכאי לקבל פיצוי
            כלשהו.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            עורכת הפעילות שומרת לעצמה את הזכות לשנות את הפרס מכל סיבה שהיא ובכל
            עת ו/או להתנות את הענקת הפרס בתנאים ובלבד שתימסר הודעה על כך
            למשתתפים.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            מבלי לגרוע מהוראות תקנון זה והוראות כל דין, עורכת הפעילות תהא רשאית
            שלא למסור את הפרס לידי מי שהגיע אליו במעשה עבירה או במעשה שאינו
            כדין, או שפעל בניגוד לאמור בתקנון זה, מכל בחינה שהיא.
          </span>
        </p>

        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>זכויות יוצרים</span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            ידוע למשתתף כי המשחק מתבצע בפלטפורמת YOUDO וכי הקניין הרוחני שייך לה
            בלבד.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            למען הסר ספק, השם YOUDO והלוגו המצורף אליו ו/או סימנים מסחריים שונים
            המופיעים באתר הינם קניינה הבלעדי של YOUDO ו/או מי מטעמן וכי המשתתף
            מסכים כי כל זכויות הקניין באתר ובמידע שבו לרבות זכויות הקניין
            הרוחני, זכויות יוצרים, סודות מסחריים, זכויות בתוכנה, בפטנטים, בהפצה
            ובהמצאות הינם ויהיו תמיד בבעלותה הבלעדית והמלאה של YOUDO ו/או ו/או
            מי מטעמן ו/או של יצרנים אחרים מטעמה ואין למשתתף כל זכות מכל סוג שהוא
            באתר ובמידע שבו.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            המשתתף מתחייב כי הוא לא יעתיק, יפיץ, יציג בפומבי, יתרגם ו/או ימסור
            לצד שלישי כלשהו כל חלק של המשחק ו/או כל מוצר המוצג בו ו/או כל פלט
            שלו ו/או כל מידע שבו מבלי לקבל את הסכמתה המפורשת לכך של YOUDO בכתב
            ומראש.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            המשתתף מתחייב שלא לבצע שינויים כלשהם באתר YOUDO ו/או במידע, לרבות
            שינויי עריכה ונוסח והוא מתחייב שלא לפרסם את המידע (בין אם שונה ובין
            אם לא) ו/או חלק ממנו באינטרנט ו/או במדיה כלשהי אחרת, קיימת ו/או
            עתידית. המשתתף מתחייב עוד כי הוא לא יבצע עסקאות ו/או התקשרויות עם
            צדדים שלישיים, בתמורה או שלא בתמורה, במידע או חלק ממנו.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            המשתתף מצהיר ומתחייב כי הוא לא יעתיק את האתר ו/או כל חלק ממנו ולא
            ינקוט בשום פעולה ולא ירשה לבצע כל פעולה שעלולה (במישרין ו/או
            בעקיפין) לחבל בתפעולו התקין והשוטף של אתר YOUDO ו/או עלולה לגרום
            לשינוי המידע המוצג בו ו/או עלולה לגרום לנזקים ל YOUDO ו/או למשתמשים
            אחרים ו/או צדדים שלישיים כלשהם.
          </span>
        </p>

        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>פרסום הפעילות ותוצאותיה</span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            עורכת הפעילות שומרת לעצמה את הזכות לפרסם את הפעילות, הפרסים וכיוצ"ב,
            בכל שלב שהוא (בין במהלך הפעילות ובין לאחריה), לפי שיקול דעתה הבלעדי,
            באתר ו/או בכל אמצעי תקשורת שהוא ובכל דרך שתמצא לנכון.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            בעצם השתתפותם בפעילות המשתתפים מאשרים, כי לא תהיה להם כל טענה ו/או
            דרישה ו/או תביעה כנגד עורכת הפעילות ו/או מי מטעמה בקשר עם השימוש,
            בהתאם לתקנון זה, בפרטים שהזינו במסגרת הפעילות, לרבות פרסום שמותיהם.
            בעצם השתתפותם בפעילות מאשרים המשתתפים כי תקנון זה מקובל ומוסכם
            עליהם, בלא הסתייגויות.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            בעצם השתתפותם בפעילות, מצהירים המשתתפים באופן בלתי חוזר, כי הם בחרו
            להשתתף בפעילות לשם הנאה ובידור, כשהם מודעים לכך שהתמורה היחידה לה
            יזכו, בכפוף לנאמר בתקנון זה, הינו הפרס כהגדרתו לעיל.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>שונות</span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            בהשתתפותו בפעילות מסכים, מאשר ומצהיר המשתתף בפעילות כי קרא את התקנון
            וכי הוא מקבל עליו את הוראותיו וגם אם לא קרא את התקנון מסכים המשתתף
            בפעילות כי תקנון זה יחול עליו ויחייבו לכל דבר ועניין.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            מובהר כי ההשתתפות בפעילות, הינה על אחריותם הבלעדית של המשתתפים
            בפעילות. עורכת הפעילות ו/או מי מטעמה לא יהיו אחראים ולא יהיו חייבים
            בשיפוי בגין כל אבדן, נזק ישיר ו/או עקיף, הפסד, לרבות הוצאות ישירות
            ו/או עקיפות, שיגרמו למי מהמשתתפים ו/או לצדדים שלישיים כלשהם כתוצאה
            מהשתתפות בפעילות וכי בכל מקרה לא תהווה השתתפותם ו/או אי השתתפותם
            בפעילות עילה לכל תביעה ו/או טענה ו/או דרישה.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            המשתתפים בפעילות, בעצם השתתפותם, יחשבו כמסכימים לכל הוראות תקנון זה.
            בהשתתפותם בפעילות פוטרים המשתתפים בפעילות את עורכת הפעילות ו/או מי
            מטעמה מכל טענה ו/או דרישה ו/או תביעה בקשר לפעילות ו/או לכל שלב משלבי
            הפעילות ו/או לתקנון ו/או לתקלות כלשהן בקשר לפעילות והם מוותרים על כל
            טענה הקשורה באלה.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            עורכת הפעילות רשאית להוריד לאלתר ולפסול מהפעילות באופן מיידי כל
            משתתף אשר ייטען לגביו כי במסגרת הפעילות הוא פגע באדם אחר וזאת מבלי
            לדרוש מהטוען כאמור להוכיח את טענותיו. למשתתף לא תהיה כל טענה ו/או
            דרישה בקשר לאמור.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            עורכת הפעילות תהיה הפוסקת הסופית בכל עניין הנוגע לפעילות ובנוגע לכל
            שאלה שתתעורר במהלכו ו/או בקשר עמו ובנוגע לכל תקלה ו/או בעיה ו/או כל
            מצב בלתי צפוי שייווצר במהלך הפעילות, בין אם צוינו במפורש בתקנון זה
            ובין אם לאו ולמשתתף לא תהיה כל טענה בשל כך.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            עורכת הפעילות ו/או מי מטעמה אינה אחראית ולא תהיה אחראית באופן כלשהו
            לפעילותן התקינה של רשת האינטרנט, החשמל, ו/או כל אמצעי תשתית או
            תקשורת אחר, על כל רכיביהן ולא תישא באחריות להפסקות, הפרעות, ניתוקים,
            נזקים, הוצאות, מניעת רווח וכיוצ"ב, אשר יגרמו למשתתף בקשר עם השתתפות
            בפעילות, במישרין ו/או בעקיפין.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            עורכת הפעילות ו/או מי מטעמה שומרת לעצמה את הזכות לבטל את זכייתו ו/או
            את השתתפותו של משתתף בתחרות במידה ויימצא כי אותו משתתף נהג במרמה
            ו/או בעבירה ו/או בחוסר תום לב ו/או שלא בדרך שנקבעה בתקנון זה ו/או
            שלא על פי דין. במקרה כאמור בסעיף זה תהא עורכת התחרות רשאית שלא למסור
            את הפרס ו/או לדרוש את הפרס חזרה, בעין או בשווי, מידי מי שזכה בו
            בנסיבות כאמור בסעיף זה. לעניין זה, "חוסר תום לב" ייחשב ככל מעשה או
            מחדל שאיננו עומד בקנה אחד עם אופן השתתפות סביר ומקובל ו/או עם מהות
            המבצע ו/או מהווה עקיפה כלשהי של הכללים המפורטים בתקנון המבצע.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            תקנון זה מסדיר את תנאי ההשתתפות בפעילות והנו חוזה לכל דבר ועניין
            ועצם ההשתתפות בפעילות על ידי השתתפות בפעילות, מהווה הסכמה מלאה לכל
            האמור בו.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            תקנון זה יעמוד לעיון כל דורש, ללא תשלום, באתר:
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            www.pizzahut.co.il ובמשרדי עורכת הפעילות בכתובת: רחוב ויסבורג 19, תל
            אביב.
          </span>
        </p>
        <p dir='rtl' style={pStyle}>
          <span style={pStyleSpan}>
            בכל מקום בתקנון זה שבו האמור נכתב בלשון זכר, נעשה הדבר מטעמי נוחות
            בלבד ומתייחס לזכר ונקבה במידה
          </span>
        </p>
      </div>
    </div>
  );
};

export default Terms;
