import React from "react";
import { ClueTrigger } from "../ScreenClue/clue-trigger";
import MenuReturn from "../ScreenMenuReturn/screen-menu-return";
import PropTypes from "prop-types";
import className from "classnames";
import colorsVariables from "../../assets/css/variables/space-colors.scss";
import "./screen-space-navbar.scss";

const SpaceNavbar = ({
  text,
  isSuccess,
  id,
  location,
  showMenuReturn,
  showClues,
  isError,
  color,
}) => {
  const getCluesLocation = () => {
    switch (location) {
      case "bottom":
        return { right: "5vw", bottom: "8vh" };
      case "left":
        return { left: "2vw", top: "8vh" };
    }
  };

  const getMenuLocation = () => {
    switch (location) {
      case "bottom":
        return { left: "2vw", bottom: "8vh" };
      case "left":
        return { left: "2vw", bottom: "8vh" };
    }
  };

  const getColor = () => {
    switch (color) {
      case "grey":
        return colorsVariables.spaceGreyShadow;
      case "blue":
        return colorsVariables.spaceDarkBlueShadow;
      default:
        return colorsVariables.spaceDarkBlueShadow;
    }
  };

  const redirectToSpaceship = () => {
    const level = localStorage.getItem("space-level");
    if (level < 6) {
      window.location.href = "/spaceship";
    } else if (level==8) {
      window.location.href = `/riddle/9`;
    } else {
      window.location.href = `/riddle/${level}`;
    }
  };

  return (
    <div
      className={`space-navbar ${location}`}
      style={{ backgroundColor: getColor() }}>
      {showClues && <ClueTrigger {...getCluesLocation()} id={id} />}
      <div
        className={className(`status ${location}`, {
          success: isSuccess,
          error: isError,
        })}
        onClick={() => isSuccess && redirectToSpaceship()}>
        {text}
      </div>
      {showMenuReturn && (
        <MenuReturn {...getMenuLocation()} isSuccess={isSuccess} />
      )}
    </div>
  );
};
SpaceNavbar.defaultProps = {
  location: "bottom",
  isError: false,
  isSuccess: false,
  color: "blue",
};

SpaceNavbar.propTypes = {
  location: PropTypes.oneOf(["bottom", "top", "left", "right"]),
  text: PropTypes.string,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showClues: PropTypes.bool,
  showMenuReturn: PropTypes.bool,
  color: PropTypes.oneOf(["blue", "grey"]),
};

export default SpaceNavbar;
