import React, {useState} from "react"
import "./space-switch.scss"
import classNames from "classnames"

const SpaceSwitch = ({checked, onClick,clicked}) => {
    console.log(clicked)
 
    return (
        <div className={checked ? "switch-wrapper" : "switch-wrapper-green"}>
            <div className={classNames("switch", {checked})}>
                <div className={checked ? "label" : 'label-clicked' }  onClick={onClick}/>
            </div>
        </div>
    )
};

export default SpaceSwitch