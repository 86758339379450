import React, {useState, useReducer, useContext} from 'react';
import {Element, HBox} from 'react-stylesheet';
import {Link} from "react-router-dom";
import {Button, Circle, Heading, Paragraph, Image, BackgroundImage} from '../../01_atoms';
import {colors, gutters, font_size} from '../../00_base/variables';
import {Row, Col} from 'react-grid-system';
import {Wrapper, Sentence, IndicatorWrapper, OptionSquare} from './truth-or-lie-styles';


const TruthOrLieContext = React.createContext();

const answersReducer = (state, action) => {

    const index = action.index;
    state[index].u = action.value;
    return state;
};
const ToolTruthOrLie = (
    {
        items,
        adventureDataId,
        screenBackground,
        borderColor,
        borderWidth,
        questionBackground,
        questionTextColor,
        truthButtonBackground,
        truthButtonTextColor,
        lieButtonBackground,
        lieButtonTextColor,
        continueButtonBackground,
        continueButtonTextColor,
        backButtonBackground,
        backButtonTextColor,
        bottomLogo,
        logoWidth
    }) => {

    const [activeSentence, setActiveSentence] = useState(0);
    const [showIncorrectAnswers, setShowIncorrectAnswers] = useState(false);
    const defaultAnswers = items.map(item => {
        return {a: item.sentence_type === "אמת" ? 0 : 1, u: -1}
    });
    const [answers, dispatchAnswers] = useReducer(answersReducer, defaultAnswers);

    return (
        <TruthOrLieContext.Provider value={{
            adventureDataId,
            answers,
            dispatchAnswers,
            items,
            activeSentence,
            setActiveSentence,
            showIncorrectAnswers,
            setShowIncorrectAnswers,
            screenBackground,
            borderColor,
            borderWidth,
            questionBackground,
            questionTextColor,
            truthButtonBackground,
            truthButtonTextColor,
            lieButtonBackground,
            lieButtonTextColor,
            continueButtonBackground,
            continueButtonTextColor,
            backButtonBackground,
            backButtonTextColor,
            bottomLogo,
            logoWidth
        }}>
            {activeSentence >= items.length ?
                <Wrapper screenBackground={screenBackground}
                         borderColor={borderColor}
                         borderWidth={borderWidth}>
                    <TruthOrLieFinalScreen adventureDataId={adventureDataId}/>
                </Wrapper>
                :
                <Wrapper screenBackground={screenBackground}
                         borderColor={borderColor}
                         borderWidth={borderWidth}
                         style={{
                             flex: 1,
                             display: "flex",
                             flexDirection: "column",
                             paddingBottom: "2rem"
                         }}>
                    <TruthOrLieIndicators/>

                    {items.map((item, index) => {
                        const isActive = index === activeSentence;

                        return (
                            <TruthOrLieSentence key={item.id}
                                                isActive={isActive}
                                                prevSentence={() => setActiveSentence(activeSentence - 1)}
                                                nextSentence={() => setActiveSentence(activeSentence + 1)}
                                                {...item}
                                                index={index}
                                                iconTruth={item.icon_truth}
                                                iconLie={item.icon_lie}/>
                        )
                    })}
                    <Element textAlign="center" padding={`${gutters.md} 0`}>
                        {bottomLogo && <Image src={bottomLogo} width={logoWidth}/>}
                    </Element>
                </Wrapper>
            }
        </TruthOrLieContext.Provider>
    )
};

const TruthOrLieIndicators = ({size, showAnswers}) => {
    const {items, answers, activeSentence, setActiveSentence, showIncorrectAnswers} = useContext(TruthOrLieContext);
    return <HBox justifyContent={'center'} flexWrap={showAnswers ? "wrap" : "nowrap"}>
        {answers.map((answer, index) => {
            const isActive = activeSentence === index;
            const value = answer.u >= 0 ? answer.u + 1 : '';
            const background = items[index].color;
            const borderColor = colors[isActive ? 'black' : 'white'];
            const hue = colors.lightOrDark(background);
            const color = hue === 'light' ? colors.black : colors.white;
            const isCorrect = answer.u === answer.a;
            const circleStyle = {
                cursor: 'pointer',
                borderRadius: "50%",
                marginBottom: gutters.sm,
                marginTop: gutters.sm,
                border: (showAnswers || showIncorrectAnswers) ? isCorrect ? "2px solid green" : "2px solid red" : `2px solid ${borderColor}`
            };

            return <IndicatorWrapper isActive={isActive} key={index}>
                <Circle value={value}
                        size={size || 25}
                        background={background}
                        color={color}
                        fontSize={font_size.sm}
                        fontWeight={'bold'}
                        onClick={() => setActiveSentence(index)}
                        style={circleStyle}>
                    {answer.u >= 0 &&
                    <BackgroundImage
                        size="contain"
                        width={"100%"}
                        height={"100%"}
                        url={items[index][answer.u === 0 ? "icon_truth" : "icon_lie"]}/>
                    }
                </Circle>
            </IndicatorWrapper>
        })}
    </HBox>
};

const TruthOrLieSentence = ({id, index, iconLie, iconTruth, sentence_type, sentence, color, isActive, nextSentence, prevSentence}) => {
    const {
        questionBackground,
        questionTextColor,
        continueButtonBackground,
        continueButtonTextColor,
        backButtonBackground,
        backButtonTextColor
    } = useContext(TruthOrLieContext);


    const options = [
        {label: 'אמת', icon: iconTruth},
        {label: 'שקר', icon: iconLie}
    ];
    return (
        <Sentence isActive={isActive}>
            <Element background={questionBackground} borderRadius={"20px"} margin={"0 10px"} padding={"10px 30px"}>
                <Heading tag={'h1'}
                         color={questionTextColor}
                         fontSize={font_size.lg}
                         textAlign={'center'}
                         value={`בחנו את עצמכם`}/>

                <Paragraph textAlign={'center'}
                           fontWeight={'bold'}
                           color={questionTextColor}
                           fontSize={font_size.md}
                           value={sentence}/>
            </Element>

            <Options sentenceId={id}
                     index={index}
                     color={"#f2f2f2"}
                     sentence_type={sentence_type}
                     options={options}/>

            <HBox justifyContent={'center'} padding={`0 ${gutters.md}`} marginTop={gutters.xs}>
                {id !== 0 &&
                <Button block
                        onClick={id > 0 && prevSentence}
                        value={'חזור'}
                        pointer={'pointer'}
                        style={{
                            margin: '0 0 0 5px',
                            backgroundColor: backButtonBackground,
                            color: backButtonTextColor,
                            borderRadius: "20px", fontSize: font_size.lg
                        }}/>
                }
                <Button block
                        value={'המשך'}
                        onClick={nextSentence}
                        pointer={'pointer'}
                        style={{
                            ...id === 0 ? {margin: '0'} : {margin: '0 5px 0 0'},
                            backgroundColor: continueButtonBackground,
                            color: continueButtonTextColor,
                            borderRadius: "20px", fontSize: font_size.lg
                        }}/>
            </HBox>
        </Sentence>
    )
};

const Options = ({index, color, options}) => {
    const {
        dispatchAnswers,
        truthButtonBackground,
        truthButtonTextColor,
        lieButtonBackground,
        lieButtonTextColor
    } = useContext(TruthOrLieContext);
    const [active, setActive] = useState(null);
    const clickHandler = answerIndex => {
        setActive(answerIndex);
        dispatchAnswers({index: index, value: answerIndex});
    };

    return (
        <Row style={{alignItems: "center"}}>
            {options.map((option, index) => {
                const isActive = active === index;
                const background = option.label === "אמת" ? truthButtonBackground : lieButtonBackground;
                const color = option.label === "אמת" ? truthButtonTextColor : lieButtonTextColor;
                return (
                    <Col xs={6} style={{padding: `${gutters.md} ${gutters.md}`}} key={index}>
                        <OptionSquare
                            onClick={() => clickHandler(index)}
                            isActive={isActive}
                            color={color}
                            background={background}>
                            {option.icon && (isActive) ? <Image src={option.icon}/> : option.label}
                        </OptionSquare>
                    </Col>
                )
            })}
        </Row>
    )
};

const TruthOrLieFinalScreen = () => {
    const {answers, adventureDataId, items, setActiveSentence, setShowIncorrectAnswers} = useContext(TruthOrLieContext);
    const correctAnswers = answers.filter(item => item.a === item.u);
    const successLength = correctAnswers.length;

    const firstWrongIndex = answers.indexOf(answers.find(answer => answer.a !== answer.u));

     const allCorrect = successLength === items.length;
     return (
        <Element paddingBottom={"20px"}
                 style={{
                     backgroundColor: "rgba(255,255,255,.8)",
                     borderRadius: "20px",
                     textAlign: "center",
                     paddingTop: "20px"
                 }}>
            <Heading tag={"h2"} value={allCorrect ? "כל הכבוד" : `עניתם נכון על ${successLength} שאלות`}/>
            <Paragraph value={allCorrect ? "קראו את המשפט שנוצר בעזרת המקרא" : "התשובות הלא נכונות מסומנות באדום"}/>
            <TruthOrLieIndicators size={100} showAnswers={true}/>
            <Button block
                    onClick={() => {
                        setShowIncorrectAnswers(true);
                        setActiveSentence(firstWrongIndex || 0)
                    }}>
                {allCorrect ? <Link to={`/menu/${adventureDataId || ''}`} style={{color: "#fff"}}>המשך</Link> : "נסו שוב"}
            </Button>
        </Element>
    )
};

export default ToolTruthOrLie;