import styled from 'styled-components';
import {gutters} from "../../00_base/variables";

export const Wrapper = styled.div`
    background-color: ${props => props.screenBackground};
    padding: ${gutters.sm};
    max-width: 500px;
    margin: 0 auto;
    border: ${props => `${props.borderWidth}px solid ${props.borderColor}`};
`;
export const Sentence = styled.div`
    margin: ${gutters.xl} 0; 
    display: ${props => props.isActive ? 'block' : 'none'};
`;
export const IndicatorWrapper = styled.div`
    padding: 0 ${gutters.xs};
`;


export const OptionSquare = styled.div`
    padding: ${gutters.xs};
    // min-height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 2.5rem;
    border-radius: 30px;
    background: ${props => props.background || "#fff"};
    color: ${props => props.color || "#000"};
    height: 100%;
    width: 100%;
    box-sizing: border-box !important;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    transition: box-shadow 0.3s;

    &:hover {
        cursor: pointer;
        box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    }
`;



