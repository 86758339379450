import React from "react";
import {gutters} from "./00_base/variables";
import {keyframes} from "styled-components";
import styled from "styled-components";
import { MdPhoneIphone } from 'react-icons/md';
import {StarsBackground} from "../components/03_organisms/stars-background/stars-background";
import ROTATE_BG from "../assets/images/pizza/rotate_message.png"
export const WarningMessage = () => {
    return <div id="warning-message" style={{
        width:"100vw", height:"100vh", backgroundImage:`url(${ROTATE_BG})`,
        backgroundSize:"cover", backgroundPosition:"center center"
    }}>

    </div>
};

const Rotate = keyframes` 
        0% {
            transform: rotate(0deg); 
        }
        40% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0deg); 
        }
        100% {
            transform: rotate(0deg); 
        }
`;
const PhoneIconWrapper = styled.div`
    transform: rotate(90deg);
    transform-origin: center; 
    animation: ${Rotate} 3s infinite;
`;