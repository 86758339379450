import guidingSystemSolutionImg from "../assets/images/guiding-system-riddle/solution.jpg";
import planetSystemSolutionImg from "../assets/images/planet-system/solution.jpg";
const phoneNumberAnswer = localStorage.getItem("phoneNumber");

class Word {
  constructor(hebrew, english) {
    this.hebrew = hebrew;
    this.english = english;
  }
}

export const DICTIONARY = {
  instructions: {
    choose_language: new Word("בחר שפה", "Choose language"),
    guide: new Word(
      `לפני תחילת המשחק יש להוריד את המדריך\nלהטסת החללית במקרה חירום`,
      `Before starting the game,\nplease download The spaceship's “emergency flight manual” (PDF file)`
    ),
    download_file: new Word(`להורדת הקובץ`, `download file`),
    go_back: new Word("חזור", "Return"),
    back_mission: new Word(`חזור\nלמשימה`, `Return to mission`),
    continue_to_bridge: new Word(`לגשר הפיקוד`, `Continue to bridge control`),
    inputted: new Word(
      `המשימה הושלמה
    `,
      `Core code inputted successfully`
    ),
    personalized_adventure: new Word(
      `רוצים ליצור הרפתקה בהתאמה אישית?`,
      `Would you like a personalized Adventure?`
    ),
    press_here: new Word(`לחצו כאן`, `Press here`),
    enter_code: new Word(
      `הכנס קוד לתחילת המשחק`,
      `Type code to start the game`
    ),
    continue_: new Word(`המשך`, `Continue`),
    start_the_adventure: new Word(`התחילו בהרפתקה!`, `Start the adventure`),
    back_to_command_bridge: new Word(`חזרה`, `Go Back`),
    return_to_mission: new Word("חזור למשימה", `Return to the mission`),
    power_spaceship_restored: new Word(
      ``,
      `Power to the spaceship's systems was restored`
    ),
    navigation_successful: new Word(``, `Navigation was successful`),
    access_engine_system_authorized: new Word(
      `גישה למערכת המנועים אושרה`,
      `Access to the engine system authorized`
    ),
    continue_to_engines: new Word(`המשך אל המנועים`, `Continue to the engines`),
    access_automatic_pilot: new Word(
      `לגישה לטייס האוטומטי יש לחייג לקפטן החללית`,
      `To access the automatic pilot, call the spaceship’s captain.`
    ),
    the_spaceship_captain: new Word(`קפטן הספינה`, `The spaceship's captain`),
    spaceship_text: new Word(
      `מצוין! קפטן הספינה מוסר את תודתו על תיקון מערכות החללית, כל הכבוד! כעת ניתן לשגר את "וויצ'יטה" בבטחה.`,
      `Of course! It’s all coming back to you now, like a distant memory, the asteroid collision resulted in hitting your head and forgetting that you were the captain all along. Now all you have to do is activate the automatic pilot and fly the Odyssey away safely.`
    ),
    treasure_text: new Word(
      `כל מערכות הטיסה פועלות. לחצו כאן לשיגור החללית ליעד!`,
      `All flight systems are operational. Press here to launch the spaceship to its destination!`
    ),
    treasure_form_warning: new Word(
      "העלו תמונה וספרו לנו איך היה",
      "Upload a picture and tell us what it was like"
    ),

    wrong_password: new Word("סיסמה שגוייה", "Wrong password"),
    well_done: new Word("כל הכבוד!", "Well done!"),
    winning_selfie: new Word("לצילום סלפי נצחון", "Take an astro-selfie"),
    name: new Word("שם", "Name"),
    first_name: new Word("שם פרטי", "First name"),
    how_was_it: new Word("איך היה?", "How was it?"),
    tell_us: new Word("ספרו לנו איך היה?", "Tell us how was it?"),
    share_experience: new Word("שתפו חוויה", "Share the experience"),
    join_our_community: new Word("הצטרפו לקהילה שלנו", "Join our community"),
    go_to_hall_of_fame: new Word("צפו בהכיל התהילה", "Go to Hall of Fame"),
    thank_you_for_playing: new Word(
      "תודה שהצטרפתם להרפתקה",
      "Thank you for joining the adventure"
    ),

    email_address: new Word("כתובת דואר אלקטרוני", "E-mail address"),
    share: new Word("שתף", "Share"),
    the_answer_is_the_ode_To_The_next_question: new Word(
      "התשובה היא הקוד שיפתח את הכלי הדיגיטלי הבא",
      "The answer is the code that will open the next digital tool"
    ),
    upload_a_victory_photo: new Word(
      "העלו תמונת ניצחון ושתפו בחוויתכם מההרפתקה!",
      "Upload a victory photo and share your experience from the adventure!"
    ),
    send: new Word("שליחה", "Send"),
  },
  general: {
    clue_1: new Word("רמז 1", "Clue 1"),
    clue_2: new Word("רמז 2", "Clue 2"),
    solution: new Word("פתרון", "Solution"),
  },
  alternate_power: {
    riddle_name: new Word("מערכת כוח חלופי", "Alternate power"),
    clue_1: new Word(
      `שלום, כאן מחשב החללית. התנגשנו באסטרואידים? לא נורא, אני עדיין פה.
            על מנת להחזיר את הכוח למערכות החללית יש למצוא את החיבור בין הצינורות השונים.
            מתחת למערכת הצינורות יש שמונה לחצנים. רק ארבעה מהם מייצגים את החיבורים הנכונים.`,
      `Hello, this is the ship’s computer. Have we collided with asteroids? No worries, I am still here. In order to return power to the spaceship’s systems, you must find the connection between the various pipes. 
             Underneath the pipe system are 8 buttons. Only 4 buttons represent the correct connections.
             `
    ),
    clue_2: new Word(
      `עקבו אחר כל סמל במערכת הצינורות כדי לראות לאיזה סמל אחר הוא מוביל. 
            שילוב של כל שני סמלים יחד הוא לחצן שיש ללחוץ עליו.`,
      `Follow each symbol in the pipe system to see to what other symbol it leads. A combination of each two symbols is a button that must be pressed.`
    ),
    solution: new Word(
      `לחצו (משמאל לימין) על הלחצן הראשון, השלישי, החמישי, והשביעי.`,
      `Press the first, third, fifth and seventh button (from left to right).`
    ),
    succsess: new Word(`המשימה הושלמה`, `Power successfully restored`),
  },
  navigation_system: {
    riddle_name: new Word("מערכת הניווט", "Navigation system"),
    clue_1: new Word(
      `שלום, כאן מחשב החללית. בעיה במערכת הניווט?
        אל דאגה. ב"מדריך להטסת החללית במקרה חירום" יש מכ"מ ניווט. 
        העזרו בו על מנת לסובב את מחוגי הניווט לכיוונים הנכונים.`,
      `Hello, this is the spaceship’s computer. Is there a problem with the navigation system? No worries. The spaceship’s Emergency Flight Manual has a navigation radar. Use it in order to rotate the navigation dials to the correct position.`
    ),
    clue_2: new Word(
      `הסתכלו על המיקום של כל חללית במכ"מ הניווט. 
        כל אחת מהן בצבע שונה התואם לצבע של אחד מגלגלי הניווט.
        סובבו כל גלגל לכיוון שאליו מצביעה החללית התואמת לצבע שלו.`,
      `Look at the position of each spaceship on the navigation radar. Each one has a different color which matches the color of one of the navigation wheels. Rotate each wheel in the direction to which the corresponding colored spaceship points.`
    ),
    solution: new Word(guidingSystemSolutionImg, guidingSystemSolutionImg),
    succsess: new Word(`הניווט בוצע בהצלחה`, `Navigation successful`),
  },
  core_system_encryption: {
    riddle_name: new Word(`קידוד מערכת הליבה`, `Core system encryption`),
    clue_1: new Word(
      `שלום, כאן מחשב החללית. צריכים את קוד מערכת הליבה? על קרטון הפיצה מופיע סמל קוד מערכת הליבה. כדאי לבחון ממה ניתן להרכיב את הסמל הזה. הקוד הוא בן ארבע ספרות.

      `,
      `Hello, this is the spaceship’s computer. Need the code for the core system? The spaceship’s Emergency Flight Manual has the code symbol for the core system. Find out how this symbol can be composed. The code is 4 digits long.`
    ),
    clue_2: new Word(
      `יש ארבע שורות של סמלים. מכל שורה צריך לקחת סמל אחד. 
        ארבעת הסמלים יחד צריכים להרכיב את סמל קוד מערכת הליבה שבמדריך.
        השילוב של ארבעת הסמלים הנכונים יתן את הקוד.`,
      `There are 4 rows of symbols. You must take one symbol from each row. The four symbols combined should form the core system’s code symbol which appears in the manual.`
    ),
    solution: new Word(
      `הסמל שמעל 3, הסמל שמעל 20, הסמל שמעל 700 והסמל שמעל 4000 הם הסמלים הנכונים.
        הקוד אם כך הוא 4723`,
      `The symbol over 3, the symbol over 20, the symbol over 700 and the symbol over 4000 are the correct ones. Therefore, the code is 4723        `
    ),
    succsess: new Word(
      `קוד הליבה הוזן בהצלחה`,
      `Core code has been successfully inputted
        Continue to the command bridge`
    ),
  },
  activate_weapon_systems: {
    riddle_name: new Word(`הפעלת מערכות נשק`, `Activate weapon systems`),
    clue_1: new Word(
      "שלום, כאן מחשב החללית. חייזר תקף את הספינה בפתאומיות? בלי פאניקה! אני כאן. כדי להפעיל את מערכות הנשק צריך לכוון את המתגים במדויק לסוג החייזר הזה. קרטון הפיצה לא יעזור לכם כאן. שימו לב שזה חייזר עם שמונה זרועות ויש שמונה מתגים.",
      `Hello, this is the spaceship’s computer. An alien has suddenly attacked the spaceship? Don’t panic. I’m here. In order to activate the weapon systems, you should aim the switches towards this alien with precision. The spaceship’s flight manual will not help you here. Note that this alien has 8 tentacles and that there are 8 switches.`
    ),
    clue_2: new Word(
      `שימו לב לזרועות של החייזר. 
        חלק מהן מכוונות כלפי למטה וחלק מהן מורמות כלפי מעלה.
        ממש כמו המתגים שאפשר להעלות או להוריד.`,
      `Pay attention to the alien’s tentacles.
        Some of them are aimed downwards and some are raised upwards. Just like the switches that can be raised or lowered.`
    ),
    solution: new Word(
      `כדי לחסל את החייזר צריך לכוון כל מתג לאחת מהזרועות של החייזר.
        הפתרון:
        OFF ON ON OFF OFF ON ON ON`,
      `In order to take out the alien, each switch must be aimed at one of the alien’s tentacles. The solution: OFF ON ON OFF OFF ON ON ON`
    ),
    succsess: new Word(
      `החייזר חוסל בהצלחה`,
      `The alien was successfully destroyed`
    ),
  },
  the_planet_system: {
    riddle_name: new Word(`מערכת הפלנטות`, `The planet system`),
    clue_1: new Word(
      `שלום, כאן מחשב החללית. צריכים לבצע טריאנגולציה של מערכת הפלנטות?
        אין בעיה. יש שלוש פלנטות נכונות שצריך לבחור.
        ב"מדריך להטסת החללית במקרה חירום" יש מערך לייזרים שישמש אתכם למשימה.
        נסו "להפעיל" את הלייזרים האלה על הנייר.`,
      `Hello, this is the spaceship’s computer. Need to triangulate the planet system? No problem. There are 3 correct planets which must be chosen. In the spaceship’s Emergency Flight Manual you will find a laser array which will serve you for this task. Try to “activate” these lasers on the page.`
    ),
    clue_2: new Word(
      `מתחו קו ישר מכל לייזר (בצבע שלו) בכיוון בו הוא מצביע. 
        הקווים נחתכים בשלוש נקודות. נקודות החיתוך הן הפלנטות אותן אתם צריכים.`,
      `Draw a straight line from each laser (in its color) in the direction to which it points. The lines intersect at 3 points. These points of intersection are the planets you need.`
    ),
    solution: new Word(planetSystemSolutionImg, planetSystemSolutionImg),
    succsess: new Word(
      `טריאנגולציה למערכת הפלנטות בוצעה בהצלחה`,
      `Planet system triangulation performed successfully`
    ),
  },
  radiation_warning: {
    riddle_name: new Word(`אזהרת קרינה`, `Radiation warning`),
    clue_1: new Word(
      `
      שלום, כאן מחשב החללית. אזהרת קרינה פתאומית? כנראה עברנו קרוב מדי לחור השחור. לא לדאוג. על קרטון הפיצה יש מונה קרינה. הסתכלו עליו. יכול להיות שהוא מסמן מילים מסוימות באזהרת הקרינה. `,
      `Hello, this is the spaceship’s computer. Sudden radiation warning? We must have passed too close to the black hole. No worries. In the spaceship’s Emergency Flight Manual you will find a radiation meter. take a good look at it, it may highlight some words in the radiation warning.`
    ),
    clue_2: new Word(
      `כל ריבוע שחור ב"מונה הקרינה" מסמן מילה מסוימת ב"אזהרת הקרינה"
        נסו לכתוב לעצמכם את כל המילים האלה בצד, ברצף.
        זכרו שאתם מחפשים קוד בן ארבע ספרות.`,
      `Each black square in the “radiation meter” represents a certain word in the “radiation warning”
        Try and write all the words one after the other, and say them out loud.
        You’re looking for a 6-letter password.
        `
    ),
    solution: new Word(
      `המילים שמתקבלות ממונה הקרינה הן "אלפא", "רבע", "מאות", "שלו", "שימוש", "מונה"
        אם כותבים אותן אחת ליד השניה מקבלים אלפארבעמאותשלושימושמונה, 
        שהם אלף ארבע מאות שלושים ושמונה. הקוד הוא 1438.`,
      `The words obtained from the radiation meter are “why” “see” “be” “you” “oh” “pee”.
        They form the code YCBUOP`
    ),
    succsess: new Word(
      `הקרינה נוטרלה בהצלחה`,
      `Radiation neutralized successfully`
    ),
  },
  engine_access: {
    riddle_name: new Word(`גישה למנועים`, `Engine Access`),
    clue_1: new Word(
      `
      שלום, כאן מחשב החללית. הגישה למנועים חסומה בסיסמה? בשביל זה אני כאן. על קרטון הפיצה יש איזור "גישה למנועים" שמכיל מקרא בין שפה חייזרית לבין אנגלית. השתמשו בו.`,
      `Hello, this is the spaceship’s computer. The access to the engines is locked with a password?
        That’s what I'm here for.
        In the spaceship's Emergency Flight Manual there is an "access to engines” section which includes a legend which translates the alien language to English.
        Use it.
        `
    ),
    clue_2: new Word(
      `המקלדת להכנסת הסיסמה באנגלית זהה למקלדת שבמדריך. 
        מצאו במדריך את חמשת הסמלים ותרגמו אותם לאנגלית על ידי השוואה בין המקלדות.`,
      `The keyboard for inputting the password in English is identical to the keyboard in the manual. 
        Find the five symbols in the manual and translate them to English by comparing the keyboards.
        `
    ),
    solution: new Word(`הקוד הוא המילה SPACE`, `The code is SPACE`),
    succsess: new Word(``, ``),
  },
  activating_the_engines: {
    riddle_name: new Word(`הפעלת מנועים`, `Activating the engines`),
    clue_1: new Word(
      `שלום, כאן מחשב החללית. לא יודעים איך להפעיל את המנועים?
        זה בסדר, אני מחשב וגם אני מסתבך עם דברים כאלה.
        ב"מדריך להטסת החללית במקרה חירום" יש את מבנה מנוע העל חלל.
        מתחת לכל בורר מספרים יש אוסף של סמלים. אותם סמלים גם מופיעים במבנה המנוע.
        נסו לבדוק מה קורה אם אתם בוחנים במנוע רק את הקווים שאותם סמלים מייצגים.`,
      `Hello, this is the spaceship’s computer. Don’t know how to activate the engines?
        Do not worry, I am a computer and I also have trouble with stuff like this.
        The spaceship’s Emergency Flight Manual includes the hyperspace engine’s structure.
        Underneath each number selector is a collection of symbols. The same symbols also appear in the engine structure.
        Try and check what happens if you only examine the lines in the engine represented by the symbols.
        `
    ),
    clue_2: new Word(
      `מבנה מנוע העל החלל בנוי כמו הספרה 8.
        כל סמל במנוע מייצג את אחד משבעת הקווים שמרכיבים את ה8 הזה.
        מתחת לכל בורר מעגלי יש אוסף של סמלים, שמייצגים קווים במבנה המנוע.
        נסו לעקוב אחר הקווים האלה על מנת לקבל את הספרות הנכונות.`,
      `The hyperspace engine structure is built like the number 8.
        Each symbol in the engine represents one of the seven lines which form this ‘8’.
        Under each circular selector is a collection of symbols which represent lines in the engine structure.
        Try and follow these lines in order to obtain the correct digits.`
    ),
    solution: new Word(`הקוד הוא 6254`, `The code is 6254`),
    succsess: new Word(
      `המנועים הופעלו בהצלחה`,
      `Engines activated successfully`
    ),
  },
  access_to_the_automatic_pilot_system: {
    riddle_name: new Word(
      `מערכת טייס אוטומטי`,
      `Access to the automatic pilot system`
    ),
    clue_1: new Word(
      `שלום, כאן מחשב החללית. לא יודעים איך לקבל גישה למערכת הטייס האוטומטי? צריך להכניס את מספר הטלפון של הקפטן. יש שם תמונה של הקפטן אז זה אמור להיות די פשוט. `,
      `Hello, this is the spaceship’s computer. Don’t know how to get access to the automatic pilot system?
        I thought I was the automatic pilot. Oh well.
        It seems that in order to get access to the automatic pilot system you need to input the captain's phone number.
        There’s a picture of the captain there, so it should be pretty simple. You probably know who the captain is.
        `
    ),
    clue_2: new Word(
      `הכניסו את מספר הטלפון של קפטן החללית. חפשו את מספר הטלפון שלו על הקופסה.`,
      `Input the captain's phone number. It's one of you, the one in the picture.`
    ),
    solution: new Word(
      `פתרון הקוד הוא 1700506070`,
      `When commissioning the adventure you were asked to attach a photo and a phone number. 
        The code is the phone number you included when you did so. This is the number you included:
        [number]
        `
    ),
    succsess: new Word(``, ``),
  },
  automatic_pilot_system: {
    riddle_name: new Word(`מערכת טייס אוטומטי`, `Automatic pilot system`),
    clue_1: new Word(
      `שלום, כאן מחשב החללית. לא בטוחים איך להפעיל את הטייס האוטומטי?
        זה בסדר גם אני מסתבך עם זה. ואני מחשב!
        ב"מדריך להטסת החללית במקרה חירום" מופיעה מערכת הפעלת טייס אוטומטי.
        לטייס האוטומטי יש שלושה ראשים רובוטיים. מתחת לכל ראש יש גריד של משבצות צבעוניות.
        לכל ראש יש גם צבע שונה. אולי כדאי להתמקד בכל גריד רק בצבע של אותו ראש רובוטי.`,
      `Hello, this is the spaceship’s computer. Not sure how to operate the automatic pilot?
        It’s okay, I have trouble with it sometimes as well. And I'm a computer!
        The spaceship's Emergency Flight Manual includes an automatic pilot operation system.
        The automatic pilot has three robotic heads. Underneath each head is a grid of colorful squares.
        Each head has a different color as well. Maybe, in each grid, you should only focus on the color of the same robotic head.
        `
    ),
    clue_2: new Word(
      `הסתכלו על הרובוט עם הראש העגול. הוא אדום. לכן בדף המדריך, בגריד שמתחת לראש האדום יש לבחור רק את המשבצות האדומות. לחצו עליהן במערכת הטייס האוטומטי.
        כעת בצעו זאת גם עבור שני הראשים האחרים עם הצבעים שלהם והגריד שלהם.
        אם עשיתם זאת נכון, יחשף לכם קוד בן שלוש ספרות.`,
      `Look at the robot with the round head. It’s yellow. Therefore, in the manual page, in the grid under the yellow head, you should only select the yellow squares. Press them in the automatic pilot system.
        Now do the same thing for the other two heads with their colors and grids.
        If you did it correctly, you'll get a 3-digit code.
        `
    ),
    solution: new Word(`הקוד הוא 253`, `The code is 253`),
    succsess: new Word(``, ``),
  },
  launching_Spaceship: {
    riddle_name: new Word(`שיגור החללית`, `Launching Spaceship`),
  },
};
