import styled from "styled-components";
import { colors, font_size } from "../../00_base/variables";

export const ContainerWrapper = styled.div`
  position: relative;
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (min-width: 1440px) {
    height: 100%;
    justify-content: space-between;
    // position: absolute;
    top: 0;
    left: 0;
    right: auto;
  }
`;

export const Wrapper = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    background-color: ${(props) => props.backgroundColor};
    background-image: url(${(props) => props.backgroundImage});
    background-repeat: no-repeat;
    }
`;
export const BottomBackground = styled.div`
  display: flex;
  padding: 50px 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: bottom left;
  position: relative;
  overflow: visible;
  width: 100%;
  .game-logo {
    width: 20rem;
    position: relative;
  }
  @media (min-width: 1440px) {
    // background-image: none;
    justify-content: center;
    // height: 120px;
    // padding: 50px;
    .game-logo {
      width: 20rem;
      // top: -120px;
    }
  }
`;
export const MobileOnly = styled.div`
  @media (min-width: 1440px) {
    display: none;
  }
`;

export const styles = (
  newsletterButtonColor,
  newsletterButtonBackground,
  fieldsColor,
  fieldsBackground,
  contentColor
) => {
  return {
    submit: {
      width: "23rem",
      borderRadius: "30px",
      display: "block",
      margin: "0 auto",
      padding: "10px 0",
      fontWeight: "bold",
      fontSize: font_size.md,
      backgroundColor: "#0dff00",
      color: "#000",
    },
    fields: {
      inputStyle: { color: fieldsColor },
      backgroundColor: fieldsBackground,
      paddingVertical: "10px",
      paddingHorizontal: "10px",
      borderRadius: "50px",
      border: `1px solid ${colors.primary_dark}`,
    },
    hr: {
      border: `2px dashed ${contentColor}`,
      width: "100%",
    },
  };
};
