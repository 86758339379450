import React, {useState} from "react";
import './screen-core-system.scss';
import mapSrc from "../../../assets/images/pizza/scene02_secret_code.png";
import SpaceCoder from "../../tools/space-coder/space-coder";
import {ClueTrigger} from "../../ScreenClue/clue-trigger";
import MenuReturn from "../../ScreenMenuReturn/screen-menu-return";
import {Link} from "react-router-dom";
import SuccessDisplay from "../../SuccessDisplay/success-display";

export const ScreenCoreSystem = ({riddleData, isResolved, onSubmit}) => {
    const [isUserSuccessed, setIsUserSuccessed] = useState(isResolved);    

    const onUserSubmit = userCode => {
        if (userCode === riddleData.password) {
            setIsUserSuccessed(true);
            onSubmit();
        }
    };

    return (
        <div className={"screen-core-container"}>
            <ClueTrigger id={riddleData.id} top={'0'} right={'0'}/>
            <MenuReturn id={riddleData.id} top={'0'} left={'0'}/>

            <div className={"screen-core-wrapper"}>
                <img className={"screen-core-map"} src={mapSrc} width={100}/>
                <img className={"seperate-line"}/>

                <div className={"screen-core-code"}>
                    {!isUserSuccessed ?
                        <SpaceCoder onSubmit={onUserSubmit}/>:
                        <div className={"core-success"}>
                            <SuccessDisplay title={riddleData.successText}/>
                        </div>
                    }
                </div>

            </div>
        </div>
    )
};