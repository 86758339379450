import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "./space-coder.scss";
import { Audio } from "../../01_atoms/audio/audio";
import SpaceLoader from "../space-loader/space-loader";
import { playAudio } from "../../../assets/helpers";
import Audio7 from "../../../assets/audios/11.wav"; //can be also 7 or 14

const SpaceCoder = ({
  startNumber,
  endNumber,
  digitsLength,
  onSubmit,
  countInRow,
  textColor,
}) => {
  const [userDigits, setUserDigits] = useState("");
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const AudioId = "audio-space-coder";

  const onNumberClick = (newDigit) => {
    console.log(userDigits);
    console.log(newDigit);
    setTimeout(() => {
      if (digitsLength + 1 === userDigits.length + 2) {
        onUserSubmit(userDigits + newDigit.toString());
      }
    }, 300);

    setShowError(false);
    playAudio(AudioId);
    setShowError(false);
    if (userDigits.length !== digitsLength + 1) {
      setUserDigits(userDigits + newDigit.toString());
    }
  };

  const onUserSubmit = (userCode) => {
    setIsLoading(true);
    setTimeout(() => {
      setUserDigits([]);
      setIsLoading(false);
      setShowError(!onSubmit(userCode));
    }, 1000);
  };

  const removeLastDigit = () => {
    setUserDigits(userDigits.slice(0, -1));
  };

  const renderNumbers = () => {
    let returnValue = [];
    for (
      let currNumber = startNumber;
      currNumber <= endNumber;
      currNumber += countInRow
    ) {
      returnValue.push(
        <div className={"numbers-row"}>
          {Array.from(Array(countInRow).keys()).map((numToAdd) => (
            <div
              className={"single-number"}
              onClick={() => onNumberClick(currNumber + numToAdd)}>
              {currNumber + numToAdd}
            </div>
          ))}
        </div>
      );
    }

    returnValue.push(
      <div className={"numbers-row"}>
        <div
          className={"single-number zero"}
          onClick={() => onNumberClick("0")}>
          0
        </div>
        <div className={"single-number remove"} onClick={removeLastDigit}>
          ⌫
        </div>
      </div>
    );
    return returnValue;
  };

  const renderUserDigits = () => {
    let returnValue = [];
    for (
      let currDigitIndex = 0;
      currDigitIndex <= digitsLength - 1;
      currDigitIndex++
    ) {
      returnValue.push(
        <div
          className={"single-user-code"}
          style={{ color: textColor }}
          key={currDigitIndex}>
          {userDigits[currDigitIndex] || ""}
        </div>
      );
    }
    return returnValue;
  };

  return (
    <div className={"space-coder-container"}>
      <Audio source={Audio7} id={AudioId} />
      <div className={"user-code-container"}>{renderUserDigits()}</div>
      <div className={"numbers-keymap"}>
        {isLoading ? <SpaceLoader /> : renderNumbers()}
      </div>
      <div
        className={"wrong-code"}
        style={{ visibility: showError ? "visible" : "hidden" }}>
        קוד לא נכון
      </div>
    </div>
  );
};

SpaceCoder.defaultProps = {
  startNumber: 1,
  endNumber: 9,
  digitsLength: 4,
  countInRow: 3,
  textColor: "white",
};
SpaceCoder.propTypes = {
  countInRow: PropTypes.number,
  startNumber: PropTypes.number,
  endNumber: PropTypes.number,
  digitsLength: PropTypes.number,
  onSubmit: PropTypes.func,
  textColor: PropTypes.string,
};

export default SpaceCoder;
