import styled from 'styled-components';
import { slideIn } from '../../00_base/animations/keyframes';

const ModalWrapper = styled.div`
    animation: ${slideIn} .2s ease-out;
    position: fixed;
    background-color: ${props => props.backgroundColor};
    width: 100%;
    height: 100%;
    z-index: 9999;
    
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform ${props => props.speed}, opacity ${props => props.speed};
    opacity: 0;
    transform: ${props =>
        props.animate === 'tb' ? 'translateY(-100%)' :
            props.animate === 'bt' ? 'translateY(100%)' :
                props.animate === 'rl' ? 'translateX(100%)' :
                    props.animate === 'lr' ? 'translateX(-100%)' :
                        'translateY(-100%)'};
    &.active, &.closing {
        transform: translate(0);
        opacity: 1;
    }
`;

const ModalContent = styled.div`
    width: ${props => props.width};
    height: ${props => props.height ? props.height : ''};
    background-color: ${props => props.backgroundColor};
    padding: ${props => props.padding};
    border-radius: ${props => props.borderRadius};

`;

const ModalTitle = styled.h3`
    font-size: ${props => props.fontSize};
    text-align: ${props => props.textAlign};
`;

const ModalSubtitle = styled.h5`
    font-size: ${props => props.fontSize};
    text-align: ${props => props.textAlign};
`;

const ModalDescription = styled.p`
    font-size: ${props => props.fontSize};
    text-align: ${props => props.textAlign};
`;

const ModalCloseTrigger = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
`;

export { ModalWrapper, ModalContent, ModalTitle, ModalSubtitle, ModalDescription, ModalCloseTrigger };