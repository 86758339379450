import React, {useEffect, useState} from "react";
import './screen-planet-system.scss';
import planet from "../../../assets/images/planet-system/systems.png"
import * as planetsImages from '../../../assets/images/planet-system/planets'
import SpaceNavbar from "../../ScreenSpaceNavbar/screen-space-navbar"
import {playAudio} from "../../../assets/helpers"
import {Audio} from "../../01_atoms/audio/audio";
import Audio10 from "../../../assets/audios/10.wav";

const planetsImagesArray = [
    planetsImages.A,
    planetsImages.B,
    planetsImages.C,
    planetsImages.D,
    planetsImages.E,
    planetsImages.F,
    planetsImages.G,
    planetsImages.H,
    planetsImages.I,
    planetsImages.AA,
    planetsImages.BB,
    planetsImages.CC,
    planetsImages.DD,
    planetsImages.EE,
    planetsImages.FF,
    planetsImages.GG,
    planetsImages.HH,
    planetsImages.II,
];

const DIFF = 9;

export const ScreenPlanetSystem = ({riddleData, onSubmit, isResolved}) => {

    const [isUserSucsess, setIsUserSucsess] = useState(isResolved);
    const [isUserFailed, setIsUserFailed] = useState(false);
    const [planetsData, setPlanetsData] = useState([]);
    const [isCheckingValidation, setIsCheckingValidation] = useState(false);

    const answersIndexs = [0, 4, 6];

    const audioId = "audio-plant-system";

    useEffect(() => {
        setInitialPlanets()
    }, []);

    const checkValidation = () => {
        setIsCheckingValidation(true)
        let validationValue = true;
        answersIndexs.map(answerIndex => {
            if (!planetsData[answerIndex].mode) {
                validationValue = false;
            }
        });
        if (validationValue) {
            setIsUserSucsess(validationValue);
            onSubmit();
        } else {
            setTimeout(() => {
                setInitialPlanets()
                setIsUserFailed(true)
                setIsCheckingValidation(false)
            }, 3000)
        }
    };

    const setInitialPlanets = () => {
        setPlanetsData(isUserSucsess ?
               [{index: 0, mode: true},
                {index: 1, mode: false},
                {index: 2, mode: false},
                {index: 3, mode: false},
                {index: 4, mode: true},
                {index: 5, mode: false},
                {index: 6, mode: true},
                {index: 7, mode: false},
                {index: 8, mode: false}]
                 :
                [{index: 0, mode: false},
                {index: 1, mode: false},
                {index: 2, mode: false},
                {index: 3, mode: false},
                {index: 4, mode: false},
                {index: 5, mode: false},
                {index: 6, mode: false},
                {index: 7, mode: false},
                {index: 8, mode: false}])
    };
    const howManyPlanetsClicked = () => {
        let counter = 0;
        planetsData.map(planet => {
            if (planet.mode) {
                counter++
            }
        })
        return counter
    };

    const onImageClick = (index) => {
        setIsUserFailed(false)
        let newState = [...planetsData];
        playAudio(audioId)
        newState[index].mode = !newState[index].mode;
        setPlanetsData(newState);
        if (howManyPlanetsClicked() === 3) {
            checkValidation()
        }
    };

    const renderPlanets = () => {
        return planetsData.map(planet => {
            const {index, mode} = planet;
            const src =  mode ? planetsImagesArray[index + DIFF] : planetsImagesArray[index];
            return <div className={"planet-img-wrapper"} onClick={e => {onImageClick(index)}} style={isCheckingValidation ? {"pointer-events":"none"}: {}}>
                <img src={src} className={"planet-img"}/>
            </div>
        })
    };

    return (
        <div className={"planet-system-container"}>
            <Audio source={Audio10} id={audioId}/>
            <img src={planet} className={"system-img"}/>
            <div className={"planets-row"}>
                {renderPlanets()}
            </div>
            <div className={"bar"}/>
            <SpaceNavbar id={riddleData.id} text={isUserSucsess && riddleData.successText} isSuccess={isUserSucsess} showClues={true} showMenuReturn={true} isError={isUserFailed}/>
        </div>
    )
};