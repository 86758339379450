import React from "react";
import { VBox, HBox, Element } from "react-stylesheet";
import { gutters, colors, font_size } from "../../00_base/variables";
import Iframe from "../../01_atoms/iframe";
import { Button, Image, Heading, Paragraph } from "../../01_atoms";
import GAME_ICON from "../../../assets/images/logos/youdo_logo_white.png";
import Anchor from "../../01_atoms/anchor/anchor";
import { Form } from "../../03_organisms";
import { SocialShare } from "./social-share";
import { shareFormFields } from "./share-form-fields";
import {
  Wrapper,
  BottomBackground,
  ContainerWrapper,
  styles,
} from "./tool-treasure-styles";
import { MobileOnly } from "../../01_atoms/responsive/responsive";
import { DICTIONARY } from "../../../dictionary/dictionary";
import FINAL_VIDEO from "../../../assets/images/pizza/outro_video.mp4";
import PIZZA_LOGO from "../../../assets/images/pizza/LOGO-RGB.png";
import CEREBRO from "../../../assets/images/cerebro-logo.png";

class ToolTreasure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "האוצר",
      showTreasureScreen: !!this.props.answer,
      secondLogo: this.props.secondLogo,
      treasureValue: "",
      showValidationError: false,
      isLoading: true,
      logo: null,
      disabled: true,
    };
  }

  handleShareFormSubmit = (formData) => {
    const data = {
      adventure_id: localStorage.getItem("adventureId"),
      name: formData.name,
      text: formData.description,
      title: formData.title,
      images: [formData.selfie],
    };
    if (data.name && data.title && data.images && data.images.length > 0) {
      this.postData("reviews", data, (success) => {
        this.setState({
          selfieSuccess: true,
        });
      });
    } else {
      this.setState({ showValidationError: true });
    }
  };

  postData = (type, data, handleSuccess, handleError) => {
    this.setState({
      selfieSuccess: "loading",
    });
    fetch("https://api.youdoadventures.com/reviews", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-Token": localStorage.token && localStorage.token,
      },
    })
      .then(
        (response) =>
          response.status >= 200 && response.status < 300 && response.json()
      )
      .then((success) => handleSuccess(success))
      .then((error) => (handleError ? handleError(error) : console.log(error)));
  };

  sendEmailToServer = (formData) => {
    const data = {
      name: "",
      phone: "",
      email: formData.email,
    };
    this.setState({
      newsletterLoading: true,
    });
    fetch(
      `https://api.youdoadventures.com//pizzahut_email?email=${data.email}`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then((success) => {
      this.setState({
        newsletterLoading: "done",
      });
    });
  };

  render() {
    const language = localStorage.getItem("language") || "hebrew";

    const {
        adventureData,
        selfieSuccess,
        selfieUrl,
        newsletterLoading,
        showValidationError,
      } = this.state,
      {
        // finalScreenBackground,
        finalTitle = "זכיתם בשובר מתנה של לחם שום או מקלות שוקולד  לבחירתכם",
        finalSubtitle = "שלחו לי את ההטבה",
        newSubTitle = '*ניתן לממש את השובר בהזמנה הבאה בכפוף לקניית פיצה משפחתית (L) או במינימום הזמנה למשלוח 69.90 ש"ח.',
        finalImage,
        // finalVideo,
        screenBackground,
        screenBackgroundImage,
        contentBackground,
        videoBorderWidth = 0,
        videoBorderColor,
        contentColor,
        selfieButtonBackground,
        selfieButtonColor,
        newsletterButtonBackground,
        newsletterButtonColor,
        fieldsBackground = "#fff",
        fieldsColor,
        videourl,
      } = this.props;

    const shareForm = shareFormFields(
      contentColor,
      selfieButtonBackground,
      selfieButtonColor,
      fieldsBackground,
      fieldsColor,
      selfieUrl,
      showValidationError,
      (data) => this.setState({ selfieUrl: data })
    );

    const style = styles(newsletterButtonColor, newsletterButtonBackground);
    const {
      welcome_screen_url_mobile,
      welcome_screen_url_tablet,
      welcome_screen_url_desktop,
    } = adventureData || {};

    return (
      <Wrapper
        className={"has-background-image background"}
        backgroundImage={screenBackgroundImage}
        backgroundColor={screenBackground}
        mobile={
          !!welcome_screen_url_mobile &&
          welcome_screen_url_mobile !== "false" &&
          welcome_screen_url_mobile
        }
        tablet={
          !!welcome_screen_url_tablet &&
          welcome_screen_url_tablet !== "false" &&
          welcome_screen_url_tablet
        }
        desktop={
          !!welcome_screen_url_desktop &&
          welcome_screen_url_desktop !== "false" &&
          welcome_screen_url_desktop
        }>
        <ContainerWrapper>
          <Element width={"100%"} padding={`0 ${gutters.sm}`}>
            <Element>
              <VBox
                alignItems={"center"}
                maxWidth={"100%"}
                background={contentBackground}
                width={"800px"}
                padding={`0 ${gutters.xl}`}
                margin={"0 auto"}>
                <iframe
                  src={FINAL_VIDEO}
                  autoPlay={true}
                  width='100%'
                  height='420px'
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    border: `${videoBorderWidth}px solid ${videoBorderColor}`,
                  }}
                />

                <Element
                  padding={`${gutters.md} 0`}
                  textAlign='center'
                  color={contentColor}>
                  {finalTitle && (
                    <Heading
                      tag='h2'
                      style={{ marginTop: 0 }}
                      value={finalTitle}
                      color={contentColor}
                    />
                  )}
                  <Heading
                    textAlign='center'
                    tag='h5'
                    color={contentColor}
                    value={newSubTitle}
                    style={{ color: "#fffffe", fontSize: "1.3rem" }}
                  />
                  {/*{finalSubtitle && <Heading tag="h3" style={{marginTop: 0}}*/}
                  {/*                           value={finalSubtitle}*/}
                  {/*                           color={contentColor}/>}*/}
                  {finalImage && <Image src={finalImage[0]} />}
                </Element>

                <VBox maxWidth={"100%"} width={"500px"} margin={"0 auto"}>
                  {/*<Form fields={shareForm}*/}
                  {/*      setIsFileLoading={(data) => console.log('loadingdata', data)}*/}
                  {/*      submitLabel={!selfieSuccess ? DICTIONARY.instructions.share[language] : selfieSuccess === 'loading' ? '...' : 'תמונתכם נכנסה להיכל התהילה'}*/}
                  {/*      hideTrigger={selfieSuccess && selfieSuccess !== 'loading'}*/}
                  {/*      submitStyle={{*/}
                  {/*          backgroundColor: "#0dff00", color: "#000", cursor: "pointer",*/}

                  {/*      }}*/}
                  {/*      handleFormSubmit={this.handleShareFormSubmit}/>*/}
                  {/*{showValidationError &&*/}
                  {/*<Paragraph color={"#fff"}*/}
                  {/*           value={DICTIONARY.instructions.treasure_form_warning[language]}/>}*/}

                  {/*{selfieSuccess && selfieSuccess !== 'loading' &&*/}
                  {/*<>*/}
                  {/*    <Button style={{background: "#0dff00", color: "#000"}} block>*/}
                  {/*        <Anchor*/}
                  {/*            href={`https://youdoadventures.com/${language.toLowerCase() === "english" ? "en" : "he"}/hall-of-fame/${localStorage.getItem("adventureId")}`}*/}
                  {/*            target={'_blank'}*/}
                  {/*            value={DICTIONARY.instructions.go_to_hall_of_fame}/>*/}
                  {/*    </Button>*/}

                  {/*</>*/}
                  {/*}*/}
                  {/*<SocialShare/>*/}
                  {/*{!selfieUrl &&*/}
                  {/*<MobileOnly style={{width: '100%'}}>*/}
                  {/*    <hr style={style.hr}/>*/}
                  {/*</MobileOnly>}*/}

                  <Element
                    marginTop={gutters.lg}
                    paddingBottom={gutters.md}
                    className='px-3'>
                    <Heading
                      textAlign='center'
                      tag='h3'
                      color={contentColor}
                      value={finalSubtitle}
                    />

                    <Form
                      submitLabel={
                        newsletterLoading === "done"
                          ? DICTIONARY.instructions.thank_you_for_playing[
                              language
                            ]
                          : newsletterLoading
                          ? "..."
                          : DICTIONARY.instructions.send[language]
                      }
                      submitStyle={style.submit}
                      fields={[
                        {
                          type: "text",
                          name: "email",
                          placeholder:
                            DICTIONARY.instructions.email_address[language],
                          ...style.fields,
                          inputStyle: { backgroundColor: fieldsBackground },
                        },
                      ]}
                      handleFormSubmit={this.sendEmailToServer}
                    />
                  </Element>

                  <VBox marginBottom={gutters.md}>
                    <Heading
                      tag={"h4"}
                      value={
                        DICTIONARY.instructions.personalized_adventure[language]
                      }
                    />
                    <Button
                      style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        backgroundColor: "rgb(13, 255, 0)",
                      }}>
                      <a
                        href={
                          "https://www.youdoadventures.com/#self-adventures"
                        }
                        target={"_blank"}
                        style={{
                          display: "block",
                          color: "#000000",
                          width: "100%",
                          height: "100%",
                        }}>
                        <b> {DICTIONARY.instructions.press_here[language]}</b>
                      </a>
                    </Button>
                  </VBox>

                  <HBox justifyContent={"space-around"} alignItems={"center"}>
                    <a
                      href={"https://www.youdoadventures.com"}
                      rel='noopener noreferrer'
                      style={{ width: "100%" }}
                      target={"_blank"}>
                      <Image src={GAME_ICON} width={"100px"} />
                    </a>

                    <a
                      href={"https://pizzahut.co.il/"}
                      rel='noopener noreferrer'
                      style={{ width: "100%" }}
                      target={"_blank"}>
                      <Image src={PIZZA_LOGO} width={"100px"} />
                    </a>

                    <a
                      href={"https://cerebro.co.il/"}
                      rel='noopener noreferrer'
                      style={{ width: "100%" }}
                      target={"_blank"}>
                      <Image
                        src={CEREBRO}
                        width={160}
                        className={"game-logo"}
                      />
                    </a>
                  </HBox>
                  <Paragraph
                    color={"#fff"}
                    style={{ display: "block", textAlign: "center" }}>
                    Powered by YOUDO
                  </Paragraph>
                </VBox>
              </VBox>
            </Element>
          </Element>
        </ContainerWrapper>
      </Wrapper>
    );
  }
}

export default ToolTreasure;
