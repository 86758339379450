import { ScreenCoreSystem } from "../../components/riddles/ScreenCoreSystem/screen-core-system";
import { ScreenPlanetSystem } from "../../components/riddles/ScreenPlanetSystem/screen-planet-system";
import { ScreenAlternativePower } from "../../components/riddles/ScreenAlternativePower/alternative-power-system";
import { ScreenAutoPilot } from "../../components/riddles/ScreenAutoPilot/screen-auto-pilot";
import { ScreenWarning } from "../../components/riddles/ScreenWarning/screen-warning";
import { ScreenGuidingSystem } from "../../components/riddles/ScreenGuidingSystem/screen-guiding-system";
import { ScreenTreasure } from "../../components/riddles/ScreenTreasure/screen-treasure";
import { ScreenAccessEngineSystem } from "../../components/riddles/ScreenAccessEngineSystem/screen-access-engine-system";
import { ScreenEngineStart } from "../../components/riddles/SpaceEngineStart/screen-engine-start";
import { ScreenArmorActivate } from "../../components/riddles/ScreenArmorActivate/screen-armor-activate";
import { ScreenAutopilotAccess } from "../../components/riddles/ScreenAutopilotAccsess/screen-autopilot-access";
import guidingSystemSolutionImg from "../../assets/images/guiding-system-riddle/solution.jpg";
import planetSystemSolutionImg from "../../assets/images/planet-system/solution.jpg";
import { DICTIONARY } from "../../dictionary/dictionary";
const phoneNumberAnswer = localStorage.getItem("phoneNumber");

const language = localStorage.getItem("language") || "hebrew";

class Clue {
  constructor(title, subtitle, description) {
    this.title = title;
    this.subtitle = subtitle;
    this.description = description;
  }
}

class Riddle {
  constructor(
    id,
    title,
    clues,
    toolData,
    tool,
    password,
    successText = "",
    menuTriggerStyle
  ) {
    this.id = id;
    this.title = title;
    this.clues = clues;
    this.toolData = toolData;
    this.tool = tool;
    this.password = password;
    this.successText = successText;
    this.menuTriggerStyle = menuTriggerStyle;
  }
}

const {
  general,
  alternate_power,
  navigation_system,
  core_system_encryption,
  activate_weapon_systems,
  the_planet_system,
  radiation_warning,
  engine_access,
  activating_the_engines,
  access_to_the_automatic_pilot_system,
  automatic_pilot_system,
  launching_Spaceship,
} = DICTIONARY;
export const adventureSpace = {
  riddles: [
    new Riddle(
      1,
      alternate_power.riddle_name[language],
      [
        new Clue(
          general.clue_1[language],
          general.clue_1[language],
          alternate_power.clue_1[language]
        ),
        new Clue(
          general.clue_2[language],
          general.clue_2[language],
          alternate_power.clue_2[language]
        ),
        new Clue(
          general.solution[language],
          general.solution[language],
          alternate_power.solution[language]
        ),
      ],
      {},
      ScreenAlternativePower,
      "1",
      alternate_power.succsess[language],
      { width: "36vw", height: "17vh", top: "2vh", left: "31.5vw" }
    ),

    // new Riddle(
    //     2,
    //     navigation_system.riddle_name[language],
    //     [
    //         new Clue(general.clue_1[language], general.clue_1[language],
    //             navigation_system.clue_1[language]),
    //         new Clue(general.clue_2[language], general.clue_2[language],
    //             navigation_system.clue_2[language]),
    //         new Clue(general.solution[language], general.solution[language],
    //             navigation_system.solution[language]),
    //     ],
    //     {},
    //     ScreenGuidingSystem,
    //     ["225", "135", "180", "45"],
    //     navigation_system.succsess[language],
    //     {width: '12.3vw', height: '15.7vh', top: "47vh", left: '30.8vw'}
    // ),

    new Riddle(
      2,
      core_system_encryption.riddle_name[language],
      [
        new Clue(
          general.clue_1[language],
          general.clue_1[language],
          core_system_encryption.clue_1[language]
        ),
        new Clue(
          general.clue_2[language],
          general.clue_2[language],
          core_system_encryption.clue_2[language]
        ),
        new Clue(
          general.solution[language],
          general.solution[language],
          core_system_encryption.solution[language]
        ),
      ],
      {},
      ScreenCoreSystem,
      "4723",
      core_system_encryption.succsess[language],
      { width: "13.3vw", height: "16.2vh", top: "67.6vh", left: "9.3vw" }
    ),

    new Riddle(
      3,
      activate_weapon_systems.riddle_name[language],
      [
        new Clue(
          general.clue_1[language],
          general.clue_1[language],
          activate_weapon_systems.clue_1[language]
        ),
        new Clue(
          general.clue_2[language],
          general.clue_2[language],
          activate_weapon_systems.clue_2[language]
        ),
        new Clue(
          general.solution[language],
          general.solution[language],
          activate_weapon_systems.solution[language]
        ),
      ],
      {},
      ScreenArmorActivate,
      [false, true, true, false, false, true, true, true],
      activate_weapon_systems.succsess[language],
      { width: "14.1vw", height: "11.4vh", top: "51vh", right: "26.6vw" }
    ),

    // new Riddle(
    //     4,
    //     the_planet_system.riddle_name[language],
    //     [
    //         new Clue(general.clue_1[language], general.clue_1[language],
    //             the_planet_system.clue_1[language]),
    //         new Clue(general.clue_2[language], general.clue_2[language],
    //             the_planet_system.clue_2[language]),
    //         new Clue(general.solution[language], general.solution[language],
    //             the_planet_system.solution[language]),
    //     ],
    //     {},
    //     ScreenPlanetSystem,
    //     "5",
    //     the_planet_system.succsess[language],
    //     {width: '23.2vw', height: '35.1vh', bottom: 0, right: '.8vw'}
    // ),

    new Riddle(
      4,
      radiation_warning.riddle_name[language],
      [
        new Clue(
          general.clue_1[language],
          general.clue_1[language],
          radiation_warning.clue_1[language]
        ),
        new Clue(
          general.clue_2[language],
          general.clue_2[language],
          radiation_warning.clue_2[language]
        ),
        new Clue(
          general.solution[language],
          general.solution[language],
          radiation_warning.solution[language]
        ),
      ],
      {},
      ScreenWarning,
      "1438",
      radiation_warning.succsess[language],
      { width: "14.3vw", height: "11.6vh", top: "51vh", left: "24.8vw" }
    ),

    new Riddle(
      5,
      engine_access.riddle_name[language],
      [
        new Clue(
          general.clue_1[language],
          general.clue_1[language],
          engine_access.clue_1[language]
        ),
        new Clue(
          general.clue_2[language],
          general.clue_2[language],
          engine_access.clue_2[language]
        ),
        new Clue(
          general.solution[language],
          general.solution[language],
          engine_access.solution[language]
        ),
      ],
      {},
      ScreenAccessEngineSystem,
      "SPACE",
      engine_access.succsess[language],
      {
        width: "22.4vw",
        /* height: 16.3vh; */
        height: "7.5vh",
        top: "17.4vh",
        left: "19.2vw",
      }
      // {width: '26.9vw', height: '16.3vh', top: "14.8vh", left: '17vw'},
    ),

    new Riddle(
      6,
      activating_the_engines.riddle_name[language],
      [
        new Clue(
          general.clue_1[language],
          general.clue_1[language],
          activating_the_engines.clue_1[language]
        ),
        new Clue(
          general.clue_2[language],
          general.clue_2[language],
          activating_the_engines.clue_2[language]
        ),
        new Clue(
          general.solution[language],
          general.solution[language],
          activating_the_engines.solution[language]
        ),
      ],
      {},
      ScreenEngineStart,
      ["180", "60", "150", "120"],
      activating_the_engines.succsess[language],
      { width: "23vw", height: "33vh", bottom: "0", left: "0" }
    ),

    new Riddle(
      7,
      access_to_the_automatic_pilot_system.riddle_name[language],
      [
        new Clue(
          general.clue_1[language],
          general.clue_1[language],
          access_to_the_automatic_pilot_system.clue_1[language]
        ),
        new Clue(
          general.clue_2[language],
          general.clue_2[language],
          access_to_the_automatic_pilot_system.clue_2[language]
        ),
        new Clue(
          general.solution[language],
          general.solution[language],
          access_to_the_automatic_pilot_system.solution[language]
        ),
      ],
      {},
      ScreenAutopilotAccess,
      "9",
      access_to_the_automatic_pilot_system.succsess[language],
      {
        width: "15.6vw",
        height: "8.6vh",
        bottom: "9.9vh",
        left: "40.8vw",
      }
      // {width: '20.2vw', height: ' 15.8vh', bottom: "5vh", left: '38.4vw'}
    ),

    new Riddle(
      8,
      automatic_pilot_system.riddle_name[language],
      [
        new Clue(
          general.clue_1[language],
          general.clue_1[language],
          automatic_pilot_system.clue_1[language]
        ),
        new Clue(
          general.clue_2[language],
          general.clue_2[language],
          automatic_pilot_system.clue_2[language]
        ),
        new Clue(
          general.solution[language],
          general.solution[language],
          automatic_pilot_system.solution[language]
        ),
      ],
      {},
      ScreenAutoPilot,
      "253",
      automatic_pilot_system.succsess[language],
      { width: "100vw", height: "100vh", top: "0", left: "0" }
    ),
    new Riddle(
      9,
      launching_Spaceship.riddle_name[language],
      [],
      {},
      ScreenTreasure,
      "",
      "",
      { width: "100vw", height: "100vh", top: "0", left: "0" }
    ),
  ],
};
