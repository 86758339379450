import React, {useState} from "react";
import './screen-auto-pilot.scss';
import SpaceTifzoret from "../../tools/space-tifzoret/space-tifzoret"
import redRobotsSrc from "../../../assets/images/auto-pilot-riddle/red-robot.png"
import blueRobotsSrc from "../../../assets/images/auto-pilot-riddle/blue-robot.png"
import greenRobotsSrc from "../../../assets/images/auto-pilot-riddle/green-robot.png"
import SpaceCoder from "../../tools/space-coder/space-coder"
import SuccessDisplay from "../../SuccessDisplay/success-display";
import {ClueTrigger} from "../../ScreenClue/clue-trigger";
import MenuReturn from "../../ScreenMenuReturn/screen-menu-return";

export const ScreenAutoPilot = ({onSubmit, isResolved, riddleData}) => {
    const [isUserSuccessed, setIsUserSuccessed] = useState(isResolved);
    const onUserSubmit = userCode => {
        if (userCode === riddleData.password) {
            setIsUserSuccessed(true);
            onSubmit();
        }
        return (userCode === riddleData.password)
    };

    return (
        <div className={"auto-pilot-container"}>
            <ClueTrigger id={riddleData.id} top={'0'} right={'0'}/>
            <MenuReturn id={riddleData.id} top={'0'} left={'0'}/>
            <div className={"auto-pilot-wrapper"}>
                <div className={"auto-pilot-tifzoret-container"}>
                    <div className={"robors-imges-wrapper"}>
                        <img src={redRobotsSrc} className={"robors-img"}/>
                        <img src={greenRobotsSrc} className={"robors-img"}/>
                        <img src={blueRobotsSrc} className={"robors-img"}/>
                    </div>
                    <div className={"auto-pilot-tifzoret"}>
                        <SpaceTifzoret/>
                    </div>
                </div>
                <div className={"auto-pilot-code"}>
                    {isUserSuccessed ?
                        <SuccessDisplay/>
                        :
                        <SpaceCoder digitsLength={riddleData.password.length}
                                    onSubmit={onUserSubmit}/>
                    }
                </div>
            </div>
        </div>
    )
};