import React from 'react';
import PropTypes from 'prop-types';
import { colors, font_size } from "../../00_base/variables";

const Text = ({ tag, block, color, variant, style, textAlign, size, fontWeight, fontSize, value, children }) => {
    return React.createElement(
        tag,
        {style: {
            color: variant ? colors[variant] : color, 
            fontSize: fontSize ? fontSize : font_size[size], 
            textAlign: textAlign,
            fontWeight: fontWeight,
            display: block ? 'block' : 'inherit', 
            ...style
        }},
        children || value
    )
};

Text.defaultProps = {
    tag: 'p',
    color: colors.gray_10,
    value: ''
};

Text.propTypes = {
    tag: PropTypes.oneOf(['p', 'label', 'span']),
    fontSize: PropTypes.string,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']),
    color: PropTypes.string,
    variant: PropTypes.oneOf(['primary', 'secondary', 'success', 'error', 'warning', 'disable', 'link']),
    fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element])
};

export default Text;