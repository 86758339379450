import React, {useState} from "react";
import './screen-warning.scss';
import SpaceCoder from "../../tools/space-coder/space-coder";
import {ClueTrigger} from "../../ScreenClue/clue-trigger";
import MenuReturn from "../../ScreenMenuReturn/screen-menu-return";
import textImg from "../../../assets/images/warning-riddle/warning-riddle-text.png";
import englishTextImg from "../../../assets/images/warning-riddle/warning-english.jpg"
import SuccessDisplay from "../../SuccessDisplay/success-display";
import ToolPassword from "../../tools/password";

export const ScreenWarning = ({riddleData, onSubmit, isResolved}) => {
    const language = localStorage.getItem("language") || "hebrew";

    const [isUserSuccessed, setIsUserSuccessed] = useState(isResolved);

    const onUserSubmit = userCode => {
        if (!isResolved) {
             const password = language === "english" ? "SPACE" : riddleData.password
            if (userCode === password) {
                setIsUserSuccessed(true);
                onSubmit()
            }
        }
    };

    return (
        <div className={"screen-warning-container"}>
            <ClueTrigger id={riddleData.id} right={"0"} top={"0"}/>
            <MenuReturn id={riddleData.id} top={'0'} left={'0'}/>
            <img className={"screen-warning-text"}
                 src={(language === "Hebrew" || language === "hebrew") ? textImg : englishTextImg}/>
            <div className={`screen-warning-code`}>
                {isUserSuccessed ?
                    <SuccessDisplay title={riddleData.successText}/> :
                    language === "english" ?
                        <ToolPassword password={"YCBUOP"}
                                      keyboardType={2}
                                      handleSuccess={() => {
                                          setIsUserSuccessed(true);
                                          onSubmit()}
                                      }/> :
                        <SpaceCoder digitsLength={riddleData.password.length}
                                    onSubmit={onUserSubmit}/>
                }
            </div>
        </div>
    )
};