import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';

const BackgroundImage = props => {
    return (
        <BackgroundImageContainer {...props}
            className={`background-image ${props.className}`}>
            {props.cover.component &&
                <BackgroundImageCover cover={props.cover}>
                    {props.cover.component}
                </BackgroundImageCover>
            }
            {props.children}
        </BackgroundImageContainer>
    )
}

const BackgroundImageContainer = styled.div`
    ${props => `
        width: ${props.square ? '100%' :  props.width};
        height: ${props.height};
        padding-bottom: ${props.square && '100%'};
        background-image: url(${props.url});
        background-position: ${props.position};
        background-size: ${props.size};
        background-attachment: ${props.attachment};
        background-repeat: ${props.repeat};
        border-radius: ${props.circle ? '50%' : props.radius && props.radius};
        overflow: hidden;
        position: relative;
    `}
`;

const BackgroundImageCover = styled.div`
    ${props => `
        opacity: ${props.cover.showOn === 'always' ? 1 : 0};
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: ${props.cover.backgroundColor};
        &:hover {
            opacity: 1;
        }
    `}
`;

BackgroundImage.defaultProps = {
    className: '',
    position: 'center center',
    size: 'cover',
    repeat: 'no-repeat',
    cover: { component: null, showOn: 'hover', backgroundColor: 'rgba(0,0,0,0.8)' } // 'hover', 'click' 'always'
};

BackgroundImage.propTypes = {
    url: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    /**
     * background-position
     */
    position: PropTypes.string,
    /**
     * background-size
     */
    size: PropTypes.string,
    /**
     * background-repeat
     */
    repeat: PropTypes.string,
    /**
     * circle with background image
     */
    circle: PropTypes.bool,
    /**
     * border-radius
     */
    radius: PropTypes.string,
    /**
    * define a cover for the image
    */
    cover: PropTypes.shape({
        component: PropTypes.element,
        showOn: PropTypes.oneOf(['hover'], ['always']),
        backgroundColor: PropTypes.string
    }),
    className: PropTypes.string,
};

export default BackgroundImage;