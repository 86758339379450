import styled from 'styled-components';
import {viewport, screenWidth } from '../../00_base/variables';


export const MobileHidden = styled.div`
    display: none;
    @media screen and (min-width: ${viewport.sm}) {
        display: block;
    }
`;


export const MobileLgHidden = styled.div`
    display: none; 
    @media screen and (min-width: ${viewport.md}) {
        display: block;
    }
`;
export const MobileLgOnly = styled.div`
    @media screen and (min-width: ${viewport.md}) {
        display: none;
    }
`;

export const TabletHidden = styled.div`
border: 1px solid red;
    display: none;
    @media screen and (min-width: ${viewport.lg}) {
        display: block;
            }
`;


export const DesktopHidden = styled.div`
border: 1px solid green;
    @media screen and (min-width: ${viewport.lg}) {
        display: none !important;
    }
`;

//new try
export const MobileOnly = styled.div`
    display: none;
    @media screen and (min-width: ${screenWidth.mobileMin}) and (max-width: ${screenWidth.mobileLgMax}) { 
        display: block;
    }
`;

export const TabletPortraitOnly = styled.div`
    display: none;
    @media screen and (min-width: ${screenWidth.tabletPortraitMin}) and (max-width: ${screenWidth.tabletPortraitMax}) { 
        display: block;
    }
`;

export const TabletLandscapeOnly = styled.div`
    display: none;
    @media screen and (min-width: ${screenWidth.tabletLandscapeMin}) and (max-width: ${screenWidth.tabletLandscapeMax}) { 
        display: block;
    }
`;

export const TabletOnly = styled.div`
    display: none;
    @media screen and (min-width: ${screenWidth.tabletPortraitMin}) and (max-width: ${screenWidth.tabletLandscapeMax}) { 
        display: block;
    }
`;

export const DesktopOnly = styled.div`
    display: none;
    @media screen and (min-width: ${screenWidth.desktopMin}) and (max-width: ${screenWidth.desktopMax}) { 
        display: block;
    }
`;

export const DesktopLargeOnly = styled.div`
    display: none;
    @media screen and (min-width: ${screenWidth.desktopLargeMin}) and (max-width: ${screenWidth.desktopLargeMax}) { 
        display: block;
    }
`;

export const DesktopMaxOnly = styled.div`
    display: none;
    @media screen and (min-width: ${screenWidth.desktopMaxMin}) { 
        display: block;
    }
`;

