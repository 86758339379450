import React, {useEffect} from 'react';
import styled from 'styled-components';
import { gutters, colors } from '../../00_base/variables';

const Train = ({ items, spaceBetweenItems }) => {
    useEffect(() => {
        const slider = document.querySelector('.train-items');
        let isDown = false;
        let startX;
        let scrollLeft;
    
        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
            // console.log(walk);
        });
    }, [])
    
    const renderItem = (item, index) => {
    return <TrainItem key={index} 
    className="item item1" 
    style={{padding: spaceBetweenItems || gutters.sm}}
    >
        {item}
    </TrainItem>};
    return (
        <TrainWrapper>
            <TrainItems className="train-items">
                {items.map(renderItem)}
            </TrainItems>
        </TrainWrapper>
    )
}

const TrainWrapper = styled.div`
    color: lighten(#333, 25%);
    background-color: lighten(lightblue, 60%);
    // grid-area: main;
    padding: 0;
    // overflow-x: scroll;
    // overflow-y: hidden;
`;

const TrainItems = styled.div`
    position: relative;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    transition: all 0.2s;
    // transform: scale(0.98);
    will-change: transform;
    user-select: none;
    cursor: pointer;
    display: flex;
    &.active {
        cursor: grabbing;
        cursor: -webkit-grabbing;
        transform: scale(1);
    }
    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        display: none;
    }
`;

const TrainItem = styled.div`
    display: inline-block;
    // background-color: ${colors.white};
    
    display: inline-flex;
    justify-content: center
    align-items: center;
    padding: ${gutters.xs};
    transition: transform .2s;
    // @media screen and (max-width: 500px) {
    //     min-height: 100px;
    //     min-width: 100px;
    // }
`;

export default Train;