import React, { useState } from "react";
import "./screen-access-engine-system.scss";
import { StarsBackground } from "../../03_organisms/stars-background/stars-background";
import ToolPassword from "../../tools/password";
import { ClueTrigger } from "../../ScreenClue/clue-trigger";
import MenuReturn from "../../ScreenMenuReturn/screen-menu-return";
import { Link } from "react-router-dom";
import CODE from "../../../assets/images/e.png";
import { PasswordSuccess } from "../../PasswordSuccess/password-success";
import { DICTIONARY } from "../../../dictionary/dictionary";

export const ScreenAccessEngineSystem = ({
  riddleData,
  isResolved,
  onSubmit,
}) => {
  const language = localStorage.getItem("language") || "hebrew";
  const [passwordSolved, setPasswordSolved] = useState(isResolved);

  const handleSuccess = () => {
    setPasswordSolved(true);
    onSubmit();
  };
  return (
    <div className='screen-access-engine-system'>
      {!passwordSolved ? (
        <>
        <ClueTrigger id={riddleData.id} right={"0"} top={"0"} />
      <MenuReturn id={riddleData.id} top={"0"} left={"0"} />
          <div className='screen-access-engine-system-wrapper'>
            <div className='screen-access-engine-system-content'>
              <img src={CODE} style={{ maxWidth: "250px" }} />
              <ToolPassword
                password={"SPACE"}
                keyboardType={2}
                handleSuccess={handleSuccess}
              />
            </div>
          </div>
        </>
      ) : (
        <PasswordSuccess
          title={
            DICTIONARY.instructions.access_engine_system_authorized[language]
          }
          text_link={DICTIONARY.instructions.continue_to_engines[language]}
          goto={`/riddle/${riddleData.id + 1}`}
          color={"#fff"}
        />
      )}
    </div>
  );
};
