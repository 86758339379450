import React, { useState } from "react";
import "./screen-armor-activate.scss";
import alienSrc from "../../../assets/images/pizza/monster_attack.png";
import classNames from "classnames";
import { Link } from "react-router-dom";
import _ from "lodash";
import SpaceSwitch from "../../tools/space-switch/space-switch";
import SuccessDisplay from "../../SuccessDisplay/success-display";
import { Audio } from "../../01_atoms/audio/audio";
import { playAudio } from "../../../assets/helpers";
import Audio9 from "../../../assets/audios/9.wav";
import Audio9b from "../../../assets/audios/9b.wav";
import { ClueTrigger } from "../../ScreenClue/clue-trigger";
import MenuReturn from "../../ScreenMenuReturn/screen-menu-return";

export const ScreenArmorActivate = ({ riddleData, isResolved, onSubmit }) => {
  const [clicked, setClicked] = useState(false);
  const [switchesStatus, setSwitchesStatus] = useState(
    isResolved ? riddleData.password : new Array(8).fill().map(() => false)
  );
  const [isUserSuccessed, setIsUserSuccessed] = useState(isResolved);

  const audioOnId = "audio-on-id";
  const audioOffId = "audio-off-id";

  const renderStatusRow = (status) => {
    return (
      <div className={"armor-status-row"}>
        {switchesStatus.map((singleSwitch) => (
          <div
            className={classNames("armor-status", {
              green: singleSwitch,
              red: !singleSwitch,
            })}>
            {status === singleSwitch ? (singleSwitch ? "ON" : "OFF") : ""}
          </div>
        ))}
      </div>
    );
  };

  const switchASwitch = (index) => {
    if (!isUserSuccessed) {
      playAudio(`audio-${switchesStatus[index] ? "off" : "on"}-id`);
      switchesStatus[index] = !switchesStatus[index];
      setSwitchesStatus(JSON.parse(JSON.stringify(switchesStatus)));
      setTimeout(() => {
        if (_.isEqual(riddleData.password, switchesStatus)) {
          setIsUserSuccessed(true);
          onSubmit();
        }
      }, 200);
    }
    setClicked(true);
  };

  const renderSwitchesRow = () => {
    return (
      <div className={"armor-switches-row"}>
        {switchesStatus.map((singleSwitch, index) => (
          <div className={"armor-switch"}>
            <SpaceSwitch
              checked={switchesStatus[index]}
              onClick={() => switchASwitch(index)}
              clicked={clicked}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className='screen-armor-activate'>
      <Audio source={Audio9} id={audioOnId} />
      <Audio source={Audio9b} id={audioOffId} />

      <ClueTrigger id={riddleData.id} top={"0"} right={"0"} />
      <MenuReturn id={riddleData.id} top={"0"} left={"0"} />

      {!isUserSuccessed ? (
        <img className={"alien-image"} src={alienSrc} />
      ) : (
        <div className={"armor-success-container"}>
          <SuccessDisplay title={riddleData.successText} />
        </div>
      )}
      {renderStatusRow(true)}
      {renderSwitchesRow()}
      {renderStatusRow(false)}
    </div>
  );
};
